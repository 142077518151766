export const studyAssets = {
  nextBlue: 'https://search.sogoucdn.com/gpt/icon_next_blue.53250588.png',
  nextGray: 'https://search.sogoucdn.com/gpt/icon_next_gray.cc37d2b5.png',
  answerGet: 'https://search.sogoucdn.com/gpt/icon_get.94215d9f.png',
  answerNotGet: 'https://search.sogoucdn.com/gpt/icon_not_get.1f68ce2b.png',
  pronounce: 'https://search.sogoucdn.com/gpt/icon_pronounce.0cfb2911.png',
  translate: 'https://search.sogoucdn.com/gpt/icon_translate.a07facec.png',
  studyLogo: 'https://search.sogoucdn.com/gpt/study_logo.1a76f60c.png',
  refresh: 'https://search.sogoucdn.com/gpt/icon_refresh.2137a98a.png',
};
