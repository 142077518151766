import React from 'react';

import './index.scss';
import { sendNSPLog } from '../../../common/js/log';
import { CardData } from '../../types/talking';

interface PageProps {
  base_info?: object;
}

const handleNewPageOpen = (linkUrl: string, classId: string, cardData: CardData) => {
  window.open(linkUrl, '_blank');
  sendNSPLog({ action: 'click', modulename: 'VR', vrid: classId, target_url: encodeURIComponent(linkUrl) }, cardData);
};

const Card: React.FC<PageProps> = (props: any) => {
  const { base_info: baseInfo, display_info: displayInfo = {}, qo_info: qoInfo = {}, cardData = {} } = props;
  const { classid } = baseInfo;
  const { vrQuery, oriQuery } = qoInfo;
  const { info_sub: list } = displayInfo;
  const dramaList = list.slice(0, 6);
  const moreJumpLink = `https://m.sogou.com/openapi/h5/category_video/index?vrid=${classid}&qoinfo=${encodeURIComponent(
    vrQuery,
  )}&query=${encodeURIComponent(oriQuery)}`;

  return (
    <div className="nps-ysjh">
      <div className="nps-ysjh__list">
        {dramaList.map((item: any, index: number) => {
          const linkUrl = `https://m.sogou.com/web/searchList.jsp?keyword=${encodeURIComponent(
            `${item.name}电视剧`,
          )}&jump_from=1_27_52_01`;
          return (
            <div className="nps-ysjh__item" key={index} onClick={() => handleNewPageOpen(linkUrl, classid, cardData)}>
              <div className="nps-ysjh__imgWrap">
                <img className="nps-ysjh__img" src={item.picture} alt=""></img>
              </div>
              <h4 className="nps-ysjh__title">{item.name}</h4>
              <h4 className="nps-ysjh__subTitle">
                {item.year} {item?.type?.split(';').slice(0, 2).join(' ')}
              </h4>
            </div>
          );
        })}
      </div>
      <div className="nps-ysjh__more" onClick={() => handleNewPageOpen(moreJumpLink, classid, cardData)}>
        查看更多
      </div>
    </div>
  );
};

export default Card;
