import React, { useImperativeHandle, useState, forwardRef, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { assets } from '../common/js/assets';
import { globalVar } from '../common/js/global';
import { dclRSA, requestDclLevel, requestDclCustom, requestDclFeedBack, toggleFixBox } from '../common/js/utils';
import { UserinfoState } from '../view/types/store';
import { TopicCurrent } from '../view/types/talking';

import Toast from './toast';

const dclUrl = 'https://api.dcl.qq.com/';
interface PageProps {
  ref?: any;
  cardData?: TopicCurrent;
  onFinsh?: () => void;
}
interface ListArrProps {
  levelName: string;
  levelId: string;
  checked: boolean;
}
interface CustomeArrProps {
  name: string;
  engName: string;
}
interface ListArrGroupProps {
  levelName: string;
  levelId: string;
  checked: boolean;
  childrens: ListArrProps[];
}
interface IFeedBackContentProps extends PageProps {
  value?: ListArrGroupProps[];
  customeArr?: CustomeArrProps[];
  onChange?: (value: ListArrGroupProps[]) => void;
  onFinish?: () => void;
}
interface ResParams {
  errcode?: number;
}

interface GlobalToast {
  setShow: (status: boolean) => {};
}
interface FeedBackContentRed {
  reset: () => {};
}
interface FDRes {
  errcode?: number;
  data: {
    treeLevels: TreeLevels[];
  };
}
interface FDRes2 {
  errcode?: number;
  data: {
    feedbackFields: {
      engName: string;
      name: string;
    }[];
  };
}
interface TreeLevels {
  children: {
    levelName: string;
    levelId: string;
  }[];
  levelName: string;
  levelId: string;
}

const FeedBackContent: React.FC<IFeedBackContentProps> = forwardRef((props: IFeedBackContentProps, ref) => {
  const { value, onChange, cardData, onFinish } = props;
  const [listArrGroup, setListArrGroup] = useState<ListArrGroupProps[]>(value || []);
  useEffect(() => {
    if (value && value.length > 0) {
      setListArrGroup(value);
    }
  }, [value]);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setSuggestion('');
    },
  }));
  const [submitText, setSubmitText] = useState('提交');
  const [suggestion, setSuggestion] = useState<string>('');
  const itemClick = (wrapIndex: number, index: number) => {
    const list = [...listArrGroup];
    list[wrapIndex].childrens[index].checked = !list[wrapIndex].childrens[index].checked;
    setListArrGroup(list);
    onChange?.(listArrGroup);
  };

  let disabled = true;
  listArrGroup.forEach((item) => {
    item.childrens.forEach((subItem) => {
      if (subItem.checked || suggestion.trim()) {
        // 只要有选中就是不禁用
        disabled = false;
      }
    });
  });
  const { userInfo } = useSelector((state: UserinfoState) => state.userInfo);
  const userID = globalVar.isPublicNet ? window.netQBID : userInfo.username;

  const postDclFeedBackRequest = async (params: object) => {
    const getRsaParams = dclRSA(`${dclUrl}feedbacks`, userID);
    const res: ResParams = await requestDclFeedBack(getRsaParams, params);
    const { errcode } = res;
    return !(errcode !== 0);
  };
  const success = () => {
    setSuggestion('');
    onFinish?.();
  };
  const onSubmit = async () => {
    if (disabled) return;
    let checkedArr: ListArrProps[] = [];
    setSubmitText('提交中...');
    listArrGroup.forEach((item) => {
      item.childrens.forEach((subItem) => {
        if (subItem.checked) {
          checkedArr.push(subItem);
        }
      });
    });
    checkedArr = checkedArr.filter(Boolean);
    const params = {
      message: suggestion || checkedArr.find((item) => item.checked)?.levelName,
      levelId: checkedArr.find((item) => item.checked)?.levelId || 'b4fa095e-a582-799d-a302-ba2571bad8aa',
      fbType: '1',
      partId: '0',
      customField: {
        custom_轮次id: cardData?.dialogueID,
        custom_会话id: cardData?.sessionID,
        custom_用户id: userID,
        custom_问题分类: checkedArr.map((item) => item.levelName).join(';') || '其他',
        custom_用户UA: navigator.userAgent.toLowerCase(),
      },
    };
    // 发送反馈请求
    const fbRes: boolean = await postDclFeedBackRequest(params);
    if (fbRes) {
      success();
      setSubmitText('提交');
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="nsp-fb-content-box">
        <div className="nsp-fb-content">
          <>
            {listArrGroup?.map((wrapItem, wrapIndex) => (
              <>
                <h3 className="sub-title">{wrapItem.levelName}</h3>
                <div key={wrapIndex}>
                  {wrapItem?.childrens?.map((item, index) => (
                    <div
                      key={JSON.stringify(item)}
                      className={`fb-item ${item.checked ? 'clicked' : ''}`}
                      data-title={item.levelId}
                      onClick={() => {
                        itemClick(wrapIndex, index);
                      }}
                    >
                      {item.levelName}
                    </div>
                  ))}
                </div>
              </>
            ))}
          </>
        </div>
        {/* <div className="sa-line-bottom-gray20"></div> */}
        <div className="nsp-fb-content">
          <h3 className="sub-title">其他建议（选填）</h3>
          <textarea
            value={suggestion}
            onChange={(evt) => {
              setSuggestion(evt.target.value);
            }}
            placeholder="请填写其他意见或建议"
            className="nsp-fb-textarea flex-full"
          />
        </div>
        <div className="nsp-fb-content nsp-fb-qr nsp-row">
          <div>
            <img src={assets.nspFeedbackQR} alt="qr" />
          </div>
          <div className="sa-spacing-left-4 sa-spacing-top-8 sa-spacing-bottom-8">
            <div className="sa-text-bold-body2 sa-spacing-right-8">
              欢迎加入核心用户群，交流/吐槽/尝新，一起探讨产品的未来发展
            </div>
            <div className="sa-spacing-top-8 sa-text-regular-caption gray-text">截图保存二维码，微信扫一扫加入</div>
          </div>
        </div>
      </div>
      <div
        className={`fb-button sa-spacing-bottom-24 sa-spacing-top-12 sa-spacing-left-12 sa-spacing-right-12 ${
          disabled ? 'disabled' : ''
        }`}
        onClick={onSubmit}
      >
        {submitText}
      </div>
    </>
  );
});

const FeedBackSuccess = () => (
  <div className="nsp-fb-success">
    <div className="qr-container">
      <div className="sa-text-bold-H3 title-big sa-spacing-top-4">
        欢迎加入核心用户群，交流/吐槽/尝新，一起探讨产品的末来发展
      </div>
      <div className="sa-spacing-top-16 sa-spacing-bottom-8">
        <img className="qr-img" src={assets.nspFeedbackQR} alt="qr" />
      </div>
      <div className="sa-spacing-left-4">
        <div className="sa-spacing-top-8 gray-text">截图保存二维码, 微信扫一扫加入</div>
      </div>
    </div>
  </div>
);

const FeedBackMask = forwardRef((props: PageProps, ref: any) => {
  const { cardData, onFinsh } = props;
  const [isShowMask, setShowMask] = useState(false);
  const [showFeedbackType, setShowFeedbackType] = useState(-1);
  // const [listArr, setListArr] = useState<ListArrProps[]>([]);
  const [listArrGroup, setListArrGroup] = useState<ListArrGroupProps[]>([]);
  const [customeArr, setCustomeArr] = useState<CustomeArrProps[]>([]);
  const feedBackContentRef = useRef<FeedBackContentRed>();
  const toastRef = useRef<GlobalToast>();
  // const [scrollY, setScrollY] = useState<number>(0);

  useImperativeHandle(ref, () => ({
    // 暴露给父组件修改mask弹窗的方法
    setMask: (newShowMask: boolean, type: number) => {
      if (newShowMask === true) {
        // setScrollY(window.scrollY);
        toggleFixBox(false);
        setShowFeedbackType(type);
        document.body.style.overflow = 'hidden';
      }
      setShowMask(newShowMask);
    },
  }));

  const reset = () => {
    resetData();
    feedBackContentRef?.current?.reset();
    document.body.style.height = '';
    document.body.style.marginTop = '';
    document.getElementsByTagName('body')[0].classList.remove('fixed-body');
    document.getElementsByTagName('html')[0].classList.remove('fixed-body');
    onFinsh?.();
    toggleFixBox(true);
  };

  const resetData = () => {
    document.body.style.overflow = '';
    const list = [...listArrGroup];
    list.forEach((item) => {
      item.checked = false;
      item.childrens.forEach((subItem) => {
        subItem.checked = false;
      });
    });
    setListArrGroup(list);
    setShowMask(false);
  };
  const { userInfo } = useSelector((state: UserinfoState) => state.userInfo);
  useEffect(() => {
    getDclLevelRequest();
    getDclCustomRequest();
  }, []);

  // 获取dcl 反馈分类
  const getDclLevelRequest = async () => {
    const getRsaParams = dclRSA(`${dclUrl}levels`, userInfo.username);
    const dclLevelUri = `${getRsaParams}&partId=0&shape=tree`;
    const res: FDRes = await requestDclLevel(dclLevelUri);
    const { errcode, data } = res;
    if (errcode !== 0) {
      return [];
    }
    const list: ListArrGroupProps[] = [];
    if (data.treeLevels && data.treeLevels.length !== 0) {
      data.treeLevels.forEach((item: TreeLevels) => {
        const subList: ListArrProps[] = [];
        item?.children?.forEach((subItem) => {
          subList.push({
            levelName: subItem.levelName,
            levelId: subItem.levelId,
            checked: false,
          });
        });
        list.push({
          levelName: item.levelName,
          levelId: item.levelId,
          checked: false,
          childrens: subList,
        });
      });
    }
    setListArrGroup(list);
    return data;
  };
  // 获取dcl 反馈自定义字段
  const getDclCustomRequest = async () => {
    const getRsaParams = dclRSA(`${dclUrl}custom-feedback-fields`, userInfo.username);
    const dclCustomUri = `${getRsaParams}`;
    const res: FDRes2 = await requestDclCustom(dclCustomUri);
    const { errcode, data } = res;
    if (errcode !== 0) {
      return [];
    }
    const customeList: CustomeArrProps[] = [];
    if (data.feedbackFields && data.feedbackFields.length !== 0) {
      data.feedbackFields.forEach((item) => {
        customeList.push({ engName: item.engName, name: item.name });
      });
    }
    setCustomeArr(customeList);
    return data;
  };

  return (
    <div
      onClick={reset}
      className="nsp-fb-mask-bg"
      style={{
        display: isShowMask ? 'block' : 'none',
      }}
    >
      <div className={globalVar.isPC ? 'pc-container' : 'container'} onClick={(e) => e.stopPropagation()}>
        <div className="nsp-row sa-spacing-left-12 sa-spacing-right-12">
          <div className="nsp-row header">
            <span className="title">反馈</span>
            <div className="close-box">
              <img src={assets.delete} className="close-icon" alt="delete" onClick={reset} />
            </div>
          </div>
        </div>
        <div className="sa-line-bottom-gray20"></div>
        <Toast ref={toastRef} text="感谢您的反馈~" duration={2000}></Toast>
        {isShowMask ? (
          <>
            {showFeedbackType === -1 ? (
              <FeedBackContent
                ref={feedBackContentRef}
                value={listArrGroup}
                cardData={cardData}
                customeArr={customeArr}
                onFinish={() => {
                  resetData();
                  toastRef?.current?.setShow(true);
                  document.body.style.height = '';
                  document.body.style.marginTop = '';
                  document.getElementsByTagName('body')[0].classList.remove('fixed-body');
                  document.getElementsByTagName('html')[0].classList.remove('fixed-body');
                  onFinsh?.();
                  toggleFixBox(true);
                }}
                onChange={(resList) => {
                  setListArrGroup(resList);
                }}
              />
            ) : (
              <FeedBackSuccess />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
});
export default FeedBackMask;
