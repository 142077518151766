import React from 'react';

import classnames from 'classnames';

import { assets } from '../../common/js/assets';

interface PagePros {
  className?: string;
  onShareLinkCopy?: () => void;
  onCancel?: () => void;
}

const ShareBottomBar: React.FC<PagePros> = (props: PagePros) => {
  const { className, onShareLinkCopy, onCancel } = props;
  return (
    <div className="nsp-mask-bg" onClick={onCancel}>
      <div className={classnames('nsp-share-window', className)}>
        <div className="top">
          <div className="item" onClick={onShareLinkCopy}>
            <div className="icon">
              <img src={assets.shareIconLink}></img>
            </div>
            复制链接
          </div>
          {/* <div className="item">
          <div className="icon">
            <img src={assets.shareIconWechat}></img>
          </div>
          微信
        </div>
        <div className="item">
          <div className="icon">
            <img src={assets.shareIconEnterpriseWechat}></img>
          </div>
          企业微信
        </div> */}
        </div>
        <div className="bottom" onClick={onCancel}>
          <span>取消</span>
        </div>
      </div>
    </div>
  );
};

export default ShareBottomBar;
