import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { assets } from '../common/js/assets';
import { sendNSPLog } from '../common/js/log';
import { newsData, news } from '../common/js/news';
import Swiper from '../common/js/swiper.min';

import { studyAssets } from './study/js/assets';
import QuestionList from './study/questionList';

interface PageProps {
  talkRef?: any;
  sceneType?: string;
}
interface GuideLogLock {
  [key: number]: boolean;
}

const guideLogLock: GuideLogLock = {};

const NewsList: React.FC<PageProps> = (props: PageProps) => {
  const { talkRef } = props;
  // 手动跳转
  const navigate = useNavigate();
  return (
    <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events nsp-tips">
      <div className="swiper-wrapper">
        {newsData.map((child, index) => (
          <div
            className={classNames(
              'nsp-swiper',
              'swiper-slide',
              { 'swiper-slide-active': index === 1 },
              { 'swiper-slide-duplicate': index === 0 || index === newsData.length - 1 },
            )}
            data-swiper-slide-index={`${child.pageIndex}`}
            key={index}
          >
            {child.pages.map((pageItem, idx) => (
              <div
                key={idx}
                className="nsp-link"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate('/session?from=homepage_prompt');
                  talkRef.current.setQuickTopic(pageItem.content);
                  // 发送prompt点击日志
                  sendNSPLog({
                    action: 'click',
                    modulename: 'prompt_guide',
                    res_title: pageItem.content,
                    index: idx + 1,
                    from: 'homepage_prompt',
                    pageindex: index + 1,
                  });
                }}
              >
                <div className="sa-text-bold-body1 title-flex">
                  <p className="nsp-container-title inline-flex">
                    <img src={pageItem.leftIcon} alt="" />
                    <span className="sa-spacing-left-8 sa-spacing-left-8">{pageItem.title}</span>
                    <i className="sa-icon sa-icon-arrow-up nsp-arrow-left-icon"></i>
                  </p>
                </div>
                <div className="sa-spacing-top-8 sa-text-regular-body2 caption sa-color-text-caption sa-spacing-bottom-16 sa-text-clamp3">
                  {pageItem.content}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const Index: React.FC<PageProps> = (props: PageProps) => {
  const { talkRef, sceneType } = props;
  const isStudyProject = sceneType === 'study';
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    new Swiper('.swiper', {
      loop: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      delay: 3000,
      speed: 500,
      on: {
        slideChangeTransitionStart(swiper: any) {
          const currentData = newsData[swiper.activeIndex];
          const { pageIndex = 0 } = currentData;
          const logPageIndex = pageIndex + 1;
          setActiveIndex(pageIndex);
          if (!guideLogLock[logPageIndex]) {
            const promptList = currentData.pages.map((item, index) => ({
              title: item.title,
              index: `${logPageIndex}_${index + 1}`,
              res_title: item.content,
            }));
            // 发送prompt曝光日志
            sendNSPLog({
              action: 'expose',
              prompt: JSON.stringify(promptList),
              modulename: 'prompt_guide',
              pageindex: logPageIndex,
            });
            guideLogLock[logPageIndex] = true;
          }
        },
      },
    });
  }, []);

  return (
    <>
      <div className={classNames('nsp-container', isStudyProject ? 'study-container' : '')}>
        <div>
          {isStudyProject ? (
            <img className="nsp-logo" src={studyAssets.studyLogo} alt="习题助教" />
          ) : (
            <img className="nsp-logo" src={assets.newLogo} alt="nsp" />
          )}
        </div>
        {isStudyProject ? (
          <QuestionList talkRef={talkRef}></QuestionList>
        ) : (
          <>
            <NewsList talkRef={talkRef}></NewsList>
            <div className="nsp-scroll">
              {news.map((item, index) => (
                <i
                  className={classNames('nsp-scroll-icon', { 'nsp-scroll-icon-select': index === activeIndex })}
                  key={index}
                ></i>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Index;
