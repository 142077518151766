const ua = navigator.userAgent;
const uaLower = ua.toLowerCase();

/**
 * 企业微信ua: Mozilla/5.0 (Linux; Android 11; RMX1901 Build/RKQ1.201217.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/109.0.5414.86 MQQBrowser/6.2 TBS/046613 Mobile Safari/537.36 wxwork/4.1.9 MicroMessenger/7.0.1 NetType/WIFI Language/zh Lang/zh ColorScheme/Light
 * 微信ua: Mozilla/5.0 (Linux; Android 11; RMX1901 Build/RKQ1.201217.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/111.0.5563.116 Mobile Safari/537.36 XWEB/5235 MMWEBSDK/20230701 MMWEBID/2783 MicroMessenger/8.0.40.2420(0x28002851) WeChat/arm64 Weixin NetType/WIFI Language/zh_CN ABI/arm64
 * MOA: Mozilla/5.0 (Linux; Android 11; RMX1901 Build/RKQ1.201217.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/89.0.4389.72 MQQBrowser/6.2 TBS/046247 Mobile Safari/537.36 MOA/6.1.2(139) Language/zh_CN
 *  */

const isPublicNet = window.location.host === 'sge.testsite.woa.com' || window.location.host === 'sge.qq.com';
export const globalVar = {
  title: 'NEW SEARCH PLAYGROUND',
  studyTitle: 'QQ浏览器搜索出品',
  AIName: '智能搜索助手',
  logo: '',
  max: 10,
  maxLength: 500,
  isPC: window.innerWidth > 960,
  isAndroid: ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1,
  isiOS: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
  isQB:
    uaLower.includes('mqqbrowser') &&
    !ua.includes('MicroMessenger') &&
    !ua.includes('MOA') &&
    !uaLower.includes('mqqbrowser/6.2'),
  isDebug: window.location.search.indexOf('debug=on') > -1 && !isPublicNet,
  isPublicNet,
  // isPublicNet: true,
  filterSiteHosts: [
    'm.sohu.com',
    'www.douyin.com',
    'www.zhihu.com',
    'm.xuexili.com',
    'm.zhms.cn',
    'www.xiaobaixitong.com',
    'm.bd.bendibao.com',
    'www.jianshu.com',
  ],
  // 开关
  switch: {
    // 分享功能
    share: true,
  },
  api: {
    reqQuery: '/open-admin/searchApi/new/query',
    reqVote: '/open-admin/searchApi/new/vote',
    reqUserInfo: '/open-admin/searchApi/new/getUserInfo',
    reqPauseTopic: '/open-admin/searchApi/new/pause',
    getCosAuth: '/open-admin/searchApi/new/getCosAuth',
    uploadImg: '/open-admin/searchApi/new/uploadImg',
    getTransWord: '/open-admin/searchApi/transWord',
    getShareDialogue: '/open-admin/searchApi/getShareDialogue',
    shareDialogue: '/open-admin/searchApi/shareDialogue',
    userInfo: '/open-admin/userinfo',
    getHeaderInfo: '/open-admin/searchApi/new/getHeaderInfo',
    getUserAuth: '/open-admin/searchApi/new/auth',
  },
  tips: {
    pic: '我是AI初级小画手，我画好了，希望你能喜欢。欢迎多提宝贵意见，帮我快速进步~',
    pic2: '这是一张图片，你可以告诉我想要做什么，或者试试下方的“生成漫画风”和“寻找相似明星脸”~',
    picCartoon: '😁生成漫画风',
    picCartoonRes: '生成好了~希望你能喜欢~',
    picStar: '👩🏻寻找相似明星脸',
    picStarRes: '与图中人物最相似的明星如下：',
    picText: '我还可以生成动漫风和寻找明星脸，想要试试吗？',
  },
  audio: {
    compound: '🎤音乐合成',
    desc: '音乐合成功能，可以用你的声音演唱歌曲~点击输入框来录制一段10s左右的声音吧，可以试着说“给一句默认的文案引导”',
    tell: '请说任意一句话，我将使用你的音色合成歌曲 先来录制一段10s左右的声音吧',
  },
  uploadTips: {
    limit: '图片太大啦，请上传张10M内的吧～',
    error: '服务内部错误~',
    safe: '图片可能包含不合适内容，请换一张吧～',
    format: '很抱歉，目前仅支持png、jpg、jpeg、bmp格式的图片，请选择上述格式图片再上传吧~',
    buys: '请求用户过多，请稍候再试哦～',
  },
};
