export const initMediaDevices = function () {
  return new Promise((resolve, reject) => {
    let recorder = null;
    let mediaStream = null; // 用来存储媒体流
    console.log('initMediaDevices 123', !navigator.mediaDevices);
    if (!navigator.mediaDevices) {
      reject({
        code: 1,
        msg: '不支持录音',
      });
      return;
    }
    // 录制
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        mediaStream = stream;
        recorder = new MediaRecorder(stream);
        resolve({
          recorder,
          mediaStream,
        });
      })
      .catch((err) => {
        console.log('initMediaDevices err', err);
        reject({
          code: 2,
          msg: '用户拒绝',
          err,
        });
        console.log('录制的错误', err);
      });
  });
};
