import axios from 'axios';

import { globalVar } from '../../../common/js/global';
import { requestQuery } from '../../../common/js/utils';

import { AUDIO_TTS_PATH, MAX_VOICE_LEN, loadStatusMap } from './model';

axios.defaults.timeout = 100000;
// const { userInfo } = useSelector((state) => state.userInfo);
// 请求获取题库数据
export const requestQuestionList = async () => {
  const params = {
    timestamp: Date.now(),
    type: 'study',
    studyReq: { query: '', actionParams: { actionType: 'question-lib' } },
    needDebug: false,
    waitType: 'done',
  };
  try {
    const res = await requestQuery(params);
    const { code, frames } = res.data;
    if (code === 0 && frames.length > 0) {
      return handleQuestionListData(frames);
    }
    return [];
  } catch (error) {
    // console.log('error', error);
  }
};
// 处理题库数据
const handleQuestionListData = (frames) => {
  let questionList = [];
  frames?.forEach((frame) => {
    const questionListJson = frame?.data?.data;
    const type = frame?.data?.type;
    if (questionListJson && type === 'questions') {
      questionList = JSON.parse(questionListJson);
    }
  });
  return questionList;
};

// 请求点词翻译灵犀卡数据
export const requestTransWord = async ({ query, from, to }) =>
  await axios({
    method: 'POST',
    url: globalVar.api.getTransWord,
    data: {
      query,
      from,
      to,
    },
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  });

// 处理机翻接口数据
export const handleExternalTransword = (transResult, word) => {
  const { translation = {}, phonetic = [], paraphrase = [] } = transResult;
  const { from, to, trans_text: transText } = translation;
  if (transText) {
    let phonetics = [];
    let details = [];
    // 有词典
    if (Array.isArray(paraphrase) && paraphrase.length) {
      details = paraphrase.filter((i) => i.value);
      const isChinese = from === 'zh-CHS';
      // 中文情况下只取前五条释义数据
      if (isChinese) {
        details = details.slice(0, 5);
      }
      // 得到音标和发音
      if (phonetic.length) {
        phonetics = phonetic
          .map((item) => formatPhonetic(item, word, item.type || 'zh-CHS'))
          .slice(0, isChinese ? 1 : phonetic.length);
      }
    }
    const machineData = {
      from,
      to,
      text: word,
      transText,
    };
    return {
      machineData,
      phonetics,
      details,
      status: loadStatusMap.success,
    };
  }
};
/**
 * 格式化音标和语音发音
 * @param {语音发音的对象} item
 * @param {语音发音的文本} text
 * @param {语音发音的方言} lang
 */
export const formatPhonetic = (item, text, lang) => {
  const itemCopy = item || {};
  const typeMap = {
    usa: '美',
    uk: '英',
    'zh-CHS': '汉',
  };
  itemCopy.text = (itemCopy.text || '')
    .replace(/(?:\/|／)/g, '')
    .replace(/\[/g, '')
    .replace(/\]/g, ''); // 音标或拼音的文本
  itemCopy.type = typeMap[item.type || lang];
  const fileName = /https?:\/\/|\/\/dlweb\.sogoucdn\.com/.test(itemCopy.filename)
    ? itemCopy.filename
    : `//dlweb.sogoucdn.com/phonetic/${itemCopy.filename}`;
  itemCopy.src = itemCopy.filename
    ? fileName
    : getVoiceSrc({
        text: text || itemCopy.text || '',
        lang: ['uk', 'usa'].includes(lang) ? 'en' : lang || 'auto',
        terminalType: 'web',
      });
  return itemCopy;
};

function getVoiceSrc({ lang, text }) {
  const query =
    (text || '')
      .replace(/\n/g, '<br/>')
      .replace(/(?:<br\s*\/?>|\r?\n)/gi, ' ')
      .replace(/(?:\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]|\uFEFF)/g, '')
      .slice(0, MAX_VOICE_LEN) || '';

  if (!query) {
    return false;
  }

  const paramObj = { text: query, spokenDialect: lang, rate: '0.8' };
  const jsonStr = JSON.stringify(paramObj).replace(/^"|"$/g, '');
  return `${AUDIO_TTS_PATH}?S-Param=${encodeURIComponent(jsonStr)}`;
}

// 获取弹窗位置信息
export const getPopPositionInfo = (ele, cardHeight) => {
  const { offsetLeft, offsetTop, offsetWidth } = ele;
  const clientRect = ele.getBoundingClientRect();
  const viewTop = clientRect.y; // 距离视窗顶部的高度
  const cardMaxHeight = 272; // 弹窗最大高度
  const spaceX = 22; // 距离左边的宽度不算左边距24px，且有2px的误差
  const triangleHeight = 8; // 小三角高度
  const lineHeight = 24; // 行高24像素
  const x = offsetLeft + offsetWidth / 2 - spaceX;
  let y = offsetTop - cardHeight - triangleHeight;
  let direction = 'top';
  // 当顶部不能放下弹窗时，弹窗出现在下边
  if (viewTop < cardMaxHeight) {
    y = offsetTop + lineHeight + triangleHeight;
    direction = 'bottom';
  }
  return {
    pointPopX: x,
    pointPopY: y,
    pointPopDirection: direction,
  };
};

// 为实现点词翻译，为每个英文单词包一层span标签
export const replaceEnWord = (sentence, occurTimesMap) => {
  // 原文单词出现次数/[a-zA-Z]+/ig
  const curOccurTimesMap = occurTimesMap;
  const sentenceHTML = sentence?.replace(/([a-zA-Z’']+)/g, (item, text) => {
    if (curOccurTimesMap[text]) {
      curOccurTimesMap[text] += 1;
    } else {
      curOccurTimesMap[text] = 1;
    }
    return `<span class='nsp-study-word' data-times=${occurTimesMap[text]}>${text}</span>`;
  });
  return {
    occurTimesMap: curOccurTimesMap,
    sentenceHTML,
  };
};

/**
 * 修改url参数
 * @param {url地址} uri
 * @param {参数名} key
 * @param {参数值} value
 */
export function updateUrlParameter(uri, key, value) {
  if (!uri) {
    return uri;
  }
  const keyArr = [].concat(key);
  const valArr = [].concat(value);

  // remove the hash part before operating on the uri
  const index = uri.indexOf('#');
  const hash = index === -1 ? '' : uri.substr(index);
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  uri = index === -1 ? uri : uri.substr(0, index);
  if (keyArr.length === valArr.length) {
    for (let i = 0; i < keyArr.length; i++) {
      const encValue = htmlEncode(valArr[i]);
      const re = new RegExp(`([?&])${keyArr[i]}=.*?(&|$)`, 'i');
      if (uri.match(re)) {
        uri = uri.replace(re, `$1${keyArr[i]}=${encValue}$2`);
      } else {
        uri = `${uri + separator + keyArr[i]}=${encValue}`;
      }
    }
  }
  return uri + hash; // finally append the hash as well
}
function htmlEncode(str) {
  return str
    .toString()
    .replace(/&/g, '&amp;')
    .replace(/>/g, '&gt;')
    .replace(/</g, '&lt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
}
