import React, { useEffect, useRef } from 'react';

import './style.scss';
import { getWarningObj } from './util';

interface IProps {
  warning: {}[];
}

interface RetItem {
  tip: string;
  color: string;
  className: string;
}

const Warning: React.FC<IProps> = (props: IProps) => {
  const { warning = [] } = props;
  let ret = getWarningObj(warning);
  const refBox = useRef<HTMLDivElement>(null);
  const stepBox = useRef<HTMLDivElement>(null);
  const curIndx = useRef(0);

  const isLoop = ret.length > 1;
  if (isLoop) {
    const cloneList = ret.slice(0);
    ret = [...cloneList, ret.shift()];
  }
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isLoop) {
      if (stepBox.current && refBox.current) {
        const stepHeight = stepBox.current.offsetHeight || 0;
        timer.current = setInterval(() => {
          if (refBox.current) {
            curIndx.current += 1;
            refBox.current.style.transition = 'all 300ms ease';
            refBox.current.style.transform = `translateY(-${curIndx.current * stepHeight}px)`;
          }
        }, 3000);
      }
      return () => clearInterval(timer.current);
    }
  }, []);

  const transitionEnd = () => {
    if (curIndx.current >= ret.length - 1 && refBox.current && isLoop) {
      refBox.current.style.transition = 'all 0ms ease';
      refBox.current.style.transform = 'translateY(0px)';
      curIndx.current = 0;
    }
  };

  return (
    <div className="warning" ref={stepBox}>
      <div className="wrapper" ref={refBox} onTransitionEnd={transitionEnd}>
        {ret.map(({ tip, color, className }: RetItem, idx: number) => (
          <div className="warning-btn" key={idx}>
            <span className={className}>{color}</span>
            <p className="fz-mid">{tip}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Warning;
