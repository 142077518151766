import React from 'react';

import './style.scss';
import classNames from 'classnames';

import { formatDcDataWeather, getHourList } from './util';

interface HourInfo {
  day_hour: string;
  state: string;
  temperature: string;
  time: string;
  value_hour_pm25: string;
}

interface IProps {
  list: HourInfo[];
  queryInfo: any;
  weather: any;
}
const Hours: React.FC<IProps> = (props: IProps) => {
  const { list, queryInfo, weather } = props;
  const todayInfo = formatDcDataWeather(weather);
  const ret = getHourList(list, queryInfo, todayInfo as any);
  return ret.length !== 0 ? (
    <div className="hours">
      <h3 className="title">
        <svg
          className="timer_icon"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <rect x="3.5" y="3.5" width="11" height="11" rx="5.5" fill="white" stroke="black" />
          <path d="M10.458 10.4583L8.70801 9.14583V6.375" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        24小时预报
      </h3>
      <div className="scroll_box">
        {ret.map(({ img, temperature, time, pm }: any) => {
          const { value, color, level } = pm || {};
          return (
            <div className="item" key={time}>
              <span className="time">{time}</span>
              <img style={{ marginTop: '0.08rem' }} src={img} alt="" />
              <span className="temperature" style={{ marginTop: '0.06rem' }}>
                {temperature}
              </span>
              {value !== undefined && <span className={classNames('level', color)}>{level}</span>}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default Hours;
