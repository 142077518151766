import { useContext, useState, useRef, useEffect, useMemo, TouchEvent, MouseEvent } from 'react';

import classNames from 'classnames';
import { marked } from 'marked';
import { useDispatch } from 'react-redux';

import { C } from '../../App';
import { assets } from '../../common/js/assets';
import { formatMessageDate } from '../../common/js/date';
import { globalVar } from '../../common/js/global';
import { sendNSPLog } from '../../common/js/log';
import { scrollToBottom } from '../../common/js/scroll';
import { isShareHistoryPage } from '../../common/js/share';
import { updateQuery, resetMaskStaus } from '../../common/js/store/querySlice';
import { copyText } from '../../common/js/utils';
import OptionsCard from '../../view/study/cardOptions';
import { ContextProps } from '../../view/types/App';
import { WordList, FeedBackMaskRef, CheckBoxRef } from '../../view/types/nspTopic';
import { NspTopicProps } from '../../view/types/session';
import { FrameDone, ResStr } from '../../view/types/talking';
import CheckBox from '../checkbox/checkbox';
import FeedBackMask from '../feedBackMask';
import Image from '../image';
import Loading from '../loading';
import LoadingText from '../loadingText';
import MusicCompound from '../musicCompound';
import MusicList from '../musicList';

import { CardMap } from './cardMap';
import NspTopicBottom from './nspTopicBottom';
import { ResultCard } from './resultCard';
import ShareButtons from './share-buttons';

// 这里是为了兼容refer的标记进行的替换处理
function referStringHTML(s: string): string {
  if (!s.includes('search-result-tag-num')) {
    return s;
  }
  return s.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const NspTopicMain: React.FC<NspTopicProps> = (props: NspTopicProps) => {
  const {
    className,
    onSourceClick,
    cardData,
    cardClick,
    copyClick,
    talkRef,
    isStudyProject,
    hasLoadingText = true,
    hasVote = true,
    isShowDate = false,
    isSharing = false,
    isShareChecked = false,
    responseStr = '',
    onShareChange,
    onSingleShare,
    onBatchShare,
    onStopResponse,
    isLoading,
  } = props;
  const dispatch = useDispatch();
  const { timestamp, cardIndex = 0, query = '', type } = cardData || {};
  // if (window.location.search.indexOf('dbg=on') > -1) {
  //   setTimeout(() => {
  //     cardInfo = mockCard;
  //   }, 2000);
  // }
  const {
    references = [],
    stat,
    card = '',
    pic,
    dataType,
    originConetnt = '',
    debug = null,
    actionData,
    optionSelected,
    done,
    audioParams,
    audioCompoundParams,
    isEnd,
    stopResponse,
    response = '',
    copyMaskIndex = '',
    musicDisable,
  } = cardData;
  const { actionType } = actionData || {};
  const isAudioType = audioParams || audioCompoundParams;
  let musicType = '';
  if (audioParams) {
    musicType = 'musicList';
  } else if (audioCompoundParams) {
    musicType = 'musicCompound';
  }

  useEffect(() => {
    if (isEnd) {
      // AI回复曝光发送日志
      sendNSPLog(
        {
          action: 'expose',
          modulename: 'reply',
          prompt: query,
          musicType,
        },
        cardData,
      );
    }
  }, [isEnd]);

  // 匹配接口返回的loading状态，没有就默认正在分析

  // const summary = insertTag(response, docRefInfos, references);

  // const isMarkDown = (str: string) => /</.test(str);
  // markdown 解析器
  const renderer = new marked.Renderer();
  // 重写解析规则 避免误识别url 例如字符串+链接: 腾讯http://www.tencent.xn--com-144ea/腾讯
  renderer.link = function (href = '', title, text) {
    const reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
    const urls = text?.match(reg);
    if (urls && urls.length > 0) {
      const url = urls?.[0];
      const splitTexts = text.split(url);
      // 中文字符串和url在一起时，无法识别，需要额外拼接
      return `<a href="${url}" title="${text}" target="_blank">${splitTexts.length === 1 ? text : url}</a>${
        splitTexts[1] ? `<span>${splitTexts[1]}</span>` : ''
      }`;
    }
    const hrefUrl = href?.split('?')[0];
    if (!hrefUrl?.includes(text)) {
      return `<a href="${href}" title="${text}" target="_blank">${text}</a>`;
    }
    return `<span>${text}</span>`;
  };
  const originResponse = isEnd ? response : responseStr;
  console.log('originResponse', originResponse);
  // const mockData =
  //   '![图片描述](https://www.example.com/404.jpg)<span class=search-result-tag data-href=https%3A%2F%2Fwww.cnblogs.com%2Flick468%2Fp%2F11424070.html><em class=search-result-tag-num>1</em></span>';
  const htmlStr = type === 'text' ? marked(originResponse, { renderer }) : originResponse;
  // console.log('htmlStr', htmlStr, response);
  // marked.parse("katex: $c = \\pm\\sqrt{a^2 + b^2}$");
  // const summary = isMarkDown(htmlStr) ? referStringHTML(htmlStr) : `<div>${replaceHTMLTag(response)}</div>`;
  const cardHTML = referStringHTML(htmlStr || '');
  // 保证每次只有一个弹层出现
  const cardMaskIndex = `${cardIndex}nsp`;
  // const [backgroundColor, setBackgroundColor] = useState('');
  const [showDebug, setShowDebug] = useState(false);
  // 是否为最后一轮对话
  const last = (cardIndex + 1) % 10 === 0;
  const feedBackMaskRef = useRef<FeedBackMaskRef>();
  const clearTime = useRef<any>();
  // 气泡出现的位置
  const [rect, setRect] = useState({
    x: 0,
    y: 0,
  });

  const onClick = (e: TouchEvent<HTMLElement>) => {
    // 多选状态时，不能再次弹出分享的按钮
    if (isSharing) {
      return;
    }
    // 分享页面也不可点击
    if (isShareHistoryPage()) {
      return;
    }
    dispatch(resetMaskStaus());
    setTimeout(() => {
      talkRef.current.setCardInfo({ ...cardData, copyMaskIndex: cardMaskIndex });
    }, 0);
    const rect = e.changedTouches['0'];
    const y = rect.clientY - 110;
    let x = rect.clientX - 90;
    if (rect.clientX < 90) {
      x = rect.clientX > 50 ? rect.clientX - 50 : 20;
    }
    setRect({
      x: rect.clientX + 90 > window.innerWidth ? window.innerWidth - 200 : x,
      y,
    });
    cardClick?.(e, cardMaskIndex);
    // setBackgroundColor('#F3F8FE');
    // setTimeout(() => {
    //   setBackgroundColor('');
    // }, 300);
    sendNSPLog(
      {
        action: 'expose',
        modulename: 'reply_copy',
      },
      cardData,
    );
  };
  // 反馈
  const onFeedBackClick = (type: number) => {
    feedBackMaskRef.current?.setMask(true, type);
    talkRef.current.setShowTopicBox(false);
  };

  const updateOptionStatus = (isPoint?: boolean) => {
    // const newCardInfo = { ...cardInfo };
    const newCardData = { ...cardData };
    if (isPoint) {
      const newActionData = {
        ...actionData,
        knowledgePoints: [],
      };
      newCardData.actionData = newActionData;
    } else {
      newCardData.optionSelected = true;
    }
    // newCardData.cardInfo = newCardInfo;
    dispatch(updateQuery(newCardData));
  };

  const { onreload, onMiuscClick, onRecordClick }: ContextProps = useContext(C);

  const checkBoxRef = useRef<CheckBoxRef>();
  // 当结果没有展现完成时，不能选择
  const checkDisable = !isEnd || !!stopResponse;
  const onMessageClick = () => {
    checkBoxRef.current?.click();
  };
  const isDone = isEnd || stopResponse;
  // 设置AI logo动画
  const [aiLogo, setAiLogo] = useState(`${assets.nspNewLogoStart}?timestamp=${timestamp}`);
  useEffect(() => {
    if (clearTime.current) {
      clearTimeout(clearTime.current);
    }
    // 进行中动画，无限循环
    const reloadGif = () => {
      if (aiLogo && aiLogo !== assets.nspNewLogoing && !isDone) {
        setAiLogo(assets.nspNewLogoing);
      }
    };
    // 开始动画入场1200ms
    clearTime.current = setTimeout(() => {
      if (!isDone) {
        reloadGif();
      }
    }, 900);
    setAiLogo(isDone ? assets.nspNewLogoDone : `${assets.nspNewLogoStart}?timestamp=${timestamp}`);
  }, [timestamp, isDone]);
  const aiLogoComponent = useMemo(
    () => <div style={{ backgroundImage: `url(${aiLogo})` }} className="sa-spacing-right-4 AI-icon"></div>,
    [aiLogo],
  );
  const disbaleCardStyle = 'card-disable';
  // 设置卡片不可点状态
  const [cardDisable, setCardDisable] = useState(musicDisable ? disbaleCardStyle : '');

  const touchTimestamp = useRef<number>(0);
  const touchBeganTime = useRef<any>(null);
  // 长按出弹窗时间
  const touchDuration = 500;
  const touchToastShow = useRef<boolean>(false);
  const onTouchEnd = (e: TouchEvent<HTMLElement>) => {
    e.stopPropagation();
    // 长按弹出复制浮层
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (Date.now() - touchTimestamp.current < touchDuration) {
      clearInterval(touchBeganTime.current);
      touchTimestamp.current = 0;
      touchToastShow.current = true;
    }
  };
  const onTouchMove = (e: TouchEvent<HTMLElement>) => {
    e.stopPropagation();
    // 如果长按超过500ms弹窗
    touchToastShow.current = true;
  };
  const onTouchStart = (e: TouchEvent<HTMLElement>) => {
    e.stopPropagation();
    touchTimestamp.current = Date.now();
    touchToastShow.current = false;
    touchBeganTime.current = setInterval(() => {
      // 如果长按超过500ms弹窗
      if (
        touchTimestamp.current !== 0 &&
        Date.now() - touchTimestamp.current > touchDuration &&
        touchToastShow.current === false
      ) {
        clearInterval(touchBeganTime.current);
        touchTimestamp.current = 0;
        onClick(e);
        touchToastShow.current = true;
      }
    }, 100);
  };

  return (
    <div
      className={classNames(
        'nsp-session-nsp sa-spacing-top-20',
        isStudyProject ? 'sa-spacing-bottom-20' : '',
        className,
      )}
      onClick={onMessageClick}
    >
      <div className="sa-text-bold-body1 nsp-row owner">
        <div className="nsp-row flex-full">
          {isSharing && (
            <CheckBox
              ref={checkBoxRef}
              disable={checkDisable}
              checked={isShareChecked}
              onChange={onShareChange}
            ></CheckBox>
          )}
          {aiLogoComponent}
          <span>{isStudyProject ? '小助教' : globalVar.AIName}</span>
          {timestamp && isShowDate && (
            <>
              <span style={{ flexGrow: 1 }}></span>
              <span className="date">{formatMessageDate(timestamp)}</span>
            </>
          )}
        </div>
      </div>
      {hasLoadingText && <LoadingText cardData={cardData} />}
      <div
        className={`sa-text-regular-body1 sa-spacing-top-8 card ${cardDisable}`}
        // style={{
        //   backgroundColor,
        // }}
      >
        <ShareButtons
          className={cardMaskIndex === copyMaskIndex ? 'copy-show' : 'copy-hide'}
          rect={rect}
          disableShare={checkDisable}
          onCopy={(e) => {
            e.stopPropagation();
            copyText(originConetnt || originResponse);
            dispatch(resetMaskStaus());
            copyClick?.();
          }}
          onShare={(e) => {
            e.stopPropagation();
            dispatch(resetMaskStaus());
            onSingleShare?.();
          }}
          onBatchShare={(e) => {
            e.stopPropagation();
            dispatch(resetMaskStaus());
            onBatchShare?.();
          }}
          cardData={cardData}
        />
        {isLoading || (stopResponse && response === '') ? (
          <Loading loadingText={stat?.code === 20 ? stat?.data : ''} />
        ) : null}
        {!isStudyProject && hasVote ? (
          <NspTopicBottom
            talkRef={talkRef}
            onFeedBackClick={onFeedBackClick}
            cardData={cardData}
            onStopResponse={onStopResponse}
            isAudioType={!!isAudioType}
          />
        ) : null}
        {audioParams && type === 'audioCompoundList' ? (
          <MusicList
            audioParams={audioParams}
            musicDisable={musicDisable}
            onMiuscClick={(e) => {
              if (cardDisable === '') {
                talkRef.current.setCardInfo({ ...cardData, musicDisable: true });
                setCardDisable(disbaleCardStyle);
                setTimeout(() => {
                  onMiuscClick?.(e);
                }, 20);
                sendNSPLog(
                  {
                    action: 'click',
                    modulename: 'musicList',
                    prompt: query,
                    musicParams: JSON.stringify(e),
                  },
                  cardData,
                );
              }
            }}
          />
        ) : null}
        {audioCompoundParams ? (
          <MusicCompound
            cardData={cardData}
            cardIndex={cardIndex}
            audioCompoundParams={audioCompoundParams}
            onMiuscClick={(e) => {
              if (!musicDisable) {
                talkRef.current.setCardInfo({ ...cardData, musicDisable: true });
                setCardDisable(disbaleCardStyle);
                setTimeout(() => {
                  onMiuscClick?.(e);
                }, 20);
              }
            }}
            onRecordClick={onRecordClick}
          />
        ) : null}
        {!isLoading && !isAudioType ? (
          <>
            <div
              // onClick={onClick}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
              className={classNames(
                stopResponse ? 'pause-color' : '',
                actionType === 'exercise' ? 'nsp-exercise-answer' : '',
              )}
            >
              <ResultCard
                onSourceClick={(e: MouseEvent<HTMLElement>) => {
                  onSourceClick(e, cardData);
                }}
                onFeedBackClick={onFeedBackClick}
                index={cardIndex}
                cardHTML={cardHTML}
                references={references}
                dataType={dataType}
                type={type}
                isStudyProject={isStudyProject}
                done={done}
                isEnd={cardData.isEnd}
              />
            </div>
            <div className="nsp-session-custom">
              {/* {isEnd ? <div dangerouslySetInnerHTML={{ __html: mockHTML }}></div> : null} */}
              {card && isEnd ? <CardMap card={card} cardData={cardData}></CardMap> : ''}
              {pic ? (
                <Image
                  pic={pic}
                  type={type}
                  last={last}
                  cardData={cardData}
                  onreload={() => {
                    if (isSharing) {
                      return;
                    }
                    onreload?.(query);
                  }}
                />
              ) : null}
            </div>
          </>
        ) : null}
        {debug ? (
          <>
            <div className="sa-spacing-top-12">
              <button
                className="nsp-session-debug-btn"
                onClick={() => {
                  setShowDebug(!showDebug);
                }}
              >
                {!showDebug ? 'showDebug' : 'hideDebug'}
              </button>
            </div>
            <div
              className="nsp-session-debug"
              style={{
                display: showDebug ? 'block' : 'none',
              }}
            >
              <code
                onClick={(e: any) => {
                  e.stopPropagation();
                  copyText(e.target.innerHTML);
                  dispatch(resetMaskStaus());
                  copyClick?.();
                }}
              >
                {JSON.stringify(debug, null, 2)}
              </code>
            </div>
          </>
        ) : null}
      </div>
      {isEnd && isStudyProject ? (
        <OptionsCard
          talkRef={talkRef}
          actionData={actionData}
          optionSelected={optionSelected}
          updateOptionStatus={updateOptionStatus}
        />
      ) : null}
      {isSharing && <div className="card-mask"></div>}
      <FeedBackMask
        ref={feedBackMaskRef}
        cardData={cardData}
        onFinsh={() => {
          talkRef.current.setShowTopicBox(true);
        }}
      ></FeedBackMask>
    </div>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const NspTopic: React.FC<NspTopicProps> = (props: NspTopicProps) => {
  const { cardData, talkRef } = props;
  const {
    done,
    responseList = [],
    audioParams,
    audioCompoundParams,
    isEnd,
    isLoading,
    timestamp,
    dataType,
    dialogueID,
  } = cardData;
  const dataRes = cardData.response || '';
  const defaultStop = cardData?.stopResponse || false;
  const [responseStr, setResponseStr] = useState('');
  const [topicLoading, setTopicLoading] = useState(isLoading);
  const [stopResponse, setStopResponse] = useState(defaultStop);
  const isAudioType = audioParams || audioCompoundParams;

  const resStrList = useRef<ResStr[]>([]);
  const historyLen = useRef<number>(0);
  const response = useRef<string>('');
  const nspAddWordLock = useRef<boolean>(false);
  const stopRes = useRef<boolean>(false);
  const wordList = useRef<WordList[]>([]);
  const cancelReq = useRef<any>();
  const steamWordIsEnd = useRef<boolean>(false);
  const currentItemDone = useRef<FrameDone>();

  const init = () => {
    resStrList.current = [];
    wordList.current = [];
    historyLen.current = 0;
    response.current = '';
    setResponseStr('');
    setTopicLoading(true);
    nspAddWordLock.current = false;
    steamWordIsEnd.current = false;
    stopRes.current = false;
    cancelReq.current = null;
    currentItemDone.current = undefined;
  };
  const onStopResponse = () => {
    setStopResponse(true);
    stopRes.current = true;
    talkRef.current.onRequestPause?.({
      response: response.current,
    });
  };
  // 初始化操作
  useEffect(() => {
    init();
  }, [timestamp]);

  useEffect(() => {
    console.log('responseStr', responseStr);
  }, [responseStr]);

  const useAddWord = () => {
    // console.log(
    //   'useAddWord',
    //   stopRes.current,
    //   steamWordIsEnd.current,
    //   resStrList.current,
    //   isEnd,
    //   currentItemDone.current,
    // );
    // 跳过打字机效果
    if (stopRes.current || steamWordIsEnd.current || resStrList.current.length === 0 || isEnd) {
      return;
    }
    // window.cancelAnimationFrame(cancelReq.current);
    const list = resStrList.current.splice(0, resStrList.current.length);
    list.forEach((wordInfo: ResStr) => {
      const { doc, content = '', linkMap } = wordInfo || {};

      // const responseList: ResStr[] = wordInfo ? [wordInfo] : [];
      const docID = doc?.doc_id || doc?.docId;
      // 判断在句子最后第二位插入doc标记
      const docTag = docID
        ? `<span class=search-result-tag data-href=${encodeURIComponent(
            doc.url,
          )}><em class=search-result-tag-num>${docID}</em></span>`
        : '';
      wordList.current.push({
        linkMap,
        docTag,
        wordInfo: content.split(''),
      });
    });
    // 执行打字机效果，已经在打字过程中不处理
    if (nspAddWordLock.current === false) {
      addWord();
      nspAddWordLock.current = true;
    }
    // cancelReq.current = window.requestAnimationFrame(addWord);
  };
  const addWord = () => {
    // 打字结束不继续执行
    if (steamWordIsEnd.current || stopRes.current || isEnd) {
      window.cancelAnimationFrame(cancelReq.current);
      return;
    }
    let insertTag = false;
    const now = Date.now();
    const wordsItem =
      wordList.current.length > 0
        ? wordList.current.shift()
        : {
            wordInfo: [],
            docTag: '',
          };
    const { wordInfo = [], docTag = '', linkMap } = wordsItem || {};
    if (linkMap) {
      response.current += `[ ${linkMap?.name}](${linkMap?.url})`;
      setResponseStr(response.current);
      addWord();
      return;
    }
    // 速率调整，默认40毫秒一个字，如果后端数据完成加快打字
    const speedS = currentItemDone.current ? 30 : 40;
    console.log('wordInfo', wordInfo, linkMap);
    // scrollToBottom();
    const onAdd = () => {
      if (stopRes.current) {
        stopRes.current = false;
        window.cancelAnimationFrame(cancelReq.current);
        return;
      }
      if (wordInfo.length === 0 && !currentItemDone.current) {
        return setTimeout(addWord, 300);
      }
      if (Date.now() - now > speedS) {
        const word = wordInfo.shift();
        // 没有字内容
        if (!word && wordList.current.length === 0 && currentItemDone.current) {
          steamWordIsEnd.current = true;
          console.log('useAddWord talkRef.current', talkRef, wordList.current, currentItemDone.current);
          talkRef.current.onTopicEnd({
            response: response.current,
          });
          init();
          window.cancelAnimationFrame(cancelReq.current);
          return;
        }
        if (!word) {
          addWord();
          return;
        }
        const wordLen = wordInfo.length;
        const showEndTag = wordLen === 0 || (wordLen === 1 && word === '|');
        const prevTag = showEndTag && (word === '\n' || word === '|' || word === '。');
        // 避免重复插入，markdown兼容，refer放在前面
        const endTag = showEndTag && docTag && !insertTag ? docTag : '';
        if (endTag) {
          insertTag = true;
        }
        response.current += prevTag ? endTag + word : word + endTag;
        setResponseStr(response.current);
        setTopicLoading(false);
        // callBack?.(isWordEnd);
      }
      setTimeout(() => {
        cancelReq.current = window.requestAnimationFrame(onAdd);
      }, 30);
      // cancelReq.current = window.requestAnimationFrame(onAdd);
    };
    onAdd();
  };

  const handlerSteamData = (responseList: ResStr[]) => {
    if (Array.isArray(responseList) && responseList.length > 0) {
      resStrList.current = [...resStrList.current, ...responseList.slice(historyLen.current)];
      historyLen.current = responseList.length;
      useAddWord();
      scrollToBottom();
    }
    if (done?.dialogueID === dialogueID) {
      currentItemDone.current = done;
    } else {
      currentItemDone.current = undefined;
    }
  };
  useEffect(() => handlerSteamData(responseList), [responseList, done]);
  useEffect(() => {
    // const res = dataRes;
    if (!isEnd && done && dataRes && dataType === 'snapshot') {
      setResponseStr(dataRes);
      setTopicLoading(false);
      talkRef.current.onTopicEnd({
        response: dataRes,
      });
      init();
    }
  }, [dataType, done]);
  // 音乐类型
  useEffect(() => {
    if (!isEnd && isAudioType && done) {
      setTopicLoading(false);
      talkRef.current.onTopicEnd({
        response: dataRes,
      });
      init();
    }
  }, [isAudioType, done]);
  useEffect(() => {
    if (dataRes && dataType !== 'text' && isEnd) {
      setTopicLoading(false);
    }
  }, [isLoading, isEnd]);

  // 结束时置空response
  // 当前会话ID
  return (
    <NspTopicMain
      {...props}
      isLoading={topicLoading !== false && !responseStr && !isAudioType && !isEnd}
      responseStr={responseStr}
      stopResponse={stopResponse}
      onStopResponse={onStopResponse}
    />
  );
};
