import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import ReactDOM from 'react-dom';

interface ToastProps {
  duration: number;
  text: string;
}

const Toast = forwardRef((props: ToastProps, ref: any) => {
  const { text, duration = 2000 } = props;
  const [isShow, setIsShow] = useState(false);
  const toastTimeOut = useRef<any | null>();
  // 重置超时设置
  const reset = () => {
    if (toastTimeOut.current) {
      clearTimeout(toastTimeOut.current);
    }
    const timeId = setTimeout(() => {
      setIsShow(false);
    }, duration);
    if (toastTimeOut.current) {
      toastTimeOut.current = timeId;
    }
  };
  // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // 暴露给父组件修改mask弹窗的方法
    setShow: (show: boolean) => {
      setIsShow(show);
      if (show) {
        reset();
      }
    },
  }));
  return isShow
    ? ReactDOM.createPortal(
        <div className="nsp-toast">
          <div className="text">{text}</div>
        </div>,
        document.body,
      )
    : null;
});
export default Toast;
