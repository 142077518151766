import React, { useEffect } from 'react';

import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { assets } from '../../../common/js/assets';
import { updataTalkingShow } from '../../../common/js/store/studySlice';
import { StudyStore } from '../../types/store';

interface PageProps {
  talkRef?: any;
}

const QuestionList: React.FC<PageProps> = (props: PageProps) => {
  const { talkRef } = props;
  // 手动跳转
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { questionList, talkingShow } = useSelector((state: StudyStore) => state.study);

  useEffect(() => {
    if (talkingShow) {
      dispatch(updataTalkingShow(false));
    }
  }, [dispatch, talkingShow]);
  return (
    <div className="nsp-study-list">
      {questionList?.map((item, index: number) => (
        <div
          className="nsp-study-wrap"
          key={index}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(`/session?sceneType=study&questionIndex=${index}`);
            // 进入到session页，展现对话输入框
            dispatch(updataTalkingShow(true));
            // 清空查询列表/翻译列表
            talkRef.current.resetQuestion(index);
          }}
        >
          <div className="nsp-container-title">
            <img className="" src={assets.creation} alt="" />
            <span className="sa-spacing-left-2">{item.questionType}</span>
          </div>
          <div className="nsp-study-content">
            <img src={item.questionImageCdnUrl} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestionList;
