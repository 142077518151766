import { createSlice } from '@reduxjs/toolkit';

export const topicNumsSlice = createSlice({
  name: 'topic',
  initialState: {
    maxNum: 30,
    remainingTopics: 0,
    topicMax: 9,
    ring: 10,
    topicList: [],
    sessionID: '',
    dialogueID: '',
    query: '',
    queryLen: 0,
    showHeader: false,
    talkRef: null,
    newTopic: false,
    showSessionError: null,
    uploadType: '',
  },
  reducers: {
    updateTopicList: (state, action) => {
      const { query, sessionID, dialogueID, remainingTopics, numNeedLimit = true } = action.payload;
      // 无次数 不继续执行
      if (numNeedLimit && state.remainingTopics <= 0 && state.topicList.length >= 9) {
        return;
      }
      state.query = query;
      // state.queryLen += query.length;
      state.topicList.push(query);
      state.sessionID = sessionID;
      state.dialogueID = dialogueID;
      state.remainingTopics = remainingTopics;

      // 每轮支持3次会话，3轮以后开启新话题
      // if (state.queryLen >= 450) {
      //     // state.remainingTopics -= 1;
      //     state.topicList = [];
      //     state.sessionID = '';
      //     state.dialogueID = '';
      // }
      if (numNeedLimit && state.topicList.length >= 10) {
        // state.remainingTopics -= 1;
        state.topicList = [];
        state.sessionID = '';
        state.dialogueID = '';
      }
    },
    resetTopicList(state, action) {
      // state.remainingTopics -= 1;
      state.topicList = [];
      state.sessionID = '';
      state.dialogueID = '';
      state.uploadType = '';
    },
    setTimeOutError(state) {
      // 无次数 不继续执行
      if (state.remainingTopics <= 0) {
        return;
      }
      // 10分钟不回复，开启新话题
      state.topicList = [];
      state.sessionID = '';
      state.dialogueID = '';
    },
    setTalkRef(state, action) {
      state.talkRef = action.payload;
    },
    setShowHeader(state, action) {
      state.showHeader = action.payload;
    },
    setShowSessionError(state, action) {
      // 不需要额外减少次数
      if (action?.payload?.code === 80008) {
        // state.remainingTopics -= 1;
        state.topicList = [];
        state.sessionID = '';
        state.dialogueID = '';
        state.uploadType = '';
      }
      state.showSessionError = action.payload;
    },
    setRemainingTopics(state, action) {
      state.remainingTopics = action.payload;
    },
    setUploadType(state, action) {
      state.uploadType = action.payload;
    },
    setStopResponse(state, action) {
      state.stopResponse = action.payload;
    },
  },
});
// 每个case reducer
export const {
  updateTopicList,
  setTimeOutError,
  setNewTopic,
  setTalkRef,
  setShowHeader,
  setShowSessionError,
  setRemainingTopics,
  resetTopicList,
  setUploadType,
  setStopResponse,
} = topicNumsSlice.actions;

export default topicNumsSlice.reducer;
