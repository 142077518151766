import React from 'react';

import { sendNSPLog } from '../common/js/log';

const Donwload: React.FC = () => {
  const androidUrl = 'https://nse.woa.com/static/qqbrowser.apk';
  const iosUrl = 'https://apple-1259630911.cos.ap-nanjing.myqcloud.com/qb_app_download.plist';
  const downloadIosUrl = `itms-services://?action=download-manifest&url=${iosUrl}`;
  return (
    <div className="nsp-download">
      <div>
        <button
          className="btn"
          onClick={() => {
            location.href = androidUrl;
            sendNSPLog({ action: 'click', pagename: 'nsp_download', modulename: 'android_btn' });
          }}
        >
          下载Android包
        </button>
        <button
          className="btn"
          onClick={() => {
            location.href = downloadIosUrl;
            sendNSPLog({ action: 'click', pagename: 'nsp_download', modulename: 'ios_btn' });
          }}
        >
          下载iOS包
        </button>
      </div>
    </div>
  );
};

export default Donwload;
