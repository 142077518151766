import URL from '@tencent/search-url';

import { encryptVoice } from './crypto';
import { AUDIO_TTS_PATH } from './model';

const MAX_VOICE_LOOP = 10;
class Audio {
  constructor() {
    // audio是一个audio元素或者是一个CustomeAudio实例
    this.audio = null;
    // 默认配置
    this.defaultOpts = {
      activeClass: 'play',
      loop: false,
      target: '',
      src: '',
    };
  }
  init(options) {
    // 播放次数
    this.playCnt = 1;
    const opts = Object.assign({}, this.defaultOpts, options);
    this.timerSum = 1;
    this.opts = opts;
    const { target } = this.prevOptions || {};
    const { callback } = options;
    if (target) {
      if (this.timer) {
        window.clearTimeout(this.timer);
        this.timer = null;
      }

      this.destroy();
      // 说明是暂停，直接返回即可
      if (target === opts.target && !callback) {
        return this;
      }
    }
    if (opts.target && !this.isActive(opts.target, opts.activeClass)) {
      this.create(callback).toggleActive(true);
    }

    return this;
  }

  create(callback = null) {
    const { opts } = this;
    const { src } = this.msUrlTransform(opts.src);
    const audio = document.createElement('audio');
    const source = document.createElement('source');
    audio.style.cssText = 'height:0;';
    source.type = 'audio/mpeg';
    source.src = src;
    audio.appendChild(source);
    audio.src = src;
    document.body.appendChild(audio);
    this.canPlayHandler = function () {
      source.currentTime = 0;
      audio.play();
    };
    audio.addEventListener('canplay', this.canPlayHandler);
    this.endedHandler = (e) => {
      const etype = e.type;
      // 循环播放：播放结束1s后重新播放，播放10次
      if (etype === 'ended' && opts.loop && this.playCnt < MAX_VOICE_LOOP) {
        this.timer = setTimeout(() => {
          audio.src = src;
          audio.play();
          this.playCnt += 1;
          this.timer = null;
        }, 1000);
      } else {
        typeof callback === 'function' && callback();
        this.destroy();
      }
    };
    audio.addEventListener('ended', this.endedHandler);
    audio.addEventListener('error', this.endedHandler);
    // HACK: ios不触发canplay自动播放
    this.toggleActive(true);
    audio.play();
    this.audio = audio;
    this.prevOptions = opts;
    return this;
  }
  /**
   * @description: 微软语音接口url转换
   * @param {string} url 待转换的url
   * @return {string}
   */
  msUrlTransform(url) {
    const urlObj = URL.parse(url);
    const { pathname, query } = urlObj;
    let retUrl = url;
    let text = query.text || '';
    let lang = query.lang || '';

    // 微软语音合成接口过期时间鉴权兼容
    if (pathname === AUDIO_TTS_PATH) {
      const sParam = query['S-Param'];
      let paramObj = {};

      try {
        const jsonObj = JSON.parse(sParam);
        paramObj = {
          curTime: Date.now(),
          ...jsonObj,
        };
        text = jsonObj.text;
        lang = jsonObj.spokenDialect;
      } catch (error) {
        // console.log(error);
      }

      const jsonStr = JSON.stringify(paramObj).replace(/^"|"$/g, '');
      retUrl = `https://fanyi.sogou.com/${AUDIO_TTS_PATH}?S-AppId=108496844&S-Param=${encodeURIComponent(
        encryptVoice(jsonStr),
      )}`;
    }

    return {
      src: retUrl,
      text,
      lang,
      path: pathname,
    };
  }

  destroy() {
    const { opts, audio } = this;
    if (!opts) return;
    this.toggleActive(false);
    const { callback } = this.prevOptions || {};
    typeof callback === 'function' && callback();
    this.prevOptions = null;
    if (!audio) return;
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
    document.body.removeChild(audio);
    audio.removeEventListener('canplay', this.canPlayHandler);
    audio.removeEventListener('ended', this.endedHandler);
    audio.removeEventListener('error', this.endedHandler);
    this.audio = null;
  }

  toggleActive(bool) {
    const { activeClass, target } = this.opts;
    const pattern = new RegExp(`\\s+${activeClass}`, 'g');
    if (bool) {
      target && !pattern.test(target.className) && (target.className += ` ${activeClass}`);
    } else {
      const node = this.prevOptions?.target;
      node && (node.className = node.className.replace(pattern, ''));
    }
    return this;
  }

  isActive(target, activeClass) {
    return new RegExp(activeClass, 'g').test(target?.className);
  }
}
export default new Audio();
