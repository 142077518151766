import { createSlice } from '@reduxjs/toolkit';

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    userInfo: {},
  },
  reducers: {
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});
// 每个case reducer
export const { updateUserInfo } = userInfoSlice.actions;

export default userInfoSlice.reducer;
