import { useEffect } from 'react';

import { assets } from '../../common/js/assets';
import { sendNSPLog } from '../../common/js/log';
import { openNaUrl } from '../../common/js/utils';

interface PageProps {
  keyword: string;
}

const SearchBox: React.FC<PageProps> = (props: PageProps) => {
  const { keyword } = props;
  const url = `https://m.sogou.com/web/searchList.jsp?keyword=${encodeURIComponent(keyword)}&jump_from=1_27_52_01`;
  useEffect(() => {
    sendNSPLog({
      action: 'expose',
      modulename: 'bottom_moreresult',
    });
  }, []);
  return (
    <div className="nsp-search-box">
      <div
        className="search-area"
        onClick={() => {
          sendNSPLog({
            action: 'click',
            modulename: 'bottom_moreresult',
            res_title: keyword,
          });
          openNaUrl(url);
        }}
      >
        <img className="search-icon" src={assets.nspNewSearch} alt="" />
        <span className="sa-text-clamp1 search-area-text">{keyword}</span>
        <img className="search-arrow-icon" src={assets.nspNewArrowRight} alt="" />
      </div>
    </div>
  );
};
export default SearchBox;
