import React from 'react';

import { sendNSPLog } from '../../../common/js/log';

import Header from './header';
import Hours from './hours';
import Today from './today';

import './style.scss';
import { evalData, formatDaysList, getQoObj, initQueryIndex } from './util';
import Warning from './warning';

// import { useNavigate } from 'react-router';

const Card: React.FC<any> = (props: any) => {
  const { display_info: displayInfo = {}, qo_info: qoInfo = {}, cardData } = props;

  const { day, hour, dc_data_xxx: dcData = {}, url } = displayInfo;
  const { vrQuery = '', classId } = qoInfo;
  let warning;
  let weather;
  let pm;
  if (dcData) {
    warning = evalData(dcData['DC.WEATHER.WARNINGNEW'])?.w || [];
    weather = evalData(dcData['DC.WEATHER.TEMPERATURENEW'])?.l || {};
    pm = evalData(dcData['DC.WEATHER.PM']) || {};
  }
  const qoObj = getQoObj(vrQuery);
  const daysList = formatDaysList(day);
  const { queryInfo, queryIndex, isQueryToday, todayIndex } = initQueryIndex(daysList, qoObj);
  return (
    <div className="nsp-weather">
      <Header diplayInfo={displayInfo} />
      {warning && warning.length !== 0 && isQueryToday && <Warning warning={warning} />}
      <Today
        warning={warning}
        weather={weather}
        pm={pm}
        queryInfo={queryInfo}
        queryIndex={queryIndex}
        isQueryToday={isQueryToday}
        todayIndex={todayIndex}
      />
      {isQueryToday && <Hours list={hour} queryInfo={queryInfo} weather={weather} />}
      {url && (
        <p
          className="link_url"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const isMobile = navigator.userAgent.match(
              /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
            );
            if (isMobile) {
              window.open(url, '_blank');
            } else {
              window.open(url.replace('mojiweatheraggr/', 'mojiweather_pc/'), '_blank');
            }
            sendNSPLog(
              { action: 'click', modulename: 'VR', vrid: classId, target_url: encodeURIComponent(url) },
              cardData,
            );
          }}
        >
          查看7日天气
        </p>
      )}
    </div>
  );
};

export default Card;
