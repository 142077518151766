import { useEffect, useRef } from 'react';

import { sendNSPLog, setRSFrom } from '../../common/js/log';
import { isContain } from '../../common/js/utils';

export interface EventInfo {
  item: string;
  index: number;
}

interface PageProps {
  topicBtns: string[];
  onClick: (eventInfo: EventInfo) => void;
  doneData?: object;
}

const RSTips: React.FC<PageProps> = (props: PageProps) => {
  const { topicBtns, onClick, doneData } = props;
  const scrollRef = useRef<HTMLDivElement>(null);
  const exposeReportLog = () => {
    const btns = scrollRef.current?.querySelectorAll('button');
    if (btns) {
      btns.forEach((ele: HTMLButtonElement, index: number) => {
        const isExpose = ele.getAttribute('data-expose');
        if (isContain(ele) && !isExpose) {
          sendNSPLog(
            {
              action: 'expose',
              modulename: 'rs',
              res_title: ele.textContent,
              index: index + 1,
            },
            doneData,
          );
          ele.setAttribute('data-expose', 'true');
        }
      });
    }
  };
  useEffect(() => {
    exposeReportLog();
  }, []);
  return (
    <div
      ref={scrollRef}
      className="rs-btns topic-btns"
      onScroll={() => {
        exposeReportLog();
      }}
    >
      <div className="scroll-container sa-spacing-left-12">
        {topicBtns.map((item: string, index: number) => (
          <button
            key={index}
            className="btn sa-spacing-right-8 sa-spacing-bottom-12"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick?.({
                item,
                index,
              });
              const from = 'dialogpage_rs';
              setRSFrom(from);
              sendNSPLog(
                {
                  action: 'click',
                  modulename: 'rs',
                  res_title: item,
                  prompt: item,
                  index: index + 1,
                  from,
                },
                doneData,
              );
            }}
          >
            <span>{item}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default RSTips;
