import { useEffect } from 'react';

import { sendNSPLog } from '../../common/js/log';
import { CardMapProps } from '../../view/types/session';
import Card70095000 from '../../view/vr/card70095000';
import Card70269800 from '../../view/vr/card70269800';
import Card70319700 from '../../view/vr/card70319700';

export const CardMap: React.FC<CardMapProps> = (props: CardMapProps) => {
  const { card, cardData } = props;
  const data = JSON.parse(card);
  const classid = data?.base_info?.classid;
  // 暂时进行6位classid匹配
  const key = classid ? classid.slice(0, 6) : '';
  // 匹配灵犀卡
  const cardMap: { [key: string]: any } = {
    '703197': Card70319700,
    '702698': Card70269800,
    '700950': Card70095000,
  };
  useEffect(() => {
    sendNSPLog(
      {
        action: 'expose',
        modulename: 'VR',
        vrid: classid,
      },
      cardData,
    );
  }, []);
  // 动态展现
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const Card: React.FC = cardMap[key];
  return <Card {...data} cardData={cardData}></Card>;
};
