import React, { useContext, useState, MouseEvent, useRef } from 'react';

import { C } from '../App';
import { assets } from '../common/js/assets';
import { globalVar } from '../common/js/global';
import { sendNSPLog } from '../common/js/log';
import { isShareHistoryPage } from '../common/js/share';
import { ContextProps } from '../view/types/App';
import { TopicCurrent } from '../view/types/talking';

interface PageProps {
  url: string;
  timestamp?: number;
  cardData?: TopicCurrent;
}

function isExpiredCheck(isShareHistory: boolean, timestamp: any) {
  if (isShareHistory && timestamp) {
    return Date.now() - timestamp > 3 * 24 * 60 * 60 * 1000;
  }
  return false;
}

const ImageViewer: React.FC<PageProps> = (props: PageProps) => {
  // const [hideImage, setHideImage] = useState(false);
  const [imgWidth, setImgWidth] = useState('');
  const [imgClass, setImgClass] = useState('');
  const isShareHistory = isShareHistoryPage();
  const { timestamp, cardData } = props;
  const { picUpload } = cardData || {};
  const isExpired = isExpiredCheck(isShareHistory, timestamp);
  let { url } = props;
  url = isExpired ? assets.imageExpiredError : url;
  const { talkRef }: ContextProps = useContext(C);
  const imageRef = useRef<HTMLImageElement>(null);

  return (
    <div
      className="nsp-session-user-image"
      onClick={(e) => {
        e.stopPropagation();
        // 如果已经放大 点击关闭
        if (!imgClass.includes('unset')) {
          setImgClass('nsp-user-image-viewer-animate-unset');
          document.body.style.overflow = '';
          talkRef.current.setShowTopicBox(true);
        }
      }}
    >
      <div className={`nsp-user-image-viewer-animate ${imgClass}`}>
        <img
          width={imgWidth}
          src={url}
          ref={imageRef}
          alt=""
          onClick={(e: MouseEvent<HTMLImageElement>) => {
            // setImageUrl(url);
            if (globalVar.isQB) {
              // 使用图片查看器
              setTimeout(() => {
                // @ts-ignore
                window?.browser?.app?.openPhotoBrowser([picUpload?.url], 0, 0);
              }, 0);
              sendNSPLog(
                {
                  action: 'click',
                  modulename: 'prompt_pic',
                },
                cardData,
              );
              return;
            }
            e.stopPropagation();
            // 如果已经放大 点击关闭
            if (!imgClass.includes('unset') && imgClass !== '') {
              setImgClass('nsp-user-image-viewer-animate-unset');
              document.body.style.overflow = '';
              talkRef.current.setShowTopicBox(true);
            } else {
              setImgWidth(`${imageRef?.current?.width}`);
              setImgClass('nsp-user-image-viewer');
              document.body.style.overflow = 'hidden';
              talkRef.current.setShowTopicBox(false);
              sendNSPLog(
                {
                  action: 'click',
                  modulename: 'prompt_pic',
                },
                cardData,
              );
            }
          }}
        />
      </div>
      {isShareHistory && (
        <div className="expired">
          <img src={assets.info}></img>图片仅保留3天，3天后过期无法展示
        </div>
      )}
    </div>
  );
};

export default ImageViewer;
