import React from 'react';

import './index.scss';
import { useDispatch, useSelector } from 'react-redux';

import { pushErrorInfo } from '../../../common/js/store/querySlice';
import { ActionParams, FrameAction } from '../../types/talking';
import { studyAssets } from '../js/assets';
import { actionMap } from '../js/model';

interface PageProps {
  talkRef?: any;
  actionData?: FrameAction;
  optionSelected?: boolean;
  updateOptionStatus?: any; // 更新选项状态，决定在对话中是否需要清空
}

const OptionsCard: React.FC<PageProps> = (props: PageProps) => {
  const { talkRef, actionData, optionSelected, updateOptionStatus } = props;
  const { actionType = '', pointId = '', queryTimes = '0', knowledgePoints = [], exerciseId = '' } = actionData || {};
  const queryTimesNum = parseInt(queryTimes, 10);
  const exerciseIdNum = parseInt(exerciseId, 10);
  const { questionList, questionIndex, topicCreating } = useSelector((state: any) => state.study);
  // 当前题目
  const curQuestion = questionList[questionIndex] || {};
  const { questionId, questionType } = curQuestion;
  const commonParams = {
    questionId,
    questionType,
  };
  const dispatch = useDispatch();
  const handleOptionClick = (actionParams: ActionParams, topicValue: string, isPoint?: boolean) => {
    if (topicCreating) {
      dispatch(
        pushErrorInfo({
          code: 40004,
        }),
      );
      return;
    }
    talkRef.current.setQuickTopic(topicValue, actionParams);
    // 更新对话是否清空状态。
    // 知识点与选项状态有做区分
    updateOptionStatus(isPoint);
  };
  return (
    <div>
      <div className="nsp-session-options">
        {/* 知识点解析选项 */}
        {!optionSelected && actionType === 'point' ? (
          <>
            <div
              className="nsp-session-option"
              onClick={() => {
                // if (topicCreating) {
                //   return;
                // }
                const curActionParams = {
                  actionType: 'exercise',
                  pointId,
                  exerciseId: exerciseId.toString(),
                  ...commonParams,
                };
                handleOptionClick(curActionParams, '懂了，出个题吧');
              }}
            >
              <span>懂了，出个题吧</span>
              <img src={studyAssets.answerGet} className="nsp-option-get-icon" alt=""></img>
            </div>
            {queryTimesNum <= 1 ? (
              <div
                className="nsp-session-option"
                onClick={() => {
                  const curActionParams = {
                    actionType: 'point',
                    pointId,
                    queryTimes: queryTimes.toString(),
                    ...commonParams,
                  };
                  handleOptionClick(curActionParams, '我还是不太懂');
                }}
              >
                我还是不太懂<img src={studyAssets.answerNotGet} className="nsp-option-noget-icon" alt=""></img>
              </div>
            ) : null}
          </>
        ) : null}

        {/* 练习题选项 */}
        {!optionSelected && actionType === 'exercise' ? (
          <div
            onClick={(e: any) => {
              const answer = e?.target?.dataset?.answer;
              const noAnswer = e?.target?.dataset?.noanswer;
              if (answer || noAnswer) {
                const curActionParams = {
                  actionType: actionMap.answerExercise,
                  pointId,
                  exerciseId: exerciseId.toString(),
                  userAnswer: answer ? 'true' : 'false',
                  ...commonParams,
                };
                const query = answer ? answer : noAnswer;
                handleOptionClick(curActionParams, query);
              }
            }}
          >
            <div className="nsp-option-title">请选择：</div>
            <div className="nsp-excise-option">
              <div data-answer="A">A</div>
              <div data-answer="B">B</div>
              <div data-answer="C">C</div>
              <div data-answer="D">D</div>
            </div>
            <div data-noanswer="我不会，需要讲解" className="nsp-session-option">
              我不会，需要讲解<img src={studyAssets.answerNotGet} className="nsp-option-noget-icon" alt=""></img>
            </div>
          </div>
        ) : null}

        {/* 练习题答案选项*/}
        {!optionSelected && actionType === actionMap.answerExercise && exerciseIdNum <= 1 ? (
          <div
            className="nsp-session-option"
            onClick={() => {
              // 发起对话
              const curActionParams = {
                actionType: 'exercise',
                pointId,
                exerciseId: exerciseId.toString(),
                ...commonParams,
              };
              handleOptionClick(curActionParams, '懂了，再来一题');
            }}
          >
            懂了，再来一题<img src={studyAssets.answerGet} className="nsp-option-get-icon" alt=""></img>
          </div>
        ) : null}
      </div>
      {/* 知识点列表 */}
      {knowledgePoints?.length && queryTimesNum <= 1 ? (
        <div className="sa-spacing-top-12">
          <div className="nsp-option-title">{actionType === 'point' ? '更多知识点' : '本题涉及的知识点'}</div>
          <div className="nsp-knowledge-list">
            {knowledgePoints?.map((item: any, index: number) => {
              const delay = 0.05 * (index + 1);
              return (
                <div
                  className="nsp-session-option nsp-study-point"
                  style={{ animation: `topIn 0.1s ease ${delay}s` }}
                  key={index}
                  onClick={() => {
                    const pointId = item.id;
                    const curActionParams = {
                      actionType: 'point',
                      pointId: pointId.toString(),
                      queryTimes: '0',
                      ...commonParams,
                    };
                    handleOptionClick(curActionParams, item.knowledge, true);
                  }}
                >
                  {item.knowledge}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OptionsCard;
