import React from 'react';

import './style.scss';
import { checkNight, formatDcDataWeather, pmLevel, weatherIcon } from './util';

interface IProps {
  warning: any[];
  weather: any;
  pm: any;
  queryInfo: any;
  queryIndex: number;
  isQueryToday: boolean;
  todayIndex: number;
}
const Today: React.FC<IProps> = (props: IProps) => {
  const { weather, pm, queryInfo, queryIndex, isQueryToday, todayIndex } = props;
  const { fengli = '', fengxiang = '', temperature = '', qixiang = '' } = formatDcDataWeather(weather);
  let text = '';
  if (isQueryToday) {
    text = '今天';
  } else {
    text = queryIndex === todayIndex + 1 ? '明天' : queryInfo?.week || '';
  }
  const isNight = checkNight(queryInfo);

  const { aqi, cnaqi } = pm;
  return (
    <div className="today">
      <div className="top_message">
        <div className="info">
          <p style={{ marginTop: '0.1rem' }}>
            <span className="temperature">
              {isQueryToday && temperature ? temperature : `${parseInt(queryInfo.low)}~${parseInt(queryInfo.high)}`}
            </span>
            {isQueryToday && (
              <span
                style={{
                  top: '-0.03rem',
                  position: 'relative',
                }}
              >
                {qixiang}
              </span>
            )}
          </p>
          <p style={{ marginTop: '0.16rem' }}>
            {isQueryToday && (fengxiang || fengli)
              ? `${fengxiang + fengli}级 ${pmLevel(aqi || cnaqi).level}`
              : queryInfo.description}
          </p>
        </div>
        <div className="icon">
          <img
            width="72"
            src={weatherIcon({
              kind: isNight && isQueryToday ? 'night' : 'day',
              key: isQueryToday ? qixiang : queryInfo.description,
            })}
            alt=""
          />
        </div>
      </div>
      <div className="more_message">
        {isQueryToday && temperature && (
          <>
            <span>
              {text}: {parseInt(queryInfo.low)}~{parseInt(queryInfo.high)}°
            </span>
            <span>{queryInfo.description}</span>
          </>
        )}
        <span>
          {isQueryToday && temperature ? '' : `${text}: `}
          {queryInfo.wind + queryInfo.wind1}
        </span>
        {queryInfo.shidu && <span>湿度{queryInfo.shidu}</span>}
      </div>
    </div>
  );
};

export default Today;
