import { useEffect, useRef, useState, TouchEvent } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { assets } from '../../common/js/assets';
import { globalVar } from '../../common/js/global';
import { sendNSPLog } from '../../common/js/log';
import { resetMaskStaus } from '../../common/js/store/querySlice';
import { copyText, stopAuidoPlay } from '../../common/js/utils';
import { CheckBoxRef } from '../../view/types/nspTopic';
import { UserTopicProps } from '../../view/types/session';
import { QueryStore } from '../../view/types/store';
import CheckBox from '../checkbox/checkbox';
import ImageView from '../imageViewer';

export const UserTopic: React.FC<UserTopicProps> = (props: UserTopicProps) => {
  const {
    cardClick,
    index,
    goSearch,
    copyClick,
    cardData,
    isStudyProject,
    name = '我',
    isSharing = false,
    isShareChecked = false,
    onShareChange,
    onSingleShare,
    talkRef,
    onBatchShare,
  } = props;
  const dispatch = useDispatch();
  const { query = '', code, picUpload, audioInfo, type = '', copyMaskIndex = '', isEnd, stopResponse } = cardData;
  const [setShowFeedBack] = useState('none');
  const cardMaskIndex = `${index}user`;
  const audioRef = useRef<HTMLAudioElement>(null);
  const { audioUrl, duration } = audioInfo || {};
  const { queryList = [] } = useSelector((state: QueryStore) => state.query);
  // 当结果没有展现完成时，不能选择
  const disableShare = !isEnd || !!stopResponse;
  // 气泡出现的位置
  const [rect, setRect] = useState({
    x: 0,
    y: 0,
  });

  // prompt曝光
  useEffect(() => {
    sendNSPLog(
      {
        action: 'expose',
        modulename: 'prompt',
        prompt: query,
        prompt_type: picUpload ? 'pic' : '',
      },
      cardData,
    );
  }, []);
  // 保证每次只有一个弹层出现
  const onClick = (e: TouchEvent<HTMLElement>) => {
    if (!picUpload) {
      if (isSharing) {
        return;
      }
      dispatch(resetMaskStaus());
      setTimeout(() => {
        talkRef.current.setCardInfo({ ...cardData, copyMaskIndex: cardMaskIndex });
      }, 0);
      const rect = e.changedTouches['0'];
      const y = rect.clientY - 110;
      let x = rect.clientX - 90;
      if (rect.clientX < 90) {
        x = rect.clientX > 50 ? rect.clientX - 50 : 20;
      }
      setRect({
        x: rect.clientX + 90 > window.innerWidth ? window.innerWidth - 200 : x,
        y,
      });
      cardClick?.(e, cardMaskIndex, setShowFeedBack);
      sendNSPLog(
        {
          action: 'expose',
          modulename: 'prompt_moreresult',
        },
        cardData,
      );
      sendNSPLog(
        {
          action: 'expose',
          modulename: 'prompt_copy',
        },
        cardData,
      );
    }
  };

  const checkDisable = code === 80006;
  const checkBoxRef = useRef<CheckBoxRef>();
  const [playStatus, setPlayStatus] = useState(false);

  const onMessageClick = () => {
    checkBoxRef.current?.click();
  };

  const touchTimestamp = useRef<number>(0);
  const touchBeganTime = useRef<any>(null);
  // 长按出弹窗时间
  const touchDuration = 500;
  const touchToastShow = useRef<boolean>(false);
  const onTouchEnd = (e: TouchEvent<HTMLElement>) => {
    e.stopPropagation();
    // 长按弹出复制浮层
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (Date.now() - touchTimestamp.current < touchDuration) {
      clearInterval(touchBeganTime.current);
      touchTimestamp.current = 0;
      touchToastShow.current = true;
    }
  };
  const onTouchMove = (e: TouchEvent<HTMLElement>) => {
    e.stopPropagation();
    // 如果长按超过500ms弹窗
    touchToastShow.current = true;
  };
  const onTouchStart = (e: TouchEvent<HTMLElement>) => {
    e.stopPropagation();
    touchTimestamp.current = Date.now();
    touchToastShow.current = false;
    touchBeganTime.current = setInterval(() => {
      // 如果长按超过500ms弹窗
      if (
        touchTimestamp.current !== 0 &&
        Date.now() - touchTimestamp.current > touchDuration &&
        touchToastShow.current === false
      ) {
        clearInterval(touchBeganTime.current);
        touchTimestamp.current = 0;
        onClick(e);
        touchToastShow.current = true;
      }
    }, 100);
  };
  const showShareButtons = !!globalVar.switch.share;

  return (
    <div className="nsp-session-user">
      {!isStudyProject ? <div className="sa-text-bold-body1 owner">{name}</div> : null}
      <div className="message sa-spacing-top-8" onClick={onMessageClick}>
        {isSharing && <div className="card-mask"></div>}
        <div className="left">
          {isSharing && (
            <CheckBox
              disable={checkDisable}
              ref={checkBoxRef}
              checked={isShareChecked}
              onChange={onShareChange}
            ></CheckBox>
          )}
        </div>
        {query ? (
          <div
            className="sa-text-regular-body1 card"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onContextMenu={(e) => {
              e.preventDefault();
            }}
            onSelect={(e) => {
              e.preventDefault();
            }}
            // onClick={onClick}
          >
            {query}
          </div>
        ) : null}
        {picUpload ? <ImageView url={picUpload.url} cardData={cardData} /> : null}
        {audioUrl && type === 'audioCompoundList' ? (
          <div className="sa-text-regular-body1 sa-spacing-top-8 card">
            <div
              className="nsp-row"
              onClick={() => {
                // console.log('点击播放', audioUrl);
                stopAuidoPlay();
                setTimeout(() => {
                  if (playStatus === false) {
                    audioRef.current?.play();
                    setPlayStatus(true);
                  } else {
                    audioRef.current?.pause();
                    setPlayStatus(false);
                  }
                }, 100);
              }}
            >
              <span className="sa-spacing-inner-right-8">{duration}″</span>
              <img src={assets.nspNewAudioIcon} className="sa-spacing-left-2 nsp-audio-play-icon" />
            </div>
            <audio
              className="nsp-audio-broad-cast"
              ref={audioRef}
              onPause={() => {
                setPlayStatus(false);
              }}
              onEnded={() => {
                setPlayStatus(false);
              }}
            >
              <source src={audioUrl} type="audio/ogg"></source>
              <source src={audioUrl} type="audio/mpeg"></source>
              <source src={audioUrl} type="audio/wav"></source>
            </audio>
          </div>
        ) : null}
      </div>
      <div
        className={classNames(
          'copy nsp-row nsp-card-mask',
          index === queryList.length - 1 ? 'copy-first' : '',
          isStudyProject ? 'study-copy' : '',
          cardMaskIndex === copyMaskIndex ? 'copy-show' : 'copy-hide',
        )}
        style={index !== queryList.length - 1 ? { top: rect?.y, left: rect?.x } : {}}
      >
        <div
          className="nsp-row nsp-dir-column sa-spacing-right-12"
          onClick={(e) => {
            e.stopPropagation();
            goSearch?.(e, query);
            dispatch(resetMaskStaus());
            sendNSPLog(
              {
                action: 'click',
                modulename: 'prompt_moreresult',
              },
              cardData,
            );
          }}
        >
          <img src={assets.nspNewCopySearch} className="copy-icon" alt="copy" />
          <span className="sa-spacing-top-2">搜索</span>
        </div>
        <div
          className="nsp-row nsp-dir-column sa-spacing-right-12"
          onClick={(e) => {
            e.stopPropagation();
            copyText(query);
            copyClick?.();
            dispatch(resetMaskStaus());
            sendNSPLog(
              {
                action: 'click',
                modulename: 'prompt_copy',
              },
              cardData,
            );
          }}
        >
          <img src={assets.nspNewCopy} className="copy-icon" alt="copy" />
          <span className="sa-spacing-top-2">复制</span>
        </div>
        {showShareButtons ? (
          <>
            {!disableShare && (
              <div
                className="nsp-row nsp-dir-column sa-spacing-right-12"
                onClick={() => {
                  sendNSPLog(
                    {
                      action: 'click',
                      modulename: 'share',
                    },
                    cardData,
                  );
                  dispatch(resetMaskStaus());
                  onSingleShare?.();
                }}
              >
                <img src={assets.nspNewShare} className="copy-icon" alt="copy" />
                <span className="sa-spacing-top-2">分享</span>
              </div>
            )}
            <div
              className="nsp-row nsp-dir-column"
              onClick={(e) => {
                sendNSPLog(
                  {
                    action: 'click',
                    modulename: 'multi_select',
                  },
                  cardData,
                );
                onBatchShare?.(e);
              }}
            >
              <img src={assets.nspNewSelect} className="copy-icon" alt="copy" />
              <span className="sa-spacing-top-2">多选</span>
            </div>
          </>
        ) : null}
        <i
          className={classNames(index === queryList.length - 1 ? 'copy-arrow-first' : '', 'copy-arrow copy-arrow-user')}
        ></i>
      </div>
    </div>
  );
};
