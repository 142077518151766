import { configureStore } from '@reduxjs/toolkit';

import querySlice from './querySlice';
import studySlice from './studySlice';
import topicNumsSlice from './topicNumsSlice';
import userInfoSlice from './userInfoSlice';

export default configureStore({
  reducer: {
    query: querySlice,
    topic: topicNumsSlice,
    userInfo: userInfoSlice,
    study: studySlice,
  },
});
