import React, { Fragment, useRef, useState, useCallback, useEffect, useMemo, useContext, MouseEvent } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { C } from '../App';
import { globalVar } from '../common/js/global';
import { sendNSPLog } from '../common/js/log';
import { markedInit } from '../common/js/marked';
import {
  shareDialogue,
  hasSelectedDialogue,
  shareSingleDialogue,
  getQbShareParams,
  getShareUrl,
  qbApiShare,
} from '../common/js/share';
import { updateQuery, resetShareState } from '../common/js/store/querySlice';
import { updataTalkingShow } from '../common/js/store/studySlice';
import { copyText, openNaUrl, urlParamsParse } from '../common/js/utils';
import ErrorTips from '../components/errorTips';
import Mask from '../components/mask';
import BackNA from '../components/session/backNA';
import { NspTopic } from '../components/session/nspTopic';
import ShareBottomBar from '../components/session/share-bottom-bar';
import ShareWindow from '../components/session/share-window';
import { UserTopic } from '../components/session/userTopic';

import TransCard from './study/cardTranslate';
import { PageProps, Payload, SourceItem, URLParams } from './types/session';
// eslint-disable-next-line import/order
import { CardData, MaskAudioRef } from './types/talking';

import 'highlight.js/styles/github.css';
import 'katex/dist/katex.min.css';
// eslint-disable-next-line import/order
import { ContextProps } from './types/App';
// eslint-disable-next-line import/order
import { QueryStore, StudyStore, UserinfoState } from './types/store';

const getCurrentUrlParams: URLParams = urlParamsParse();
const { sceneType } = getCurrentUrlParams;
const isStudyProject = sceneType === 'study';
markedInit();

const SessionMain: React.FC<PageProps> = (props: PageProps) => {
  const dispatch = useDispatch();
  const { onreload, talkRef, queryList = [] } = props;
  const maskRef = useRef<MaskAudioRef>();
  const [jumpUrl, setJumpUrl] = useState('');
  // const newQueryList = [...queryList].reverse();

  const { questionList = [], questionIndex = 0 } = useSelector((state: StudyStore) => state.study);
  // 当前题目
  const curQuestion = questionList[questionIndex] || {};
  const { questionId, questionType, questionTextContent: questionContent } = curQuestion;
  // 打开iframe
  const onSourceClick = (item: SourceItem, cardData: CardData) => {
    const { url = '' } = item;
    const sendReferLog = (type: string) => {
      sendNSPLog(
        {
          action: 'click',
          modulename: item.name ? 'reply_quoted_bottom' : 'reply_quoted_inner',
          res_title: encodeURIComponent(url),
          target_type: type,
        },
        cardData,
      );
    };
    // 内容区最大宽度960
    if (globalVar.isPC) {
      window.open(item?.url);
      sendReferLog('h5');
      return;
    }
    if (globalVar.isQB) {
      const qbUrl = `qb://selecttrans?data={"pagetype":"baike",  "query": "${encodeURIComponent(
        url,
      )}", "pagefrom":"SGE", "is_max_height":true}`;
      openNaUrl(qbUrl);
      return;
    }
    window.location.href = url;
    sendReferLog('h5');
  };
  // 打开搜索结果页
  const goSearch = (e?: MouseEvent<HTMLElement>, query?: string) => {
    let url = `https://m.sogou.com/web/searchList.jsp?keyword=${query}&jump_from=1_27_52_01`;
    // 内容区最大宽度960
    if (globalVar.isPC) {
      url = `https://www.sogou.com/web?query=${query}&jump_from=1_27_52_01`;
      window.open(url);
      return;
    }
    // qb里直接打开
    if (globalVar.isQB) {
      window.location.href = url;
      return;
    }
    setJumpUrl(url);
    maskRef.current?.setMask(true);
  };
  const eleRef = useRef<HTMLDivElement>(null);
  // 显示复制弹窗
  const [maskIndex, setMaskIndex] = useState('-1');
  const cardClick = useCallback((e: MouseEvent<HTMLElement>, cardIndex = '') => {
    e.stopPropagation();
    setMaskIndex(cardIndex);
  }, []);
  const { onToast }: ContextProps = useContext(C);
  // 显示toast
  const copyClick = () => {
    onToast('文字已复制');
  };

  // share start
  const hasSelected = hasSelectedDialogue(queryList);
  const [isSharing, setIsSharing] = useState(false);
  const [shareWindow, setShareWindow] = useState(false);
  const [shareId, setShareId] = useState();
  const { userInfo } = useSelector((state: UserinfoState) => state.userInfo);
  const nickName = window.nickName || userInfo.username || '';

  // 取消分享的状态
  const cancelSharingState = () => {
    setShareWindow(false);
    setIsSharing(false);
    dispatch(updataTalkingShow(true));
  };

  const onShareChange = (timestamp: number, type = 'response', checked: boolean) => {
    const payload: Payload = { timestamp };
    if (type === 'request') {
      payload.shareRequest = checked;
    } else {
      payload.shareResponse = checked;
    }
    dispatch(updateQuery(payload));
  };
  const shareOpen = ({ timestamp = 0, type = 'response' } = {}) => {
    setIsSharing(true);
    dispatch(resetShareState({ timestamp, type }));
    dispatch(updataTalkingShow(false));
  };
  const shareClose = () => {
    cancelSharingState();
  };

  const onRealShare = () => {
    const onRealShareAsync = async () => {
      const ret = await shareDialogue(queryList);
      const shareId = ret?.share_tag;
      if (!shareId) {
        onToast('接口调用失败');
        return;
      }
      setShareId(shareId);
      if (globalVar.isQB) {
        const params = getQbShareParams(shareId, nickName);
        const shareApiRet = await qbApiShare(params);
        if (shareApiRet) {
          // 分享成功
          cancelSharingState();
        }
        return;
      }
      openShareWindow();
    };

    onRealShareAsync().catch((e) => {
      console.error(e);
    });
  };

  const onSingleShare = (timestamp: number, type?: string) => {
    (async () => {
      const ret = await shareSingleDialogue(queryList, timestamp, type);
      const shareId = ret?.share_tag;
      if (!shareId) {
        onToast('接口调用失败');
        return;
      }
      setShareId(shareId);
      if (globalVar.isQB) {
        const params = getQbShareParams(shareId, nickName);
        const shareApiRet = await qbApiShare(params);
        if (shareApiRet) {
          // 分享成功
          setIsSharing(false);
        }
        return;
      }
      openShareWindow();
    })().catch((e) => {
      console.error(e);
    });
  };

  const openShareWindow = () => {
    setShareWindow(true);
    dispatch(updataTalkingShow(false));
  };

  const onShareLinkCopy = () => {
    const link = getShareUrl(shareId);
    copyText(link);
    onToast('链接已复制');

    // 重置状态
    setShareWindow(false);
    setIsSharing(false);
    dispatch(updataTalkingShow(true));
  };
  // share end

  // const { showSessionError = null } = useSelector((state: any) => state.topic);

  useEffect(() => {
    if (isStudyProject) {
      const curActionParams = {
        actionType: 'answer-question',
        questionId,
        questionType,
      };
      talkRef?.current?.reloadStudyTopic(questionContent, curActionParams);
      setTimeout(() => {
        const nspSession = document.querySelector('.nsp-session');
        nspSession?.scrollIntoView();
      }, 50);
    }
  }, [questionContent, questionId, questionType, talkRef]);

  const { isNAFrom }: ContextProps = useContext(C);

  return (
    <>
      {isNAFrom ? <BackNA></BackNA> : null}
      {isStudyProject ? <TransCard talkRef={talkRef} goSearch={goSearch} /> : null}
      <div
        className={classNames('sa-spacing-left-12 sa-spacing-right-12 nsp-session')}
        ref={eleRef}
        onClick={() => {
          setMaskIndex('-1');
        }}
      >
        <div className="nsp-session-bottom"></div>
        <div className={classNames('nsp-session-new', isNAFrom ? 'nsp-session-na-gap' : 'sa-spacing-top-20')}>
          {queryList.map((item: any, index: number) => (
            <Fragment key={index}>
              {item.code !== 0 ? (
                <ErrorTips cardData={item} index={index} />
              ) : (
                <NspTopic
                  isSharing={isSharing}
                  isShareChecked={item.shareResponse}
                  onShareChange={(checked) => {
                    onShareChange(item.timestamp, 'response', checked);
                  }}
                  onSingleShare={() => {
                    onSingleShare(item.timestamp);
                  }}
                  onBatchShare={() => {
                    shareOpen({ timestamp: item.timestamp, type: 'response' });
                  }}
                  index={index}
                  maskIndex={maskIndex}
                  eleRef={eleRef}
                  cardData={item}
                  onSourceClick={onSourceClick}
                  cardClick={cardClick}
                  copyClick={copyClick}
                  onreload={onreload}
                  talkRef={talkRef}
                  isStudyProject={isStudyProject}
                  isQBAIName={isNAFrom}
                />
              )}
              {item.topicType === 'add' ? (
                <UserTopic
                  isSharing={isSharing}
                  isShareChecked={item.shareRequest}
                  onShareChange={(checked) => {
                    onShareChange(item.timestamp, 'request', checked);
                  }}
                  onSingleShare={() => {
                    onSingleShare(item.timestamp, 'user');
                  }}
                  onBatchShare={() => {
                    shareOpen({ timestamp: item.timestamp, type: 'response' });
                  }}
                  index={index}
                  cardData={item}
                  maskIndex={maskIndex}
                  cardClick={cardClick}
                  goSearch={goSearch}
                  copyClick={copyClick}
                  talkRef={talkRef}
                  isStudyProject={isStudyProject}
                />
              ) : null}
            </Fragment>
          ))}
        </div>
        {/* {showSessionError ? <ErrorTips code={showSessionError.code} /> : null} */}
      </div>
      <Mask ref={maskRef} jumpUrl={jumpUrl}></Mask>
      {isSharing && <ShareBottomBar disableShare={!hasSelected} onCancel={shareClose} onRealShare={onRealShare} />}
      {shareWindow && (
        <ShareWindow
          onShareLinkCopy={onShareLinkCopy}
          onCancel={() => {
            cancelSharingState();
          }}
        ></ShareWindow>
      )}
    </>
  );
};

const Session: React.FC<PageProps> = (props: PageProps) => {
  const { queryList = [] } = useSelector((state: QueryStore) => state.query);
  // const list = [...queryList].reverse();
  console.log('queryList', queryList);
  useEffect(() => {
    const pagename = 'nsp_dialogpage';
    // 发送首页曝光日志
    sendNSPLog({ action: 'expose', modulename: pagename });
  }, []);
  // 性能优化
  return useMemo(() => <SessionMain {...props} queryList={queryList} />, [queryList]);
};
export default Session;
