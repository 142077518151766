import React, { useImperativeHandle } from 'react';

import classnames from 'classnames';

import { assets } from '../../common/js/assets';
import './checkbox.scss';

interface PagePros {
  className?: string;
  checked: boolean;
  disable?: boolean;
  onChange?: (checked: boolean) => void;
}

const CheckBox = React.forwardRef((props: PagePros, ref: any) => {
  const { onChange, className, checked = false, disable = false } = props;
  let imgSrc = checked ? assets.check1 : assets.check0;
  if (disable) {
    imgSrc = assets.check0;
  }
  const onClick = () => {
    if (disable) {
      return;
    }
    onChange?.(!checked);
  };

  // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    click: () => {
      onClick();
    },
  }));

  return <img className={classnames('checkbox', { disable }, className)} src={imgSrc} onClick={onClick}></img>;
});

export default CheckBox;
