import { assets } from './assets';
import { globalVar } from './global';

function getNews() {
  let news = [
    {
      pageIndex: 0,
      active: true,
      pages: [
        {
          title: '想获取的信息',
          content: '今天天气怎么样？',
          leftIcon: assets.skill,
          rightIcon: '',
        },
        {
          title: '想创作的作品',
          content: '写一篇短篇科幻小说',
          leftIcon: assets.creation,
          rightIcon: '',
        },
        {
          title: '想完成的任务',
          content: '播放电视剧《你给我的喜欢》',
          leftIcon: assets.task,
          rightIcon: '',
        },
      ],
    },
    {
      pageIndex: 1,
      pages: [
        {
          title: '想获得的技能',
          content: '股票投资入门指南',
          leftIcon: assets.skill,
          rightIcon: '',
        },
        {
          title: '想解决的问题',
          content: '如何用Python编写简单的游戏？',
          leftIcon: assets.problem,
          rightIcon: '',
        },
        {
          title: '想创作的作品',
          content: '请帮我整理一份互联网产品经理岗位的简历模板',
          leftIcon: assets.creation,
          rightIcon: '',
        },
      ],
    },
    {
      pageIndex: 2,
      pages: [
        {
          title: '想解决的问题',
          content: '如何预防感冒和流感？',
          leftIcon: assets.problem,
          rightIcon: '',
        },
        {
          title: '想创作的作品',
          content: '请帮我写一首关于爱情的诗歌，采用泰戈尔的风格。',
          leftIcon: assets.creation,
          rightIcon: '',
        },
        {
          title: '想获取的信息',
          content: '最近有什么好看的电视剧，能推荐一些吗？',
          leftIcon: assets.skill,
          rightIcon: '',
        },
      ],
    },
    {
      pageIndex: 3,
      pages: [
        {
          title: '想了解的知识',
          content: '请告诉我关于量子计算的基础知识',
          leftIcon: assets.knowledge,
          rightIcon: '',
        },
        {
          title: '想获得的技能',
          content: '你能告诉我如何制作巧克力蛋糕吗？',
          leftIcon: assets.skill,
          rightIcon: '',
        },
        {
          title: '想解决的问题',
          content: '哪些食物可以降低血压和胆固醇？',
          leftIcon: assets.problem,
          rightIcon: '',
        },
      ],
    },
  ];
  // 如果是在pc下数据结构转换
  if (globalVar.isPC) {
    news = [
      {
        pageIndex: 0,
        active: true,
        pages: [
          {
            title: '想获取的信息',
            content: '今天天气怎么样？',
            leftIcon: assets.skill,
            rightIcon: '',
          },
          {
            title: '想创作的作品',
            content: '写一篇短篇科幻小说',
            leftIcon: assets.creation,
            rightIcon: '',
          },
          {
            title: '想完成的任务',
            content: '播放电视剧《你给我的喜欢》',
            leftIcon: assets.task,
            rightIcon: '',
          },
          {
            title: '想获得的技能',
            content: '股票投资入门指南',
            leftIcon: assets.skill,
            rightIcon: '',
          },
        ],
      },
      {
        pageIndex: 1,
        pages: [
          {
            title: '想解决的问题',
            content: '如何用Python编写简单的游戏？',
            leftIcon: assets.problem,
            rightIcon: '',
          },
          {
            title: '想创作的作品',
            content: '请帮我整理一份互联网产品经理岗位的简历模板',
            leftIcon: assets.creation,
            rightIcon: '',
          },
          {
            title: '想解决的问题',
            content: '如何预防感冒和流感？',
            leftIcon: assets.problem,
            rightIcon: '',
          },
          {
            title: '想创作的作品',
            content: '请帮我写一首关于爱情的诗歌，采用泰戈尔的风格。',
            leftIcon: assets.creation,
            rightIcon: '',
          },
        ],
      },
      {
        pageIndex: 2,
        pages: [
          {
            title: '想获取的信息',
            content: '最近有什么好看的电视剧，能推荐一些吗？',
            leftIcon: assets.skill,
            rightIcon: '',
          },
          {
            title: '想了解的知识',
            content: '请告诉我关于量子计算的基础知识',
            leftIcon: assets.knowledge,
            rightIcon: '',
          },
          {
            title: '想获得的技能',
            content: '你能告诉我如何制作巧克力蛋糕吗？',
            leftIcon: assets.skill,
            rightIcon: '',
          },
          {
            title: '想解决的问题',
            content: '哪些食物可以降低血压和胆固醇？',
            leftIcon: assets.problem,
            rightIcon: '',
          },
        ],
      },
    ];
  }
  return news;
}

const news = getNews();
const first = news[0];
const last = news[news.length - 1];
const newsData = [...news];
newsData.push(first);
newsData.unshift(last);

export { newsData, news };
