import React, { useRef, useState, useImperativeHandle, forwardRef, useEffect, useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { C } from '../App';
import { globalVar } from '../common/js/global';
import { sendNSPLog } from '../common/js/log';
import { mockCard } from '../common/js/mock';
import { scrollToBottom } from '../common/js/scroll';
import { pushErrorInfo, resetQueryList, updateQuery, disableAudioTopicStatus } from '../common/js/store/querySlice';
import { updateQuestionIndex, updateTopicCreating, updateWordStorageMap } from '../common/js/store/studySlice';
import {
  resetTopicList,
  setShowSessionError,
  updateTopicList,
  setTimeOutError,
} from '../common/js/store/topicNumsSlice';
import { reportLog } from '../common/js/trace/reportLog';
import { replaceReferTag, requestPauseTopic, requestQuery, requestUpload } from '../common/js/utils';
import TalkingBox from '../components/talking/talkingBox';

import { updateUrlParameter } from './study/js/util';
import { ContextProps, StopResponseParams, URLParams } from './types/App';
import { TopicState } from './types/store';
import {
  AudioCompoundData,
  AudioData,
  AudioListItem,
  ErrorInfo,
  Frame,
  FrameData,
  CreateParams,
  PageProps,
  RequestData,
  ResultParams,
  TopicBtnData,
  TopicCurrent,
  ImageUploadParmas,
  UploadRes,
  StopRequest,
  TopicEndParams,
  PicParams,
  MarkdownLink,
} from './types/talking';

// eslint-disable-next-line sonarjs/cognitive-complexity
const Talking: React.FC<PageProps> = forwardRef((props: PageProps, ref: any) => {
  const { nspKey, sceneType, rsword, from, talkingShow } = props;
  const { onToast, onQuickTopic }: ContextProps = useContext(C);
  const talkingBoxRef = useRef<any>();
  // 计时
  const timeOut = useRef<any>(null);
  // 话题轮数
  const topicList = useRef<TopicCurrent[]>([]);
  // 当前话题
  const topicCurrent = useRef<TopicCurrent>({});
  // 获取redux中记录的数据状态,部分数据更新不及时 需要设置变量为let
  const topicStore = useSelector((state: TopicState) => state.topic);
  const { maxNum = 0 } = topicStore || {};
  // 剩余话题数
  const remainingTopics = useRef<number>(topicStore.remainingTopics || 0);

  useEffect(() => {
    onQuickTopic();
  }, []);

  // 会话生成中
  // const [isCreateTopicing, setIsCreateTopicing] = useState(false);
  // 一次会话最大轮数
  const maxLen = topicStore.topicMax || 9;
  // 是否为学习项目
  const isStudyProject = sceneType === 'study';
  const { questionList = [], questionIndex = 0 } = useSelector((state: any) => state.study);
  const isLastQuestion = questionIndex === questionList.length - 1;
  const curQuestion = questionList[questionIndex] || {};
  const { questionId, questionType } = curQuestion;
  const searchWordList = useRef<string[]>([]);
  // AI结果页跳转NSP避免多次请求
  const nspLock = useRef<boolean>(false);
  // 最新完成的搜索词
  const [searchKeyword, setSearchKeyword] = useState(searchWordList.current[searchWordList.current.length - 1]);
  const { userInfo } = useSelector((state: any) => state.userInfo);
  // 音乐合成List
  const audioList = useRef<AudioListItem[]>([]);
  // 更新输入
  const dispatch = useDispatch();
  // 清除计时
  const clearTopicTimeOut = () => {
    // 10分钟内有交互或者无次数时不再提醒
    if (timeOut.current) {
      clearTimeout(timeOut.current);
      timeOut.current = null;
    }
  };
  const onRequestPause = (params: StopResponseParams) => {
    reportLog('onRequestPause', topicCurrent.current);
    const { dialogueID, sessionID, timestamp, frameID } = topicCurrent.current;
    const { response } = params;
    requestPauseTopic({
      dialogueID,
      sessionID,
      timestamp,
      frameID,
    });
    // 显示重新编辑功能
    talkingBoxRef.current.setPauseTopic(true);
    // topicCurrent.current.stopResponse = true;
    stopRequest({ timestamp, response });
    sendNSPLog(
      {
        action: 'click',
        modulename: 'stop_reply',
      },
      topicCurrent.current,
    );
    // dispatch(setStopResponse(true));
  };
  const stopRequest = ({ timestamp, response }: StopRequest) => {
    const len = topicList.current.length;
    topicCurrent.current = {
      ...topicCurrent.current,
      timestamp,
      response,
      stat: {
        code: 11,
        msg: '',
      },
      isEnd: true,
      isLoading: !response,
      stopResponse: true,
      isCreateTopicing: false,
      cardIndex: topicCurrent.current.cardIndex || len,
    };
    talkingBoxRef.current.resetStatus();
    updateCardInfo(topicCurrent.current);
    // 每次创建会话，重置停止响应状态
    // stopResponse = false;
  };
  const limitError = (times?: number) => {
    const len = topicList.current.length;
    const timestamp = times || Date.now();
    const { topic, sessionID } = topicCurrent.current;
    // 处理次数超限
    let errorInfo: ErrorInfo = { code: 0, timestamp };
    if ((remainingTopics.current <= 0 && len > maxLen) || (remainingTopics.current <= 0 && len === 0)) {
      errorInfo = {
        type: 'error',
        code: 40003,
        timestamp,
        message: topic,
        sessionID,
      };
      onResetTopic();
      dispatch(pushErrorInfo(errorInfo));
      // updateCardInfo(errorInfo);
    }
    reportLog('limitError', errorInfo);
    return errorInfo;
  };

  // 重启新话题
  const onResetTopic = () => {
    topicList.current = [];
    talkingBoxRef.current.resetStatus();
    topicCurrent.current = {};
    updateCardInfo(topicCurrent.current);
    // 更新音频话题状态
    setTimeout(() => {
      dispatch(disableAudioTopicStatus());
    }, 100);
    reportLog('onResetTopic', topicCurrent.current);
  };
  // 当前话题开启新的一轮对话
  const onNewRoundTopic = () => {
    const { sessionID, dialogueID, type, reqPic } = topicCurrent.current;
    topicCurrent.current = {
      sessionID,
      dialogueID,
      type,
      reqPic: type?.includes('pic') ? reqPic : undefined,
    };
  };

  // 更新nsp对话框状态数据
  const updateCardInfo = (data: TopicCurrent) => {
    if (Object.keys(data).length === 0 || !data?.timestamp) {
      talkingBoxRef?.current?.setTopicCurrent({});
      return;
    }
    // 避免部分异步数据更新时字段丢失，比如AI回复中一直长按分享更新弹窗等
    if (topicCurrent.current.timestamp === data.timestamp) {
      dispatch(updateQuery({ ...topicCurrent.current, ...data }));
    } else {
      dispatch(updateQuery(data));
    }
    talkingBoxRef?.current?.setTopicCurrent(data);
  };

  // 手动跳转
  const navigate = useNavigate();

  // const resetStatus = () => {};
  // 音乐合成结束话题
  const onStopMuiscTopic = () => {
    clearTopicTimeOut();
    const { sessionID } = topicCurrent.current;
    topicCurrent.current.stopMusic = true;
    updateCardInfo(topicCurrent.current);
    // 更新页面会话提示
    dispatch(
      pushErrorInfo({
        code: 80012,
        remainingTopics: remainingTopics.current,
        sessionID,
        query: topic,
      }),
    );
    setTimeout(() => {
      onResetTopic();
      talkingBoxRef?.current?.setTopicCurrent(topicCurrent.current);
    }, 20);
  };

  // 请求结束的处理
  const reqSuccess = () => {
    if (!topicCurrent.current.timestamp) {
      return;
    }
    topicCurrent.current.isCreateTopicing = false;
    talkingBoxRef?.current?.setShowTopicBtns(true);
    const len = topicList.current.length;
    // 返回成功计入次数更新
    const { dialogueID, sessionID, query = '', timestamp, dataType } = topicCurrent.current;
    const { remainingTopics = 0, remainingTokens = 0, resetSession } = topicCurrent.current.done || {};
    if (from === 'topBarIcon') {
      talkingBoxRef.current.setShowSearchBox(true);
    }
    // 快照类型直接跳过
    if (dataType === 'snapshot') {
      // 从搜索框进来
      if (nspKey && (from === 'searchBox' || from === 'showmore' || from === 'abstract')) {
        talkingBoxRef?.current?.setShowSearchBox(true);
      }
      if (from === 'searchBox' && nspLock.current === false && window.browser?.search?.onWebInputShown) {
        // 避免快速闪动
        setTimeout(() => {
          const nspSessionInput = document.getElementById('nspSessionInput');
          const inputRects = nspSessionInput?.getClientRects()[0];
          window.browser.search.onWebInputShown(null, null, inputRects);
          // talkingBoxRef.current.setShowSearchBox(false);
          // talkingBoxRef?.current?.onFocus();
        }, 100);
        nspLock.current = true;
      }
      topicList.current.push(topicCurrent.current);
      onNewRoundTopic();
      console.log('reqSuccess', rsword, nspLock.current);
      // 如果是点击rs进入NSP页面进行第二次搜索
      if (rsword && nspLock.current === false) {
        nspLock.current = true;
        setTimeout(() => {
          setQuickTopic(decodeURIComponent(rsword));
        }, 100);
      }
      return;
    }
    const publishTips = (code: number) => {
      dispatch(
        pushErrorInfo({
          query,
          code,
          remainingTopics,
          sessionID,
          dialogueID,
          timestamp: Date.now(),
        }),
      );
    };
    // 如果命中信安，强制重启新话题
    if (resetSession === true) {
      publishTips(80009);
      onResetTopic();
      return;
    }
    dispatch(
      updateTopicList({
        ...topicCurrent.current,
        numNeedLimit: !isStudyProject,
      }),
    );
    // 习题项目不做次数限制
    if (isStudyProject) {
      return;
    }
    // 停止响应不继续
    if (topicCurrent.current.stopResponse) {
      return;
    }
    // 成功合成音乐提示
    if (topicCurrent.current.type === 'audioCompound') {
      // publishTips(80010);
      // talkingBoxRef.current.resetStatus();
      onStopMuiscTopic();
      return;
    }
    if (!topicList.current.some((item) => item?.timestamp === timestamp)) {
      topicList.current.push(topicCurrent.current);
    }

    // 轮次超限提醒
    if (len >= maxLen) {
      publishTips(40002);
      onResetTopic();
      return;
    }
    if (topicCurrent.current.type === 'audioCompoundError') {
      return;
    }
    // 无次数时提醒
    if (+remainingTopics <= 0 && len >= maxLen) {
      publishTips(40003);
      onResetTopic();
      return;
    }
    // 字数超过限制, 开启新话题
    if (+remainingTokens <= maxNum && len < maxLen) {
      dispatch(setTimeOutError());
      publishTips(10009);
      onResetTopic();
    }
    // 小于3轮才提示长时间提示
    if (len < maxLen) {
      // 十分钟无操作认为超时
      timeOut.current = setTimeout(() => {
        dispatch(setTimeOutError());
        publishTips(40007);
        onResetTopic();
      }, 1000 * 60 * 10);
    }
    // 二次搜索完成
    if (nspLock.current) {
      talkingBoxRef.current.setShowSearchBox(true);
    }
    // 存在搜索词
    if (query) {
      setSearchKeyword(query);
    }
    localStorage.removeItem('NSPSession');
  };

  // 新话题
  const onNewTopic = () => {
    // 可以创建新话题
    const isCreateIng = topicCurrent.current.isCreateTopicing !== true;
    const isNewTopic = isCreateIng && window.location.pathname === '/session';
    // 创建中无法使用
    if (!isCreateIng) {
      return;
    }
    reportLog('onNewTopic', {
      ...topicCurrent.current,
      remainingTopics: remainingTopics.current,
    });
    // 查询会话次数
    onResetTopic();
    const errorInfo = limitError();
    topicCurrent.current = {};
    if (errorInfo.code !== 0) {
      return;
    }
    // 新会话
    if (isNewTopic) {
      talkingBoxRef.current.setPauseTopic(false);
      // 新建会话 清除超时
      clearTopicTimeOut();
      let errorInfo = null;
      // remainingTopics.current -= 1;
      if (remainingTopics.current > 0) {
        errorInfo = {
          code: 80008,
          remainingTopics: remainingTopics.current,
        };
        // 更新可用次数
        dispatch(setShowSessionError(errorInfo));
      }
      // setPauseTopic(false);
      // 更新页面会话提示
      dispatch(pushErrorInfo(errorInfo));
    }
    sendNSPLog({ action: 'click', modulename: 'restart' }, topicCurrent.current);
  };
  const timerRef: any = useRef(null);
  const topic = useRef<string>('');
  const musicCurrent = useRef<AudioListItem>();
  // 超时
  const resultParams = useRef<ResultParams | null>(null);
  // 请求时间，轮询接口使用
  const reqTimestamp = useRef<number>(0);
  // 快捷发起会话
  const setQuickTopic = (value: string, actionParams?: any, rs?: URLParams) => {
    reportLog('setQuickTopic', {
      value,
      rs,
    });
    const errorInfo = limitError(Date.now());
    if (+errorInfo.code > 0) {
      return;
    }
    topic.current = value;
    resultParams.current = rs ? { nspKey: rs.nspKey, rsword: rs.rsword } : null;
    if (!searchKeyword) {
      setSearchKeyword(value);
    }
    onTextTopic(
      {
        query: value,
        type: 'text',
        timestamp: Date.now(),
      },
      actionParams,
    );
    // 不存在rs二次搜索，直接置空
    if (rs && !rs.rsword) {
      resultParams.current = null;
    }
  };
  const onTopicEnd = (params: TopicEndParams) => {
    const { response = '' } = params;
    topicCurrent.current = { ...topicCurrent.current, response, isEnd: true, isCreateTopicing: false };
    updateCardInfo(topicCurrent.current);
    reportLog('onTopicEnd', topicCurrent.current);
    setTimeout(() => {
      reqSuccess();
      scrollToBottom();
    }, 20);
  };
  const setRemainingTopics = (topics: number) => {
    remainingTopics.current = +topics;
  };

  const setCardInfo = (cardData: TopicCurrent) => {
    // topicCurrent.current.voteStatus = vote;
    updateCardInfo(cardData);
  };

  // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // 暴露给父组件的方法
    setShowMultimode: talkingBoxRef?.current?.setShowMultimode,
    onPopState: talkingBoxRef?.current?.onPopState,
    setQuickTopic,
    setRemainingTopics,
    setCardInfo,
    setShowTopicBox: talkingBoxRef?.current?.setShowTopicBox,
    reqSuccess,
    onMiuscClick,
    onRecordClick,
    onRequestPause,
    onTopicEnd,
    limitError,
    onFocus: talkingBoxRef?.current?.onFocus,
    onBlur: talkingBoxRef?.current?.onBlur,
  }));
  const onRecordClick = (params: TopicCurrent) => {
    const { dialogueID, sessionID, remainingTopics } = params?.done || {};
    updateCardInfo({ ...params, musicDisable: true });
    dispatch(
      pushErrorInfo({
        code: 80011,
        remainingTopics,
        sessionID,
        dialogueID,
      }),
    );
    setTimeout(() => {
      onCreateMusicTopic();
    }, 0);
  };
  const onMiuscClick = (musicItem: AudioListItem) => {
    const { audioID, name, singer, audioCompound } = musicItem;
    topicCurrent.current.type = 'audioCompound';
    console.log('onMiuscClick', musicItem, topicCurrent.current);
    const { audioInfo } = topicCurrent.current;
    const timestamp = Date.now();
    const musicItemParams = {
      type: 'audioCompound',
      query: `🎵${name}-${singer}`,
      timestamp,
      audioCompound: audioCompound || {
        audioUrl: audioInfo?.audioUrl || '',
        selectAudioID: audioID,
      },
      dialogueID: topicCurrent.current.dialogueID,
      sessionID: topicCurrent.current.sessionID,
    };
    topicCurrent.current = {
      // ...topicCurrent.current,
      ...musicItemParams,
      musicItem,
    };
    // console.log(topic);
    onCreateSearch(musicItemParams);
    // scrollToBottom();
  };
  const onTextTopic = (createParams: CreateParams, actionParams?: object) => {
    const { query = '', timestamp, type = 'text', audioInfo } = createParams || {};
    reportLog('onTextTopic', createParams);
    const errorInfo = limitError(timestamp);
    // 当前会话有错误 不能继续
    if (+errorInfo?.code > 0 && !isStudyProject) {
      // scrollToBottom();
      return;
    }
    // 每一次新话题进行重置
    onNewRoundTopic();
    talkingBoxRef?.current?.resetStatus();
    // 当前需要请求后端的话题数据类型
    let reqType = topicCurrent.current.type || type;
    let reqPic: PicParams | undefined;
    // 图片开发指令
    if (topicCurrent.current?.type?.includes('pic') && topicList.current.length < 3) {
      reqType = 'picInstruct';
      reqPic = topicCurrent.current.reqPic;
      // dispatch(setUploadType(uploadType.current));
    }
    // 音乐录音类型
    if (type.includes('audio')) {
      reqType = type;
      topicCurrent.current.audioInfo = audioInfo;
    }
    topicCurrent.current = { ...topicCurrent.current, timestamp, query };
    // 多模类型大于三轮需要转换为文本格式
    if (topicList.current.length >= 3) {
      topicCurrent.current.type = 'text';
      reqType = 'text';
    }
    topicCurrent.current.type = reqType;
    // 重置停止响应状态
    // stopResponse.current = false;
    onCreateSearch({ ...createParams, type: reqType, pic: reqPic }, actionParams);
  };

  const onCatchError = (error: any, errorInfo?: ErrorInfo) => {
    if (window.location.search.indexOf('debug=') > -1) {
      alert(error);
    }
    topicCurrent.current.errorInfo = { ...errorInfo, code: 88888, timestamp: errorInfo?.timestamp || Date.now() };
    console.error('onCatchError', error, errorInfo);
    topicCurrent.current.isCreateTopicing = false;
    // setIsCreateTopicing(false);
    dispatch(updateTopicCreating(false));
    updateCardInfo(topicCurrent.current);
    talkingBoxRef?.current?.setTopicCurrent(topicCurrent.current);
    setTimeout(() => {
      // scrollToBottom();
    }, 100);
  };
  // 图片上传发起请求
  const onCreateImgTopic = (params: ImageUploadParmas) => {
    const { timestamp = 0, dataURI, file, fileName } = params;
    const errorInfo = limitError(timestamp);
    // 当前会话有错误 不能继续
    if (+errorInfo?.code > 0 && !isStudyProject) {
      // scrollToBottom();
      return;
    }
    // 每一次新话题进行重置
    onResetTopic();
    talkingBoxRef.current.resetStatus();
    topicCurrent.current = {
      ...topicCurrent.current,
      ...{
        picUpload: {
          url: dataURI,
        },
        query: '',
        ...errorInfo,
      },
      timestamp,
    };
    updateCardInfo(topicCurrent.current);
    // 每次发起会话需等页面渲染以后进行滚动到最底部
    requestUpload(file, fileName).then((res: UploadRes) => {
      const { url, code, contentDetected } = res.data;
      if (topicCurrent.current.stopResponse) {
        return;
      }
      // 信安检测失败
      if (41000 < code && code < 42000) {
        topicCurrent.current = {
          ...topicCurrent.current,
          isEnd: true,
          response: globalVar.uploadTips.safe,
        };
        updateCardInfo(topicCurrent.current);
        // onToast(globalVar.uploadTips.safe);
        onCatchError({}, { timestamp, code: 80006 });
        onNewTopic();
        return;
      }
      if (code === 23) {
        onToast(globalVar.uploadTips.buys);
        onCatchError({}, { timestamp, code: 80006 });
        return;
      }
      // 图片上传失败
      if (code !== 0) {
        onToast(globalVar.uploadTips.error);
        onCatchError({}, { timestamp, code: 80006 });
        return;
      }
      // talkingBoxRef?.current?.setContentDetected(contentDetected);
      // showcontentDetected = contentDetected;
      topicCurrent.current.type = 'pic';
      topicCurrent.current.picUpload = {
        url,
      };
      // updateCardInfo(topicCurrent.current);
      setTimeout(() => {
        const params = {
          url,
          timestamp: timestamp || 0,
          type: 'pic',
          contentDetected,
        };
        topicCurrent.current.reqPic = {
          url,
          contentDetected,
        };
        // 请求nsp回复服务
        onCreateSearch(params);
      }, 0);
    });
  };
  // 音频话题发起的请求
  const onCreateMusicTopic = () => {
    if (window.location.pathname === '/') {
      navigate('/session');
    }
    // 不在创建中时可以进行操作
    if (!topicCurrent.current.isCreateTopicing) {
      // 每一次新话题进行重置
      onResetTopic();
      talkingBoxRef.current.resetStatus();
      const timestamp = Date.now();
      const audioParams = {
        type: 'audioCompoundStart',
        timestamp,
        query: globalVar.audio.compound,
      };
      topicCurrent.current = { ...topicCurrent.current, ...audioParams };
      onCreateSearch(audioParams);
    }
  };

  // 当前操作参数
  const curActionParams = useRef(null);
  // 创建会话
  const onCreateSearch = (createParams: CreateParams, actionParams?: any) => {
    reportLog('onCreateSearch', createParams);
    const { url, query, contentDetected, type } = createParams;
    // 每次请求唯一的时间戳
    const timestamp = createParams?.timestamp || Date.now();
    const audioCompound = createParams.audioCompound || null;
    try {
      if (actionParams) {
        curActionParams.current = actionParams;
      }
      const actionType = actionParams?.actionType;
      // 10分钟内有交互或者无次数时不再提醒
      clearTopicTimeOut();
      reqTimestamp.current = timestamp;
      const { sessionID } = topicCurrent.current;
      const errorInfo = limitError(timestamp);
      // 当前会话有错误 不能继续
      if (+errorInfo?.code > 0) {
        return;
      }
      // 纯文字输入模式
      if (topic && topicCurrent.current.type !== 'pic') {
        const topicType = actionType === 'answer-question' ? actionType : 'add';
        topicCurrent.current.topicType = topicType;
        // topicRef?.current?.blur();
        updateCardInfo(topicCurrent.current);
      }
      talkingBoxRef.current.onReset();
      let params = {
        timestamp,
        sessionID,
        userID: userInfo.username || '',
        type: type || topicCurrent.current.type,
      };
      // 如果为学习接口，增加学习项目请求参数
      if (isStudyProject) {
        const studyParams = {
          studyReq: {
            query: topic,
            actionParams,
          },
          type: 'study',
        };
        params = { ...params, ...studyParams };
      } else {
        const mainParams = {
          query,
          pic: createParams?.pic?.url
            ? createParams.pic
            : {
                url,
                contentDetected,
              },
          sessionIndex: topicList.current.length + 1,
          audioCompound,
        };
        params = { ...params, ...mainParams };
        searchWordList.current.push(topic.current);
      }
      topicCurrent.current = {
        ...topicCurrent.current,
        timestamp,
        sessionID,
        isCreateTopicing: true,
      };
      // talkingBoxRef?.current?.setTopicCurrent(topicCurrent.current);

      // 每次发起会话需等页面渲染以后进行滚动到最底部
      setTimeout(() => {
        scrollToBottom(isStudyProject);
      }, 50);
      if (window.location.href.includes('dbg=on')) {
        const { response, references } = mockCard;
        topicCurrent.current.frameData = {
          response,
          references,
          docIDs: [],
        };
        topicCurrent.current.isCreateTopicing = false;
        return updateCardInfo(topicCurrent.current);
      }
      // 请求后端
      window.aegis.time('repeactRequest');
      repeactRequest(params);
    } catch (error) {
      window.aegis.error({
        msg: 'onCreateSearch error',
        ext1: JSON.stringify(error),
        ext2: JSON.stringify({ userID: window.nickName }),
      });
      onCatchError(error);
    }
  };

  /**
   *
   * @param data 需要进行处理的内容，用于打字
   * @param now
   * @param endUpdateData
   * @returns
   */
  const handlerContentOutput = async (data: FrameData, linkMap?: MarkdownLink) => {
    const docID = data?.docIDs?.[0];
    const currentDoc = data?.references?.[+docID];
    // 分割句子
    const updateStr = data?.data;
    const references = Array.isArray(topicCurrent.current.references) ? [...topicCurrent.current.references] : [];
    const responseList = Array.isArray(topicCurrent.current.responseList) ? [...topicCurrent.current.responseList] : [];

    // 多个refer情况
    if (data?.docIDs.length >= 1) {
      data?.docIDs.forEach((item: string | number) => {
        const doc = data?.references?.[+item];
        const docID = doc?.doc_id || doc?.docId;
        if (doc && !references.some((item) => item.doc_id === docID || item.docId === docID)) {
          references.push(doc);
        }
      });
    }
    responseList.push({
      content: linkMap ? '' : updateStr || '',
      doc: currentDoc,
      linkMap,
    });
    topicCurrent.current = {
      ...topicCurrent.current,
      references,
      responseList,
      originConetnt: (topicCurrent.current.originConetnt || '') + updateStr,
    };
  };

  // 处理类型数据，是文本 图片 stat 语音
  const handlerDataType = (frames: Frame[], reqParams: RequestData) => {
    const len = topicList.current.length;
    frames?.forEach((frame) => {
      // 过渡类型
      if (frame?.stat) {
        topicCurrent.current.stat = frame.stat;
        // 搜索增强处理
        const topicBtnData: TopicBtnData =
          frame?.stat?.data && frame?.stat?.code !== 20 ? JSON.parse(frame.stat.data) : [];
        const queries = Array.isArray(topicBtnData.queries) ? topicBtnData.queries.slice(0, 3) : [];
        if (queries.length > 0) {
          topicCurrent.current.statQueries = queries;
        }
      } else if (frame?.pic) {
        topicCurrent.current.pic = frame.pic;
        topicCurrent.current.isLoading = false;
      } else if (frame?.card) {
        // frame Card赋值
        topicCurrent.current.card = frame.card.data;
      } else if (frame?.data) {
        const framData = frame?.data;
        const { type, data = '' } = framData;
        topicCurrent.current.dataType = type;
        topicCurrent.current.isLoading = false;
        // 若为学习项目，且为操作信息
        if (isStudyProject && type === 'action' && data) {
          const actionData = JSON.parse(data);
          topicCurrent.current.actionData = actionData;
        } else if (type === 'snapshot' && data) {
          // 结果页快照
          const rsData = JSON.parse(data);
          const docRefInfos = rsData.doc_ref_infos;
          const { references, response, recommend } = rsData;
          topicCurrent.current = {
            ...topicCurrent.current,
            originConetnt: response,
            response: replaceReferTag(response, docRefInfos, references),
            references,
          };
          // rs推荐词
          if (recommend?.data && Array.isArray(recommend?.data)) {
            const btns = recommend.data.slice(0, 3).map((item: { query: string }) => item.query);
            talkingBoxRef.current.setTopicBtns(btns);
            talkingBoxRef?.current?.showTopicBtns?.(true);
          }
        } else if (type === 'recommend') {
          // 第三轮会话不进行推荐展现
          if (len < maxLen) {
            const topicBtnData: TopicBtnData = JSON.parse(data);
            const btns = Array.isArray(topicBtnData.queries) ? topicBtnData.queries.slice(0, 3) : [];
            talkingBoxRef.current.setTopicBtns(btns);
            // setShowTopicBtns(true);
            // setShowPicsBtn(true);
          }
        } else if (type === 'audioCompound') {
          const audioData: AudioCompoundData = JSON.parse(data);
          topicCurrent.current.audioCompoundParams = audioData;
        } else if (type === 'audioCompoundList') {
          const audioData: AudioData = JSON.parse(data);
          const { category, message } = audioData;
          const joinAudioList = (category: AudioData['category']): AudioListItem[] => {
            let list: AudioListItem[] = [];
            category.forEach((item) => {
              list = list.concat(item.audio);
            });
            return list;
          };
          audioList.current = joinAudioList(category);
          topicCurrent.current.audioParams = {
            list: audioList.current,
            message,
          };
        } else {
          let linkMap: MarkdownLink | undefined;
          if (type === 'link') {
            // markdown格式文本不走打字机，直接输出内容
            linkMap = JSON.parse(data);
          }
          // 存在轮询的文本数据
          reqTimestamp.current = Date.now();
          topicCurrent.current.dataType = 'data';
          // 处理文本内容输出
          handlerContentOutput(frame.data, linkMap);
        }
      } else if (frame?.done) {
        const { remainingTokens, resetSession, code } = frame?.done || {};
        const { sessionID = '', dialogueID = '', pic } = reqParams;
        const { type, cardIndex = 0 } = topicCurrent.current;
        if (type?.includes('pic') && pic?.contentDetected === 'person' && cardIndex < 2) {
          // repactMap.response = globalVar.tips.pic2;
          talkingBoxRef.current.setPicUrl(topicCurrent.current.picUpload);
          talkingBoxRef.current.setShowPicsBtn(true);
        }
        // uploadType = '';
        // setUploadType(uploadType);
        remainingTopics.current = +frame?.done?.remainingTopics;
        // remainingTopics.current = 0;
        // 更新数据接受完成的数据信息
        topicCurrent.current.done = {
          remainingTopics: +remainingTopics.current,
          remainingTokens,
          resetSession,
          sessionID,
          dialogueID,
          cursor: 'end',
          code,
        };
        if (!isStudyProject) {
          // scrollToBottom();
        }
      } else if (frame?.debug) {
        topicCurrent.current.debug = frame?.debug;
      }
      topicCurrent.current.cardIndex = topicCurrent.current.cardIndex || len;
      updateCardInfo(topicCurrent.current);
    });
  };

  // 请求处理的操作
  const repeactRequest = async (params: RequestData) => {
    const { timestamp, query, userID } = params;
    // 接口返回 axios对象
    const res: any = await requestQuery(params, !nspLock.current ? resultParams.current || {} : {});
    const len = topicList.current.length;
    reportLog('repeactRequest', {
      req: params,
      res,
    });
    // if (type === 'audioCompound') {
    //   res.data.code = 44000;
    // }
    // res.data.code = 44001;
    const { code, ctrlFlag, sessionID, dialogueID, frameID, frames } = res.data;
    // 学习项目，需要更新sessionID
    if (isStudyProject) {
      dispatch(
        updateTopicList({
          ...topicList.current,
          sessionID,
          numNeedLimit: !isStudyProject,
        }),
      );
    }
    // 43000代表用户手动停止响应
    if (code === 43000) {
      return;
    }
    // 音乐合成状态错误，走单独的AI回复里提示
    if (code >= 44000 && code <= 44999) {
      topicCurrent.current.done = {
        sessionID,
        dialogueID,
        cursor: 'isEnd',
        remainingTopics: remainingTopics.current,
        remainingTokens: '',
      };
      topicCurrent.current.audioCompoundParams = {
        errorCode: code,
        audio: musicCurrent.current,
      };
      topicCurrent.current.cardIndex = topicCurrent.current.cardIndex || len;
      topicCurrent.current.type = 'audioCompoundError';
      // setIsCreateTopicing(false);
      // setCreateTopic(false);
      topicCurrent.current.isCreateTopicing = false;
      topicCurrent.current.isLoading = false;
      // topicList.current.push(topicCurrent.current);
      updateCardInfo(topicCurrent.current);
      return;
    }
    // 接口报错 直接返回错误
    if (code !== 0) {
      // setCreateTopic(false);
      topicCurrent.current.code = code;
      topicCurrent.current.isCreateTopicing = false;
      topicCurrent.current.type = '';
      updateCardInfo(topicCurrent.current);
      if (!isStudyProject) {
        talkingBoxRef.current.resetStatus();
        return;
      }
      return;
    }
    // 0代表正常轮询 2代表数据生成中
    const isCtrl0 = ctrlFlag === 0;
    const isCtrl2 = ctrlFlag === 2;
    topicCurrent.current = { ...topicCurrent.current, dialogueID, sessionID };
    // 请求参数
    const reqParams = {
      timestamp,
      query,
      sessionID,
      dialogueID,
      frameID,
      userID,
      sessionIndex: len + 1,
    };
    try {
      if (isCtrl0 || isCtrl2) {
        // 缓存未请求完成的会话
        localStorage.setItem('NSPSession', JSON.stringify(params));
        const duration = isCtrl0 ? 20 : 1000;
        const min = 1000 * 60 * 10;
        // 没有数据 如果是0正常轮询 数据生成1秒轮询一次
        timerRef.current = setTimeout(() => {
          // 超过1分钟不再进行轮询请求 提示超时
          if (Date.now() - reqTimestamp.current < min) {
            repeactRequest({ ...params, ...reqParams });
          } else {
            // setCreateTopic(false);
            // setIsCreateTopicing(false);
            topicCurrent.current.isCreateTopicing = false;
            topicCurrent.current.code = 88888;
            updateCardInfo(topicCurrent.current);
            onResetTopic();
            if (!isStudyProject) {
              // scrollToBottom();
            }
          }
        }, duration);
        // 记录数据处理
        if (Array.isArray(frames) && frames.length > 0) {
          handlerDataType(frames, params);
        }
      } else if (ctrlFlag === 1) {
        window.aegis.timeEnd('repeactRequest');
        handlerDataType(frames, params);
      }
    } catch (error) {
      window.aegis.error({
        msg: 'repeactRequest error',
        ext1: JSON.stringify(error),
        ext2: JSON.stringify({ userID: window.nickName }),
      });
      onCatchError(error);
    }
  };
  const createNextQuestion = () => {
    // 新建会话 清除超时 清空上一题内容 更新当前题目索引
    const nextQuestionIndex = questionIndex + 1;
    resetQuestion(nextQuestionIndex);
    // 更新url参数，保证用户刷新会话页可以进入当前题目
    const url = window.location?.href;
    const newUrl = updateUrlParameter(url, 'questionIndex', nextQuestionIndex);
    window.history?.replaceState({}, '', newUrl);
  };
  const resetQuestion = (curQuestionIndex: number) => {
    // setCreateTopic(false);
    // setIsCreateTopicing(false);
    topicCurrent.current.isCreateTopicing = false;
    dispatch(updateTopicCreating(false));
    clearTopicTimeOut();
    dispatch(resetTopicList(null));
    dispatch(resetQueryList());
    dispatch(updateQuestionIndex(curQuestionIndex));
    dispatch(updateWordStorageMap({}));
    clearTimeout(timerRef.current);
  };
  const onStudyTopic = () => {
    if (isLastQuestion) {
      clearTopicTimeOut();
      dispatch(
        pushErrorInfo({
          code: 20001,
          remainingTopics,
        }),
      );
      return;
    }
    createNextQuestion();
  };
  // talkingBox需要透传的参数
  const talkingBoxProps = {
    isStudyProject,
    isLastQuestion,
    questionId,
    questionType,
    talkingShow,
    onStudyTopic,
    searchKeyword,
    onTextTopic,
    onNewTopic,
    onCreateImgTopic,
    onCatchError,
    onCreateMusicTopic,
    limitError,
    onCreateSearch,
    onStopMuiscTopic,
    isEnd: topicCurrent.current.isEnd,
  };
  return <TalkingBox ref={talkingBoxRef} {...talkingBoxProps} topicCurrent={topicCurrent.current} />;
});
export default Talking;
