import React, { useEffect, useRef, useState, MouseEvent } from 'react';

import './index.scss';
import { useDispatch, useSelector } from 'react-redux';

import { updateWordStorageMap } from '../../../common/js/store/studySlice';
import { replaceHTMLTag, requestQuery } from '../../../common/js/utils';
import Loading from '../../../components/loading';
import { studyAssets } from '../js/assets';
import { actionMap, loadStatusMap } from '../js/model';
import { getPopPositionInfo, handleExternalTransword, replaceEnWord, requestTransWord } from '../js/util';

import PointPop from './pointWordPop';
import { BasicWordInfo, FrameData, GptWordInfo, PointWordInfoParams, RequestData, TranslateAllMap } from './translate';

interface PagePros {
  talkRef?: any;
  goSearch?: (e?: MouseEvent<HTMLElement>, query?: string) => void;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
const TransCard: React.FC<PagePros> = (props: any) => {
  const { goSearch } = props;
  const dispatch = useDispatch();
  // 原文译文展现判断
  const [isOriginalText, setIsOriginalText] = useState(true);
  // 获取习题列表及当前习题索引
  const { questionList = [], questionIndex = 0, wordStorageMap } = useSelector((state: any) => state.study);
  const [translateAllData, setTranslateAllData] = useState<TranslateAllMap>({
    content: [],
    status: loadStatusMap.unStart,
    cursor: '',
    response: '',
    done: false,
  });
  const { content = [], status: translateAllStatus, cursor = '' } = translateAllData || {};
  // 当前题目数据
  const curQuestion = questionList[questionIndex] || {};
  const { questionId, questionType, questionTextContent: questionContent } = curQuestion;
  // 为实现点词翻译，为每个英文单词包一层span标签
  const { sentenceHTML } = replaceEnWord(questionContent, {});
  const questionContentHtml = replaceHTMLTag(sentenceHTML);
  // 是否展现点词翻译卡片
  const [pointPopShow, setPointPopShow] = useState(false);
  // 该状态主要为了实现对点词翻译弹窗的位置校验
  const [posNeedChange, setPosNeedChange] = useState(false);
  // 点词卡片单词数据信息
  const [pointWordInfo, setPointWordInfo] = useState({});
  // 点词卡片位置信息
  const [popPosition, setPopPosition] = useState({});
  // gpt轮询请求流程中
  const [isCreating, setIsCreating] = useState(false);
  // 激活状态单词
  const activeWord: any = useRef(null);
  // 与主流程共用同一个sessionID
  const { sessionID = '' } = useSelector((state: any) => state.topic);
  const { userInfo } = useSelector((state: any) => state.userInfo);
  // 点词弹窗
  const popRef: any = useRef(null);

  // 轮询流程变量
  const timerRef: any = useRef(null);
  let reqTimestamp = Date.now();

  // gpt点词翻译数据
  const gptWordInfo: GptWordInfo = {
    status: loadStatusMap.unStart,
    meaning: '',
  };
  // 机翻接口数据
  let basicWordInfo: BasicWordInfo = {
    status: loadStatusMap.unStart,
  };
  let pointWordInfoMap: PointWordInfoParams = {
    word: '',
    gptWordInfo,
    basicWordInfo,
    loadStatus: loadStatusMap.unStart,
  };
  // 记录翻译全文状态
  let translateAllMap: TranslateAllMap = {
    content: [],
    status: loadStatusMap.unStart,
    cursor: '',
    response: '',
    done: false,
  };
  const resStrList: string[] = [];
  let isCreateWord = false;

  const curAction: any = useRef('');
  // 点词弹窗处理
  const handleWordClick = (e: any) => {
    if (isCreating) {
      return;
    }
    const { target } = e;
    // 判断是否为英文单词
    if (target?.classList?.contains('nsp-study-word')) {
      activeWord.current = target;
      const word = target.innerText;
      target.classList.add('active-word');
      const cardMinHeight = 148; // 弹窗最小高度
      const cardMaxHeight = 310; // 弹窗最大高度
      let pointPopPosition = {};
      // 判断是否已翻译过该词，若已翻译过直接返回结果
      const wordValue = getWordValue(target);
      if (wordValue) {
        pointWordInfoMap = { ...wordValue };
        pointPopPosition = getPopPositionInfo(target, cardMaxHeight);
        // 由于数据更新前无法获得弹窗高度ss，因此数据更新后需要再做一次位置校验
        setPosNeedChange(true);
      } else {
        // 若单词没有被翻译过，发起机翻请求及gpt请求，状态设为加载中
        reqTimestamp = Date.now();
        pointWordInfoMap = {
          word,
          basicWordInfo: {
            status: loadStatusMap.loading,
          },
          gptWordInfo: {
            status: loadStatusMap.loading,
          },
          loadStatus: loadStatusMap.loading,
        };
        pointPopPosition = getPopPositionInfo(target, cardMinHeight);
        // 请求单词翻译信息
        requestPointWordInfo(target);
      }
      setPointPopShow(true);
      // 更新单词数据信息
      setPointWordInfo({ ...pointWordInfoMap });
      // 更新单词位置信息
      setPopPosition(pointPopPosition);
    }
  };

  // 请求卡片信息：gpt请求及翻译接口请求
  const requestPointWordInfo = async (target: any) => {
    const word = target?.innerText;
    // 当前单词第几次出现，gpt接口需要据此定位单词位置
    const wordOccurTimes = activeWord.current?.dataset?.times;
    const basicParams = { query: word, from: 'en', to: 'zh-CHS' };
    const gptParams = {
      query: word,
      actionParams: {
        actionType: actionMap.translateWord,
        occurTimes: wordOccurTimes,
        questionId,
        questionType,
      },
    };
    curAction.current = actionMap.translateWord;
    // 并行请求翻译接口及gpt接口
    Promise.allSettled([requestTransWord(basicParams), createGptSearch(gptParams)])
      .catch(() => {
        // console.log('err:', err);
      })
      .then((res: any) => {
        const [basicTransWord] = res || [];
        // 基础释义+音标数据
        if (basicTransWord?.status === 'fulfilled') {
          const data = basicTransWord?.value?.data;
          if (data?.code === 0) {
            const basicData = handleExternalTransword(data?.data, word) || {};
            basicWordInfo = { ...basicWordInfo, ...basicData };
          } else {
            basicWordInfo.status = loadStatusMap.fail;
          }
        } else {
          basicWordInfo.status = loadStatusMap.fail;
        }
      });
  };

  // 获取已存word数据
  const getWordValue = (ele: any) => {
    const word = ele?.innerText;
    const wordOccurTimes = activeWord.current?.dataset?.times;
    const wordInfoKey = `${word}-${wordOccurTimes}`;
    return wordStorageMap[wordInfoKey];
  };

  const createGptSearch = (actionParams: any) => {
    try {
      setIsCreating(true);
      const gptParams = {
        timestamp: Date.now(),
        sessionID,
        userID: userInfo.username || '',
        type: 'study',
        studyReq: actionParams,
      };
      return pollRequest(gptParams);
    } catch (error) {
      // console.log('error:', error);
      handleFailRequest();
    }
  };
  // 轮询请求gpt接口
  const pollRequest = async (params: RequestData) => {
    const { timestamp, query, userID } = params;
    const res = await requestQuery(params);
    const { code, ctrlFlag, sessionID, dialogueID, frameID, frames } = res.data;
    // 接口报错 直接返回失败
    if (code !== 0) {
      handleFailRequest();
      return;
    }
    // 0代表正常轮询 2代表数据生成中
    const isCtrl0 = ctrlFlag === 0;
    const isCtrl2 = ctrlFlag === 2;
    // 请求参数
    const reqParams = {
      timestamp,
      query,
      sessionID,
      dialogueID,
      frameID,
      userID,
    };
    try {
      if (isCtrl0 || isCtrl2) {
        const duration = isCtrl0 ? 20 : 1000;
        const min = 1000 * 60 * 2;
        // 没有数据 如果是0正常轮询 数据生成1秒轮询一次
        timerRef.current = setTimeout(() => {
          // 超过1分钟不再进行轮询请求 提示超时
          if (Date.now() - reqTimestamp < min) {
            pollRequest({ ...params, ...reqParams });
          } else {
            // 失败处理
            handleFailRequest();
          }
        }, duration);
        // 记录数据处理
        if (Array.isArray(frames) && frames.length > 0) {
          handleDataType(frames);
        }
      } else if (ctrlFlag === 1) {
        handleDataType(frames);
      }
    } catch (error) {
      // console.log('error:', error);
    }
  };

  // 处理类型数据
  const handleDataType = (frames: any) => {
    frames?.forEach((frame: any) => {
      // 过渡类型
      if (frame?.data && frame?.data?.type === 'gpt') {
        reqTimestamp = Date.now();
        // 处理文本内容输出
        if (curAction.current === actionMap.translateAll) {
          handleTranslateAllOutput(frame.data);
          // 打字动画
          if (isCreateWord === false) {
            addWordsAnimate();
          }
          isCreateWord = true;
        } else {
          const translateTxt = frame?.data?.data;
          gptWordInfo.meaning += translateTxt;
        }
      } else if (frame?.done) {
        // 更新数据接受完成的数据信息
        if (curAction.current === actionMap.translateWord) {
          gptWordInfo.status = loadStatusMap.success;
          updateTranslateWordData();
        } else {
          translateAllMap.status = loadStatusMap.success;
          translateAllMap.done = true;
        }
      }
    });
  };
  // 翻译全文返回json数据，需要特殊处理
  const handleTranslateAllOutput = async (data: FrameData) => {
    const translateDataJson = data?.data;
    try {
      if (translateDataJson && typeof translateDataJson === 'string') {
        const translateData = JSON.parse(translateDataJson);
        resStrList.push(translateData['原文']);
        resStrList.push(translateData['翻译结果']);
      }
    } catch (error) {
      // console.log('error:', error);
    }
  };
  let sentenceIndex = 0; // 全文翻译句子索引
  const sentenceList: any[] = [];
  let wordOccurTimesMap = {};
  const addWordsAnimate = () => {
    // 接口请求完还没打完字 或者打完字以后接口还有数据请求
    if (resStrList.length === 0 && !translateAllMap.done) {
      // 100毫秒轮询一次
      setTimeout(() => {
        addWordsAnimate();
      }, 100);
      return;
    }
    const wordInfo = resStrList.shift();
    let cancelReq: any = null;
    const words = wordInfo?.split('') || [];
    // 代表新的一行开始，翻译内容增加新的一句
    if (words.length > 0) {
      translateAllMap.response = '';
      sentenceList.push('');
      sentenceIndex += 1;
    }
    let now = Date.now();

    const addWord = () => {
      // 30毫秒的速率
      if (Date.now() - now > 30) {
        const word = words.shift();
        const sentenceNum = sentenceList.length;
        const hasSentence = sentenceNum > 0;
        // 没有字内容
        if (!word) {
          // 翻译全文，每句结束为英文单词增加span标签，并统计出现次数
          if (hasSentence && sentenceIndex % 2 !== 0) {
            const { sentenceHTML, occurTimesMap } = replaceEnWord(translateAllMap.response, wordOccurTimesMap);
            wordOccurTimesMap = occurTimesMap;
            sentenceList[sentenceNum - 1] = sentenceHTML;
            translateAllMap.content = [...sentenceList];
            setTranslateAllData({ ...translateAllMap });
          }
          if (!translateAllMap.done || resStrList.length !== 0) {
            addWordsAnimate();
          } else {
            translateAllMap.cursor = 'end';
            translateAllMap.status = loadStatusMap.success;
            updateTranslateAllData();
          }
          window.cancelAnimationFrame(cancelReq);
          return;
        }
        const { response = '' } = translateAllMap;
        translateAllMap = {
          ...translateAllMap,
          response: response + word,
        };
        if (hasSentence && translateAllMap.status !== loadStatusMap.fail) {
          sentenceList[sentenceNum - 1] = response + word;
          translateAllMap.content = [...sentenceList];
          translateAllMap.status = loadStatusMap.generating;
          setTranslateAllData({ ...translateAllMap });
        }
        now = Date.now();
      }
      cancelReq = window.requestAnimationFrame(addWord);
    };
    addWord();
  };
  const handleFailRequest = () => {
    if (curAction.current === actionMap.translateWord) {
      gptWordInfo.status = loadStatusMap.fail;
      updateTranslateWordData();
    } else {
      translateAllMap.status = loadStatusMap.fail;
      updateTranslateAllData();
    }
    clearTimeout(timerRef.current);
  };
  // 接口请求结束，数据状态处理
  const updateTranslateWordData = () => {
    pointWordInfoMap = {
      ...pointWordInfoMap,
      basicWordInfo,
      gptWordInfo,
      loadStatus: loadStatusMap.done,
    };
    // 更新单词弹窗数据
    setPointWordInfo(pointWordInfoMap);
    // 由于数据更新前无法获得弹窗高度，因此数据更新后需要再做一次位置校验
    setPosNeedChange(true);
    // 当翻译接口与gpt接口均成功请求到数据时，对数据进行存储，下次无需再请求
    if (basicWordInfo.status === loadStatusMap.success && gptWordInfo.status === loadStatusMap.success) {
      const wordOccurTimes = activeWord.current?.dataset?.times;
      const wordInfoKey = `${pointWordInfoMap.word}-${wordOccurTimes}`;
      // 已经成功请求到数据的单词，将数据存放入map中，不再重新请求
      const wordMap: { [key: string]: any } = {};
      wordMap[wordInfoKey] = pointWordInfoMap;
      dispatch(updateWordStorageMap({ ...wordStorageMap, ...wordMap }));
    }
    setIsCreating(false);
  };
  const updateTranslateAllData = () => {
    setTranslateAllData({ ...translateAllMap });
    setIsCreating(false);
  };
  const clearWordPointPop = () => {
    cancelWordActive();
    setPointPopShow(false);
    clearTimeout(timerRef.current);
    setIsCreating(false);
  };
  // 取消选中态
  const cancelWordActive = () => {
    const preActiveWord = document.querySelector('.active-word');
    if (preActiveWord) {
      preActiveWord.classList?.remove('active-word');
    }
  };
  const handleTranslateAllClick = () => {
    setIsOriginalText(!isOriginalText);
    window.scrollTo(0, 0);
    const translateAllSuccess = translateAllStatus === loadStatusMap.success;
    // 原文且没有翻译成功时，点击翻译后发起翻译请求
    if (isOriginalText && !translateAllSuccess) {
      const actionParams = {
        query: '',
        actionParams: {
          actionType: actionMap.translateAll,
          questionId,
          questionType,
        },
      };
      curAction.current = actionMap.translateAll;
      // 更新为加载状态
      translateAllMap.status = loadStatusMap.loading;
      setTranslateAllData({ ...translateAllMap });
      createGptSearch(actionParams);
    }
  };

  // 更新点词翻译弹窗位置信息
  useEffect(() => {
    if (posNeedChange) {
      const popNode = popRef.current;
      const popHeight = popNode?.offsetHeight;
      const pointPopPosition = getPopPositionInfo(activeWord.current, popHeight);
      setPopPosition(pointPopPosition);
      setPosNeedChange(false);
    }
  }, [posNeedChange]);

  // 当题目内容发生变化时，清空上一题的状态
  useEffect(() => {
    clearWordPointPop();
    setIsOriginalText(true);
    translateAllMap = {
      content: [],
      status: loadStatusMap.unStart,
      cursor: '',
      done: false,
    };
    setTranslateAllData({ ...translateAllMap });
  }, [questionContent]);

  const translateAllSuccess = translateAllStatus === loadStatusMap.success;
  const translateAllFail = translateAllStatus === loadStatusMap.fail;
  return questionContent ? (
    <div
      className="nsp-study-qustion-card"
      onClick={(e) => {
        handleWordClick(e);
      }}
    >
      {!isOriginalText && translateAllStatus === loadStatusMap.loading ? (
        <Loading loadingText="正在翻译题目"></Loading>
      ) : null}
      {/* 译文 */}
      <div
        className="nsp-study-translate-content"
        style={{ display: `${isOriginalText || translateAllFail ? 'none' : 'block'}` }}
      >
        {content?.length > 0 &&
          content?.map((sentence: any, index: number) => (
            <p className="nsp-study-translate-sentence" key={index}>
              <span dangerouslySetInnerHTML={{ __html: sentence }}></span>
              {index === content.length - 1 ? (
                <span
                  className="nsp-cursor"
                  style={{ opacity: cursor ? 0 : 1, animation: cursor ? 'unset' : '' }}
                ></span>
              ) : null}
            </p>
          ))}
      </div>
      {/* 原文 */}
      {isOriginalText ? (
        <div className="nsp-study-question-content" dangerouslySetInnerHTML={{ __html: questionContentHtml }}></div>
      ) : null}
      {/* 原文或翻译成功才显示按钮菜单 */}
      {isOriginalText || translateAllSuccess ? (
        <div className="nsp-study-translate-desc">
          <div className="nsp-study-question-btn nsp-study-btn-left" onClick={handleTranslateAllClick}>
            <img className="nsp-study-translate-icon sa-spacing-right-4" src={studyAssets.translate} alt=""></img>
            {isOriginalText ? <span>翻译题目</span> : <span>隐藏译文</span>}
          </div>
          <div className="nsp-study-paratext">点击任何单词可查看翻译哦</div>
        </div>
      ) : null}
      {/* 翻译失败 */}
      {!isOriginalText && translateAllFail ? (
        <div className="nsp-study-translate-fail">
          <div className="nsp-study-fail-title">正在翻译题目：</div>
          <span className="nsp-study-fail-txt">翻译失败</span>
          <div
            className="nsp-study-fail-btn"
            onClick={() => {
              setIsOriginalText(true);
            }}
          >
            <img className="sa-spacing-right-4 gpt-refresh-icon" src={studyAssets.refresh} alt=""></img>查看原文
          </div>
        </div>
      ) : null}
      {pointPopShow ? (
        <PointPop
          popRef={popRef}
          pointWordInfo={pointWordInfo}
          popPosition={popPosition}
          goSearch={goSearch}
          clearWordPointPop={clearWordPointPop}
        />
      ) : null}
    </div>
  ) : null;
};

export default TransCard;
