import React from 'react';

import { useSelector } from 'react-redux';

import { globalVar } from '../common/js/global';
import { TopicState } from '../view/types/store';
interface PagePros {
  sceneType?: string;
}

const Header: React.FC<PagePros> = (props: PagePros) => {
  const isStudyProject = props.sceneType === 'study';
  const { showHeader } = useSelector((state: TopicState) => state.topic);
  const { pathname } = window.location;
  // console.log('globalVar.isPC', globalVar.isPC);
  const style = {
    display: pathname === '/' && showHeader === false && globalVar.isPC !== true ? 'none' : 'block',
  };
  return (
    <div className="nsp-header nsp-row sa-text-bold-body1 sa-spacing-inner-top-16" style={style}>
      <div className="flex-full">{isStudyProject ? globalVar.studyTitle : globalVar.title}</div>
      {/* <i className='sa-icon sa-icon-wiki_share'></i> */}
    </div>
  );
};

export default Header;
