import React from 'react';

import './style.scss';
import { getDefaultCity } from './util';

interface IProps {
  diplayInfo: {
    name_sight: string;
    location_xianzhen: string;
    province: string;
    name_city: string;
  };
}
const Header: React.FC<IProps> = (props: IProps) => {
  const {
    name_sight: jingdian = '',
    location_xianzhen: xianzhen = '',
    province = '',
    name_city: city = '',
  } = props.diplayInfo;

  const { showText } = getDefaultCity({
    jingdian,
    xianzhen,
    province,
    city,
  });
  return (
    <div className="header">
      <span className="city_name">{showText}</span>
      <i className="split"></i>
      <span className="from_info">信息由墨迹天气提供</span>
    </div>
  );
};

export default Header;
