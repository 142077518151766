import { useEffect, useRef } from 'react';

import classNames from 'classnames';

import { TopicProps } from '../../view/types/session';

export const ResultCard = (props: TopicProps) => {
  const { onSourceClick, cardHTML = '', references = [], type = '', isStudyProject, done, isEnd } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const hideCursor = isEnd;

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }
    if (done || isEnd) {
      const allTags = contentRef.current.querySelectorAll('.search-result-tag');
      if (allTags && allTags.length > 0) {
        allTags.forEach((ele: any) => {
          ele.onclick = (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            const url = ele.getAttribute('data-href');
            onSourceClick?.({
              url: decodeURIComponent(url),
            });
          };
        });
      }
      const allATags = contentRef.current.querySelectorAll('.nsp-markdown a');
      if (allATags && allATags.length > 0) {
        allATags.forEach((ele: any) => {
          ele.target = '_blank';
          ele.onclick = (e: Event) => {
            e.stopPropagation();
          };
        });
      }
      const allTableTags = contentRef.current.querySelectorAll('.nsp-markdown table');
      if (allTableTags && allTableTags.length > 0) {
        allTableTags.forEach((ele: any) => {
          const columns = ele.querySelectorAll('tr th');
          if (columns && columns.length > 2) {
            ele.style.width = 'auto';
            ele.className = 'nsp-markdown-table';
          }
        });
      }
    }
  }, [cardHTML]);
  const showCardHTML = cardHTML.includes('<') ? cardHTML : `<p class='none-block-gap'>${cardHTML}</p>`;
  // console.log('showCardHTML', showCardHTML, cardHTML, references);
  return (
    <>
      {cardHTML ? (
        <div className={classNames('content', isStudyProject ? 'study-content' : '')} ref={contentRef}>
          <div
            className={classNames(
              hideCursor ? '' : 'nsp-cursor',
              'content-show',
              type === 'text' ? 'nsp-markdown' : '',
              isEnd ? 'nsp-markdown-done' : '',
            )}
            dangerouslySetInnerHTML={{ __html: `${showCardHTML}` }}
          ></div>
        </div>
      ) : null}

      {references && references.length > 0 && isEnd ? (
        <div className="nsp-ie-row wrap">
          {references.map((item, index) => {
            const { doc_id: docID, docId, name } = item;
            const splitName = name && name.length > 16 ? `${name.slice(0, 16)}...` : name;

            return (
              <div
                key={index}
                className="nsp-ie-row nsp-source"
                onClick={(e) => {
                  e.stopPropagation();
                  onSourceClick?.(item);
                }}
              >
                <span className="sa-spacing-right-8">
                  <span className="nsp-source-sort">{docID || docId}</span>
                </span>
                {/* {icon ? <span className='nsp-source-img sa-spacing-right-4'>
                                    <img src={icon} alt="source" />
                                </span> : null} */}
                <span className="nsp-source-name sa-spacing-right-8">{splitName}</span>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};
