import React, { useState, useEffect, useRef, useMemo } from 'react';

import classNames from 'classnames';

import { assets } from '../common/js/assets';
import { sendNSPLog } from '../common/js/log';
import { FrameStat, TopicCurrent } from '../view/types/talking';

interface PageProps {
  stat?: FrameStat;
  isDone?: boolean;
  statQueries?: string[];
  stopResponse?: boolean;
  cardData?: TopicCurrent;
}
export interface LoadingProps {
  [key: number]: LoadingItems;
}
interface LoadingItems {
  text: string;
  icon: string;
  arrowIcon?: string;
  hideOmitAnimate?: boolean;
}

const LoadingText: React.FC<PageProps> = (props: PageProps) => {
  const { cardData } = props;
  const { timestamp, stat, isEnd, statQueries = [], stopResponse } = cardData || {};
  const searchDefaultText = '正在搜索';
  const statTimeOut: any = useRef<any>();
  const loadingMap: LoadingProps = {
    1: {
      text: '正在分析',
      icon: assets.nspNewLoadingClock,
    },
    2: {
      text: '正在生成回复',
      icon: assets.nspNewLoadingStatus,
    },
    3: {
      text: '正在生成图片',
      icon: assets.nspNewLoadingStatus,
    },
    4: {
      text: '正在解析音色',
      icon: assets.nspNewLoadingStatus,
    },
    5: {
      text: '正在生成答案与解析',
      icon: assets.nspNewLoadingStatus,
    },
    6: {
      text: '正在翻译题目',
      icon: assets.nspNewLoadingStatus,
    },
    7: {
      text: '正在结合搜索生成回复',
      icon: assets.nspNewLoadingStatus,
    },
    8: {
      text: '已生成回复',
      icon: assets.loadingRightBlack,
      hideOmitAnimate: true,
    },
    9: {
      text: '已结合搜索生成回复',
      icon: assets.loadingRightBlack,
      hideOmitAnimate: true,
      arrowIcon: assets.loadingArrowUp,
    },
    10: {
      text: `${searchDefaultText}`,
      icon: assets.nspNewLoadingSearch,
    },
    11: {
      text: '已停止回复',
      icon: assets.nspNewYetStop,
      hideOmitAnimate: true,
    },
    20: {
      text: '正在解析音色',
      icon: assets.nspNewLoadingStatus,
    },
  };
  const [loadingInfo, setLoadingInfo]: [LoadingItems, any] = useState(loadingMap['1']);
  const initLoadingInfo = useRef<LoadingItems>();
  const queriesList = useRef<string[]>([]);
  const [historyList, setHistoryList]: [string[], any] = useState([]);
  const [arrowIcon, setArrowIcon] = useState(assets.loadingArrowUp);
  const historyRef: any = useRef(null);

  useEffect(() => {
    queriesList.current = [];
    initLoadingInfo.current = loadingMap['1'];
    setLoadingInfo(loadingMap['1']);
  }, []);

  useEffect(() => {
    if (!isEnd) {
      queriesList.current = [];
      setHistoryList([]);
      initLoadingInfo.current = undefined;
      setLoadingInfo(loadingMap[1]);
    } else {
      setHistoryList(statQueries);
    }
  }, [timestamp]);

  useEffect(() => {
    // 如果有搜索增强
    if (Array.isArray(statQueries) && statQueries.length > 0 && !isEnd) {
      queriesList.current = [];
      setHistoryList([]);
      let i = 0;
      const addQuery = () => {
        const query = statQueries[i];
        initLoadingInfo.current = loadingMap['10'];
        initLoadingInfo.current.text = `${searchDefaultText} ${query}`;
        // setLoadingInfo(initLoadingInfo.current);
        statTimeOut.current = setTimeout(() => {
          if (query && !queriesList.current.includes(query)) {
            queriesList.current.push(query);
            setHistoryList([...queriesList.current]);
          }
          if (queriesList.current.length === statQueries.length) {
            initLoadingInfo.current = loadingMap['7'];
            setLoadingInfo(initLoadingInfo.current);
          }
          if (i < statQueries.length) {
            addQuery();
          }
        }, 2500);
        i += 1;
      };
      addQuery();
    }
  }, [statQueries]);

  useEffect(() => {
    const { code } = stat || {};
    const isStatQuerires = Array.isArray(statQueries) && statQueries.length > 0;
    if (code) {
      if (code !== 10 && !isStatQuerires) {
        initLoadingInfo.current = loadingMap[code];
      }
    } else {
      initLoadingInfo.current = loadingMap['1'];
    }
    if (isStatQuerires && queriesList.current.length === statQueries.length) {
      initLoadingInfo.current = loadingMap['7'];
    }
    if (isEnd) {
      initLoadingInfo.current = isStatQuerires ? loadingMap[9] : loadingMap[8];
      setTimeout(() => {
        clearTimeout(statTimeOut.current);
        statTimeOut.current = null;
        initLoadingInfo.current = undefined;
      }, 0);
    }
    if (stopResponse) {
      clearTimeout(statTimeOut.current);
      statTimeOut.current = null;
      initLoadingInfo.current = loadingMap['11'];
    }
    setLoadingInfo(initLoadingInfo.current);
  }, [cardData, queriesList.current]);

  const [animateClass, setAnimateClass] = useState('');

  const toggleHistoryList = () => {
    if (arrowIcon === assets.loadingArrowUp) {
      setArrowIcon(assets.loadingArrowDown);
      setAnimateClass('nsp_none_animate');
      if (historyRef.current) {
        historyRef.current.style.height = 0;
        historyRef.current.style.opacity = 0;
      }
      sendNSPLog(
        {
          action: 'click',
          modulename: 'expand',
        },
        cardData,
      );
    } else {
      setArrowIcon(assets.loadingArrowUp);
      if (historyRef.current) {
        historyRef.current.style.height = 'auto';
        historyRef.current.style.opacity = 1;
      }
      setAnimateClass('animate__fadeInUp');
      sendNSPLog(
        {
          action: 'click',
          modulename: 'collapse',
        },
        cardData,
      );
    }
  };

  const LoadingTextStatus = (loadingInfo: LoadingItems) =>
    loadingInfo ? (
      <div className="nsp-loading-text sa-spacing-top-4">
        <span className="sa-text-regular-body2 sentence sa-text-clamp1">
          <img src={loadingInfo.icon} alt="" />
          {loadingInfo.text}
          {loadingInfo.arrowIcon ? (
            <img
              className="arrow"
              src={arrowIcon}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                toggleHistoryList();
              }}
            />
          ) : null}
        </span>
        {!loadingInfo.hideOmitAnimate ? <span className="nsp-dot">...</span> : null}
      </div>
    ) : null;

  return (
    <>
      {Array.isArray(historyList) && historyList.length > 0 ? (
        <div
          className={classNames('nsp-loading-history-box animate__animated animate__faster', animateClass)}
          ref={historyRef}
        >
          {historyList.map((item: string, index: number) => (
            <div className="nsp-loading-text sa-spacing-top-4" key={index}>
              <span className="sa-text-regular-body2 sentence sa-text-clamp1">
                <img src={assets.loadingRightBlack} alt="" />
                {`完成搜索 ${item}`}
              </span>
            </div>
          ))}
        </div>
      ) : null}
      {useMemo(
        () => (
          <LoadingTextStatus {...loadingInfo} />
        ),
        [loadingInfo.text],
      )}
      {/* <div className="nsp-loading-text sa-spacing-top-4">
        <span className="sa-text-regular-body2 sentence">
          <img src={assets.loadingSearch} alt="" />
          {'正在搜索 NSP学习大纲'}
        </span>
        <span className="nsp-dot">...</span>
      </div>
      <div className="nsp-loading-text sa-spacing-top-4">
        <span className="sa-text-regular-body2 sentence gray-done">
          <img src={assets.loadingRightGray} alt="" />
          {'完成搜索 NSP学习大纲'}
        </span>
      </div>
      <div className="nsp-loading-text sa-spacing-top-4">
        <span className="sa-text-regular-body2 sentence">
          <img src={assets.loadingStatus} alt="" />
          {'正在结合搜索生成回复'}
          <span className="nsp-dot">...</span>
        </span>
      </div>
      <div className="nsp-loading-text sa-spacing-top-4">
        <span className="sa-text-regular-body2 sentence">
          <img src={assets.loadingRight} alt="" />
          {'已结合搜索生成回复'}
          <img className="arrow" src={assets.loadingArrowDown} alt="" />
        </span>
      </div> */}
    </>
  );
};

export default LoadingText;
