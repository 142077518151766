import React from 'react';

import './index.scss';
import { sendNSPLog } from '../../../common/js/log';
import { CardData } from '../../types/talking';

const getSourceIcon = (resourcename: string, siteHost: string) => {
  const logoName = [
    '乐视',
    '爱奇艺',
    '风行',
    '优酷',
    'PPTV',
    '华数',
    '新浪',
    '56网',
    '土豆',
    '凤凰网',
    '电影网',
    'CNTV',
    '酷米网',
    '爆米花',
    '暴风',
    '酷6网',
    '激动网',
    '芒果TV',
    '17173',
    '腾讯',
    '看看',
    '搜狐',
    'PPS',
    '新篮网',
    '音悦台',
    '糖豆网',
    '淘米网',
    '先看',
    '响巢看看',
    '迅雷',
    '中国蓝',
    '中国蓝TV',
    'PP视频',
    '哔哩哔哩',
    '欢喜首映',
    '西瓜视频',
    '咪咕视频',
    '豆瓣',
    '央视频',
    'CCTV',
    '天天看看',
    '风行视频',
  ];
  const logoImg = [
    'leshi.86fe36a0.png',
    'iqiyi.ad940a2f.png',
    'fengxing.a960913f.png',
    'youku.29fd714a.png',
    'pptv.8fbb2e92.png',
    'huashu.0def4846.png',
    'sina.099e4732.png',
    '56.272e71be.png',
    'tudou.ae4de3bd.png',
    'fenghuang.c6aa3262.png',
    'movie.c3e2e230.png',
    'cntv.75a0d0c9.png',
    'kumi.278794ee.png',
    'baomihua.d493a98f.png',
    'baofeng.69e20c8f.png',
    'ku6.51f57ea5.png',
    'jidong.fdd42535.png',
    'hunantv.dc066b5d.png',
    '17173.c215576f.png',
    'tengxun.a75cbb76.png',
    'xckk.5f0d6a67.png',
    'sohu.24ec5982.png',
    'pps.6ec4538e.png',
    'cztv.c981617e.png',
    'yinyuetai.02e44a2c.png',
    'tangdou.22011925.png',
    'taomi.ec4fea01.png',
    'xiankan.3630dd7e.png',
    'xckk.5f0d6a67.png',
    'xunlei.3001e431.png',
    'cztv2.6a3b99ec.png',
    'cztv2.6a3b99ec.png',
    'ppsp.d5a8d2c1.png',
    'bilibili.ddda95be.png',
    'huanxi.03dcbeca.png',
    'xigua.7ca2af85.png',
    'migu.3b2e636a.png',
    'douban.png',
    'yangshipin.0aec0aa9.png',
    'cctv.png',
    'xckk.5f0d6a67.png',
    'fengxing.20220428.png',
  ];
  const siteHostList = [
    'kankanews.com',
    'yangshipin.cn',
    'movie.douban.com',
    'miaopai.com',
    'huya.com',
    'sa.sogou.com',
    '17173.com',
    'yidianzixun.com',
    'video.mtime.com',
    'weibo.com',
    'miguvideo.com',
    'kankan.com',
    'ixigua.com',
    'ifeng.com',
    'qq.com',
    'pptv.com',
    'mgtv.com',
    'xinlan.com',
    'wasu.cn',
    'tudou.com',
    'sohu.com',
    'pearvideo.com',
    'letv.com',
    'kumi.cn',
    'iqiyi.com',
    'funshion.com',
    'cntv.cn',
    'bilibili.com',
    'baomihua.com',
    'acfun.tv',
    'm1905.com',
    '56.com',
    'cctv.com',
    'huanxi.com',
  ];
  const siteHostIcon = [
    'kankannew.jpg',
    'ysp.png',
    'douban.png',
    'miaopai.png',
    'huya.jpg',
    'sasogou.png',
    '17173.png',
    'yidianzixun.jpg',
    'mtime.png',
    'weibo.png',
    'miguvideo.png',
    'kankan.png',
    'ixigua.png',
    'ifeng.png',
    'qq.png',
    'pptv.png',
    'mgtv.png',
    'xinlan.png',
    'wasu.png',
    'tudou.png',
    'sohu.png',
    'pearvideo.png',
    'le.png',
    'kumi.png',
    'iqiyi.png',
    'funshion.png',
    'cntv.png',
    'bilibili.png',
    'baomihua.png',
    'acfun.png',
    '1905.png',
    '56.png',
    'cctv.png',
    'huanxi.jpg',
  ];
  let logoUrl = 'https://dlweb.sogoucdn.com/vr/images/tv/logo/';
  let logoIndex = -1;
  if (resourcename) {
    logoIndex = logoName.indexOf(resourcename);
  }
  if (logoIndex !== -1) {
    logoUrl += logoImg[logoIndex];
    return logoUrl;
  }
  if (siteHost) {
    logoIndex = siteHostList.indexOf(siteHost);
  }
  if (logoIndex !== -1) {
    logoUrl += siteHostIcon[logoIndex];
    return logoUrl;
  }
  return null;
};

const handleNewPageOpen = (linkUrl: string, classId?: string, cardData?: CardData) => {
  window.open(linkUrl, '_blank');
  sendNSPLog({ action: 'click', modulename: 'VR', vrid: classId, target_url: encodeURIComponent(linkUrl) }, cardData);
};

const Card: React.FC = (props: any) => {
  const {
    base_info: baseInfo,
    display_info: displayInfo = {},
    child_info: childInfo = {},
    extra_info: extraInfo,
    cardData,
  } = props;
  const { classid } = baseInfo;
  const { userAgent } = extraInfo;
  const isQB = /qqbrowser/gi.test(userAgent);
  const child701987 =
    childInfo.find((item: { base_info: { classid: string | string[] } }) =>
      item?.base_info?.classid?.includes('701987'),
    ) || {};
  const pcUrl = child701987?.display_info?.info_subdisplay?.url_pc;
  const { info_subdisplay: infoSubdisplay } = displayInfo;
  const {
    language,
    year,
    location,
    type,
    score,
    name_resource: nameResource,
    key_status: keyStatus,
    number_total: numberTotal,
    url_poster: urlPoster,
    url_poster_shortvideo: urlPosterShortVideo,
    url_poster_big: urlPosterBig,
    json_toplist: jsonToplist,
    list_resource: listResource,
  } = infoSubdisplay;
  const name = infoSubdisplay?.name?.replace('<em>', '').replace('</em>', '');

  const getTypeLabel = () => {
    const newHeadInfo = [];
    if (year) {
      newHeadInfo.push(year);
    }
    if (location) {
      newHeadInfo.push(location);
    }
    if (type) {
      const types = type.split(';').slice(0, 2);
      newHeadInfo.push(types.join(' '));
    }
    if (language) {
      newHeadInfo.push(language);
    }
    if (newHeadInfo.length) {
      return newHeadInfo.slice(0, 3).join(' · ');
    }
  };

  const getLinkUrl = () => {
    const issue = listResource?.[0]?.tag_issue?.[0];
    if (issue) {
      if (isQB) {
        return issue?.url_na || issue?.url_play_episode;
      }
      return issue?.url_play_episode;
    }
  };

  const getDetailUrl = () => {
    const isMobile = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    );
    const mobileUrl = `https://m.sogou.com/openapi/h5/video_tv/home.html?addressbar=normalhide&from=vr_videoTv&classId=${classid}&tvName=${encodeURIComponent(
      name,
    )}&oldquery=${encodeURIComponent(name)}`;
    if (isMobile) {
      return mobileUrl;
    }
    return pcUrl || mobileUrl;
  };

  const typeLabel = getTypeLabel();
  const topJson = JSON.parse(jsonToplist);
  const sourceIcon = getSourceIcon(nameResource.split('|')[0], '') || '';
  const linkUrl = getLinkUrl();
  const detailLinkUrl = getDetailUrl();
  const posterBigUrl = urlPosterShortVideo || urlPosterBig;

  return (
    <div className="nps-ys">
      {posterBigUrl ? (
        <div className="nps-ys__banner">
          <div className="nps-ys__bannerLink" onClick={() => handleNewPageOpen(linkUrl, classid, cardData)}>
            <img className="nps-ys__bannerImg" src={posterBigUrl} alt=""></img>
            <svg className="nps-ys__playIcon" width="44" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.313 11.411a2.91 2.91 0 0 1 4.386-2.509l21.403 12.59a2.91 2.91 0 0 1 0 5.017L12.699 39.1a2.91 2.91 0 0 1-4.386-2.51V11.411z"
                  fill="#fff"
                />
              </g>
              <defs>
                <filter
                  id="a"
                  x=".313"
                  y=".5"
                  width="43.225"
                  height="47"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="4" />
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1314_13649" />
                  <feBlend in="SourceGraphic" in2="effect1_dropShadow_1314_13649" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      ) : null}

      <div className="nps-ys__summary">
        <div className="nps-ys__summary__left" onClick={() => handleNewPageOpen(linkUrl, classid, cardData)}>
          <div className="nps-ys__summary__imgWrap">
            {score > 0 ? <span className="nps-ys__summary__score">{score}</span> : null}
            <img className="nps-ys__summary__img" src={urlPoster} alt=""></img>
            {!posterBigUrl ? (
              <span className="nps-ys__btn">
                <svg
                  className="nps-ys__playIconSmall"
                  width="11"
                  height="12"
                  viewBox="0 0 11 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2 4.96077C11 5.42265 11 6.57735 10.2 7.03923L1.8 11.889C1 12.3509 3.51615e-07 11.7735 3.91994e-07 10.8497L8.15972e-07 1.15026C8.56351e-07 0.226497 1 -0.350853 1.8 0.111027L10.2 4.96077Z"
                    fill="white"
                  />
                </svg>
                播放
              </span>
            ) : null}
          </div>
        </div>
        <div className="nps-ys__summary__right" onClick={() => handleNewPageOpen(detailLinkUrl, classid, cardData)}>
          <h3 className="nps-ys__summary__title" dangerouslySetInnerHTML={{ __html: name }}></h3>
          <div className="nps-ys__summary__sta">
            {keyStatus} 共{numberTotal}集
          </div>
          <div className="nps-ys__summary__type">{typeLabel}</div>
          <div className="nps-ys__summary__source">
            <span className="nps-ys__summary__tag">
              {sourceIcon ? <img className="nps-ys__summary__icon" src={sourceIcon} alt="source"></img> : null}
              {nameResource}
            </span>
            {topJson?.rank && topJson?.bangdanname ? (
              <span className="nps-ys__summary__tag">
                {topJson?.bangdanname} {topJson?.rank}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="nps-ys__more" onClick={() => handleNewPageOpen(detailLinkUrl, classid, cardData)}>
        查看详情
      </div>
    </div>
  );
};

export default Card;
