import React, { useState, ReactElement, useContext, MouseEvent, useRef } from 'react';

// import { useSelector } from 'react-redux';
// import { globalVar } from '../common/js/global';
import classNames from 'classnames';

import { C } from '../App';
import { assets } from '../common/js/assets';
import { globalVar } from '../common/js/global';
import { sendNSPLog } from '../common/js/log';
import { isShareHistoryPage } from '../common/js/share';
import { ContextProps } from '../view/types/App';
import { TopicCurrent } from '../view/types/talking';
interface PageProps {
  onreload?: () => void;
  last: boolean;
  pic: {
    url: string;
    desc?: string;
    score?: number;
    timestamp?: number;
  };
  type?: string;
  cardData?: TopicCurrent;
}

const LoadingMask = (): ReactElement => {
  const iconProps = {
    className: 'sa-spacing-right-4 sa-icon sa-icon-replace loading',
  };

  return (
    <div className="nsp-loading-default">
      <div className="loading-wrap sa-color-text-caption">
        <i {...iconProps}></i>
        <span>正在加载</span>
      </div>
    </div>
  );
};

function isExpiredCheck(isShareHistory: boolean, timestamp: number) {
  if (isShareHistory && timestamp) {
    return Date.now() - timestamp > 3 * 24 * 60 * 60 * 1000;
  }
  return false;
}

const Image: React.FC<PageProps> = (props: PageProps) => {
  const { onreload, last, cardData } = props;
  const { type, pic, timestamp = 0, isEnd } = cardData || {};
  const { desc, score } = pic || {};
  // const { prevImgUrls = [] } = useSelector((state: any) => state.query);
  const [imgClass, setImgClass] = useState('');
  const [imgWidth, setImgWidth] = useState('');
  const [imgOnload, setImgOnload] = useState(!!isEnd);
  // console.log('prevImgUrls', prevImgUrls);
  const isShareHistory = isShareHistoryPage();
  const isExpired = isExpiredCheck(isShareHistory, timestamp);
  let { url } = pic || {};
  url = isExpired ? assets.imageExpiredError : url;
  const { talkRef }: ContextProps = useContext(C);
  const imageRef = useRef<HTMLImageElement>(null);

  return (
    <>
      <div className="nsp-session-image">
        <div
          className={`nsp-session-image-viewer-animate ${imgClass}`}
          onClick={(e) => {
            e.stopPropagation();
            // 如果已经放大 点击关闭
            if (!imgClass.includes('unset')) {
              setImgClass('nsp-user-image-viewer-animate-unset');
              document.body.style.overflow = '';
              talkRef.current.setShowTopicBox(true);
            }
          }}
          onTouchMove={(e) => e.preventDefault()}
        >
          {imgOnload === false ? <LoadingMask /> : null}
          <div
            className={classNames('nsp-session-image-none-line-height', !imgOnload ? 'nsp-session-image-loading' : '')}
          >
            {desc && score ? (
              <div className="sa-text-regular-body1 sa-spacing-bottom-8">
                <span>{desc}： </span>
                <span>相似度{(score * 100).toFixed(1)}%</span>
              </div>
            ) : (
              ''
            )}
            <img
              src={url}
              alt=""
              ref={imageRef}
              width={imgWidth}
              onLoad={() => {
                setImgWidth(`${imageRef?.current?.width}`);
                setTimeout(() => {
                  setImgOnload(true);
                  // 最后一轮漏出提示
                  // if (last) {
                  //   scrollToBottom();
                  // }
                }, 0);
              }}
              onClick={(e: MouseEvent<HTMLImageElement>) => {
                if (isExpired) {
                  return;
                }
                // setImageUrl(url);
                if (globalVar.isQB) {
                  // 使用图片查看器
                  setTimeout(() => {
                    // @ts-ignore
                    window?.browser?.app?.openPhotoBrowser([url], 0, 0);
                  }, 0);
                  sendNSPLog(
                    {
                      action: 'click',
                      modulename: 'reply_pic',
                    },
                    cardData,
                  );
                  return;
                }
                e.stopPropagation();
                // 如果已经放大 点击关闭
                if (!imgClass.includes('unset') && imgClass !== '') {
                  setImgClass('nsp-user-image-viewer-animate-unset');
                  document.body.style.overflow = '';
                  talkRef.current.setShowTopicBox(true);
                } else {
                  setImgWidth(`${imageRef?.current?.width}`);
                  setImgClass('nsp-user-image-viewer');
                  document.body.style.overflow = 'hidden';
                  talkRef.current.setShowTopicBox(false);
                  sendNSPLog(
                    {
                      action: 'click',
                      modulename: 'reply_pic',
                    },
                    cardData,
                  );
                }
              }}
            />
          </div>
        </div>
        {isShareHistory && (
          <div className="expired">
            <img src={assets.info}></img>图片仅保留3天，3天后过期无法展示
          </div>
        )}
        {!last && imgOnload && type === 'text' && !isShareHistory ? (
          <div>
            <button
              className="reload-btn sa-spacing-top-12"
              onClick={(e) => {
                e.stopPropagation();
                onreload?.();
              }}
            >
              <i className="sa-icon sa-icon-replace sa-text-regular-caption sa-spacing-right-4"></i>
              <span>重新生成</span>
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Image;
