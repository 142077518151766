import { useEffect } from 'react';

import { sendNSPLog } from '../../common/js/log';

const BackNA: React.FC = () => {
  useEffect(() => {
    sendNSPLog({ action: 'expose', modulename: 'back' });
  }, []);
  return (
    <>
      <div className="nsp-fixed-top-box"></div>
      <div className="nsp-fixed-top-gradient-box">
        <div
          className="nsp-back-na"
          onClick={() => {
            // console.log('window?.browser?.search', window?.browser?.search);
            // location.href = `qb://searchnsp?originalUrl=${encodeURIComponent(location.href)}`;
            window?.browser?.search?.exitNSP(
              () => {
                // console.log('退出成功', e);
              },
              () => {
                // console.log('退出失败', e);
              },
            );
            sendNSPLog({ action: 'click', modulename: 'back' });
          }}
        >
          <i className="sa-icon sa-icon-sub-back"></i>
        </div>
      </div>
    </>
  );
};
export default BackNA;
