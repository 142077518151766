import { useState, useEffect } from 'react';

import { load, save } from 'react-cookies';

interface PagePros {
  sceneType?: string;
  userInfo?: object;
}
const BetaWrap: React.FC<PagePros> = (props: PagePros) => {
  const isStudyProject = props.sceneType === 'study';
  // 从cookie中取计数，内测窗弹3次，清cookie后重新计数
  // 从cookie中取数据，若无cookie数据  setcookie =0，点击确认 +1，当cookie数据为3，不显示 ,更改为弹1次，过期时间3个月
  const [isShowBeta, setIsShowBeta] = useState(false);
  const betaCount = load('nsp_beta');
  useEffect(() => {
    // 浮层防止滚动穿透，为body增加fixed定位
    const bodyNodeStyle = document.body.style;
    if (betaCount !== undefined) {
      if (Number(betaCount) < 1) {
        setIsShowBeta(true);
        bodyNodeStyle.overflow = 'hidden';
        // bodyNodeStyle.position = 'fixed';
      } else {
        setIsShowBeta(false);
        bodyNodeStyle.overflow = '';
      }
    } else {
      save('nsp_beta', 0, { path: '/', expires: new Date(Date.now() + 92 * 24 * 60 * 60 * 1000) });
      setIsShowBeta(true);
      bodyNodeStyle.overflow = 'hidden';
    }
  }, []);

  const setBetaCountCookie = () => {
    save('nsp_beta', Number(load('nsp_beta')) + 1, {
      path: '/',
      expires: new Date(Date.now() + 92 * 24 * 60 * 60 * 1000),
    });
    setIsShowBeta(false);
    document.body.style.overflow = '';
  };

  return isShowBeta ? (
    <div className="mask">
      <div className="beta-wrap">
        <h3 className="beta-wrap-title">内测提醒</h3>
        <p className="beta-wrap-content sa-text-clamp4">
          {isStudyProject ? '解题助教' : ''}
          产品只对公司内部员工开放，请勿对司外泄露相关信息，使用中请避免提及公司数据、商业秘密、个人信息，谢谢您的理解和配合！
        </p>
        <div
          className="beta-wrap-button"
          onClick={() => {
            setBetaCountCookie();
          }}
        >
          <span className="beta-wrap-button-button-text sa-text-clamp1">知道了，开始体验</span>
          <i className="sa-icon sa-icon-arrow-up beta-wrap-button-arrow-left-icon"></i>
        </div>
      </div>
    </div>
  ) : null;
  //   return <div className="mask">
  //   <div className="beta-wrap">
  //     <h3 className="beta-wrap-title">内测提醒</h3>
  //     <p className="beta-wrap-content sa-text-clamp4">
  //       {isStudyProject ? '解题助教' : 'NSP'}
  //       目前只对公司内部员工开放测试和体验。请您不要将产品或者相关信息泄露给公司外部的人员，谢谢您的理解和配合！
  //     </p>
  //     <div
  //       className="beta-wrap-button"
  //       onClick={() => {
  //         setBetaCountCookie();
  //       }}
  //     >
  //       <span className="beta-wrap-button-button-text sa-text-clamp1">知道了，开始体验</span>
  //       <i className="sa-icon sa-icon-arrow-up beta-wrap-button-arrow-left-icon"></i>
  //     </div>
  //   </div>
  // </div>
};
export default BetaWrap;
