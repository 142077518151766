import { createSlice } from '@reduxjs/toolkit';

export const querySlice = createSlice({
  name: 'query',
  initialState: {
    value: '',
    queryList: [],
    prevImgUrls: [],
    cosAuthInfo: null,
  },
  reducers: {
    addQuery: (state, action) => {
      state.queryList.unshift(action.payload);
    },
    updateQuery: (state, action) => {
      const list = [...state.queryList];
      const { timestamp } = action.payload;
      const hasItem = state.queryList.some((item) => item.timestamp === timestamp);
      if (!hasItem) {
        state.queryList = [{ ...action.payload, topicType: 'add', code: 0 }, ...list];
      } else {
        state.queryList = list.map((item) => {
          if (item.timestamp === timestamp) {
            return { ...item, ...action.payload };
          }
          return item;
        });
      }
    },
    pushErrorInfo(state, action) {
      window.aegis.error({
        msg: 'pushErrorInfo',
        ext1: JSON.stringify(action.payload),
        ext2: JSON.stringify({ userID: window.nickName }),
      });
      if (action.payload) {
        state.queryList = [action.payload, ...state.queryList];
      }
      // state.queryList.push(action.payload);
    },
    resetMaskStaus(state) {
      state.queryList = [...state.queryList].map((item) => {
        if (item.copyMaskIndex) {
          item.copyMaskIndex = '';
        }
        return item;
      });
    },
    disableAudioTopicStatus(state) {
      state.queryList = [...state.queryList].map((item) => {
        if (item?.type?.includes('audio') && !item.musicDisable) {
          item.musicDisable = true;
        }
        return item;
      });
    },
    updatePrevImgUrls(state, action) {
      state.prevImgUrls.push(action.payload);
    },
    setCosAuInfo(state, action) {
      state.cosAuthInfo = action.payload;
    },
    resetQueryList(state) {
      state.queryList = [];
    },
    // 初始化分享状态
    resetShareState(state, action) {
      // type=request/response
      const { timestamp, type } = action.payload;
      state.queryList = state.queryList.map((item) => {
        let ret = item;
        if (item.timestamp === timestamp) {
          if (type === 'response') {
            // 结果展示完成后，才能选中
            ret = {
              ...item,
              shareRequest: false,
              shareResponse: item.cardInfo?.cursor === 'end' && !item.cardInfo?.done?.stopResponse,
            };
          } else {
            ret = { ...item, shareRequest: true, shareResponse: false };
          }
        } else if (item.shareRequest || item.shareResponse) {
          ret = { ...item, shareRequest: false, shareResponse: false };
        }
        return ret;
      });
    },
  },
});

// 每个case reducer
export const {
  addQuery,
  updateQuery,
  pushErrorInfo,
  updatePrevImgUrls,
  setCosAuInfo,
  resetQueryList,
  resetShareState,
  resetMaskStaus,
  disableAudioTopicStatus,
} = querySlice.actions;

export default querySlice.reducer;
