import React, { useRef, useState, MouseEvent, useImperativeHandle, forwardRef, useEffect, useContext } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { C } from '../../App';
import { assets } from '../../common/js/assets';
import { globalVar } from '../../common/js/global';
import { sendNSPLog, setRSFrom } from '../../common/js/log';
import { movePageScroll } from '../../common/js/scroll';
import { setShowHeader } from '../../common/js/store/topicNumsSlice';
import { stopAuidoPlay } from '../../common/js/utils';
import { studyAssets } from '../../view/study/js/assets';
import { ContextProps } from '../../view/types/App';
import {
  ErrorInfo,
  RequestData,
  AudioInfo,
  TalkingBoxPageProps,
  PicParams,
  MaskAudioRef,
} from '../../view/types/talking';
import MaskAudio from '../maskAudio';

import InputMate from './inputMate';
import MultiMode from './multiModeBox';
import PiCBtns, { PicEventInfo } from './picBtns';
import RSTips, { EventInfo } from './rsTips';
import SearchBox from './searchBox';

// eslint-disable-next-line sonarjs/cognitive-complexity
const TalkingBox: React.FC<TalkingBoxPageProps> = forwardRef((props: TalkingBoxPageProps, ref: any) => {
  const {
    talkingShow,
    isStudyProject,
    questionId,
    questionType,
    isLastQuestion,
    onStudyTopic,
    searchKeyword = '',
    onTextTopic,
    onNewTopic,
    onCreateImgTopic,
    onCatchError,
    onCreateMusicTopic,
    limitError,
    onStopMuiscTopic,
    // isCreateTopicing,
    isEnd,
  } = props;
  const [topicCurrent, setTopicCurrent] = useState(props.topicCurrent);
  const { type, timestamp = 0, stopMusic } = topicCurrent;
  const { isNAFrom }: ContextProps = useContext(C);
  const [isCreateTopicing, setIsCreateTopicing] = useState(false);
  const pageTimeCurrent = useRef<number>(0);

  useEffect(() => {
    if (timestamp >= pageTimeCurrent.current) {
      pageTimeCurrent.current = timestamp;
      setIsCreateTopicing(!!topicCurrent?.isCreateTopicing);
      // if (isEnd) {
      //   setIsCreateTopicing(false);
      // }
    }
  }, [topicCurrent.isCreateTopicing]);
  // pageTimeCurrent.current = timestamp;

  const { onToast }: ContextProps = useContext(C);

  // 1倍行高 16px
  const textareaHeight = 26;
  const initHeight = 32;

  // 是否显示文本框
  const [showTopicBox, setShowTopicBox] = useState(true);

  // 判断是否为多行文本
  const [multiLine, setMultiLine] = useState(false);

  // 文本框是否聚焦
  const [isFocus, setIsFocus] = useState(false);

  // 是否显示话题按钮
  const [showTopic, setShowTopic] = useState(true);

  // 文件上传类型
  const uploadType = useRef('');

  // 对话式按钮
  const [topicBtns, setTopicBtns] = useState<string[]>([]);
  const [showTopicBtns, setShowTopicBtns] = useState(false);

  // 设置对话框文本
  const inputPlaceholder = isNAFrom ? '还有什么问题想问～' : '对话吧～';
  // const placeholderC = useRef(isStudyProject ? '对题目有疑问？提问试试' : inputPlaceholder);
  let placeholder = isStudyProject ? '对题目有疑问？提问试试' : inputPlaceholder;
  placeholder = isCreateTopicing ? '正在回复中' : placeholder;

  // 暂停回复
  const [pauseTopic, setPauseTopic] = useState(false);

  const topicRef = useRef<HTMLTextAreaElement>(null);
  const [topic, setTopic] = useState('');

  const [showPicsBtn, setShowPicsBtn] = useState(false);

  // 设置多模展开
  const [showMultimode, setShowMultimode] = useState(false);

  // 显示底部搜索框
  const [showSearchBox, setShowSearchBox] = useState(false);

  const currentReqParams = useRef<RequestData>({ timestamp: 0 });

  // 点击发起搜索按钮状态
  const [enterActive, setEnterActive] = useState(false);

  const maskAudioRef = useRef<MaskAudioRef>();

  // 点击clear按钮
  const isClearC = useRef<boolean>(false);

  // 手动跳转
  const navigate = useNavigate();

  // 设置初始loading动画
  const [isLoadingSend, setIsLoadingSend] = useState(true);
  setTimeout(() => {
    setIsLoadingSend(false);
  }, 800);
  // 设置上传图片的url
  const picUrl = useRef<PicParams>();
  const setPicUrl = (params: PicParams) => {
    picUrl.current = params;
  };

  const onEditTopic = (value: string) => {
    if (topicRef.current && value) {
      topicRef.current.value = value;
      setTopic(value);
      onInput(null, topicRef?.current);
      topicRef?.current?.focus();
    }
  };
  // 重置对话框
  const onReset = () => {
    setTopic('');
    if (topicRef.current) {
      topicRef.current.value = '';
      topicRef.current.style.height = `.${initHeight}rem`;
    }
    setMultiLine(false);
    setPauseTopic(false);
    setShowTopic(true);
  };
  // 用户输入
  const onInput = (e: any, currentDom?: HTMLTextAreaElement) => {
    const target = e?.target || currentDom;
    if (target.value === '' || !topicRef.current) {
      onReset();
      return;
    }
    setTopic(target.value || topic);
    topicRef.current.style.height = `.${textareaHeight}rem`;
    topicRef.current.style.height = `${target.scrollHeight}px`;
    // const lines = Math.floor(target.scrollHeight / textareaHeight);
    // const multiLines = lines >= 2;
    // 聚焦多行
    setMultiLine(true);
    if (target.scrollHeight > textareaHeight * 5) {
      topicRef.current.style.height = `.${textareaHeight}rem`;
      topicRef.current.style.height = `${textareaHeight * 5}px`;
    }
  };

  // 聚焦
  const onFocus = () => {
    // document.querySelector('.nsp-tips-box')?.scrollIntoView();
    setTimeout(() => {
      setShowTopic(false);
      setShowHeader(true);
      setIsFocus(true);
      setPauseTopic(false);
      setShowMultimode(false);
      // 聚焦多行
      if (topicRef?.current) {
        // topicRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        // topicRef.current.style.height = `.${textareaHeight}rem`;
        setMultiLine(true);
      }
      // 禁止页面滚动
      // stopPageScroll();
      // 键盘输入时隐藏搜索底部框
      if (searchKeyword) {
        setShowSearchBox(false);
      }
    }, 0);
    if (!globalVar.isPC) {
      const nspTipBox: HTMLDivElement | null = document.querySelector('.nsp-tips-box');
      if (nspTipBox) {
        // nspTipBox.scrollTop -= 10;
        nspTipBox.scrollIntoView();
      }
      const nspC: HTMLDivElement | null = document.querySelector('.nsp-container');
      const nspHeader: HTMLDivElement | null = document.querySelector('.nsp-header');
      if (nspC) {
        nspC.style.opacity = '0';
        nspC.style.height = '0px';
        nspC.style.width = '0px';
      }
      if (nspHeader) {
        nspHeader.style.display = 'block';
      }
    }
    sendNSPLog({
      action: 'click',
      modulename: 'input',
    });
  };
  // 失焦
  const onBlur = () => {
    setTimeout(() => {
      // 释放页面滚动
      movePageScroll();
      if (!topic) {
        setShowTopic(true);
      }
      if (isClearC.current !== true) {
        setIsFocus(false);
      }
      isClearC.current = false;
      if (window.location.pathname === '/' && !globalVar.isPC) {
        const nspC: HTMLDivElement | null = document.querySelector('.nsp-container');
        const nspHeader: HTMLDivElement | null = document.querySelector('.nsp-header');
        if (nspC) {
          nspC.style.opacity = '1';
          nspC.style.height = 'auto';
          nspC.style.width = '';
        }
        if (nspHeader) {
          nspHeader.style.display = 'none';
        }
      }
    }, 100);
    // setIsFocus(false);
    // 键盘失焦时显示搜索底部框
    if (searchKeyword && !showMultimode) {
      setTimeout(() => {
        setShowSearchBox(true);
      }, 100);
    }
  };
  const onChange = () => {
    if (isStudyProject) {
      if (!isCreateTopicing && topic !== '') {
        setEnterActive(true);
      } else {
        setEnterActive(false);
      }
    }
  };
  const onPopState = () => {
    // 如果不在会话页，隐藏相关的按钮
    if (window.location.pathname !== '/session') {
      setShowPicsBtn(false);
      setPauseTopic(false);
      setTopicBtns([]);
      // dispatch(resetQueryList());
    } else {
      const { reqPic, type, cardIndex = 0 } = topicCurrent;
      if (type === 'picInstruct' && reqPic?.contentDetected === 'person' && cardIndex < 2) {
        setShowPicsBtn(true);
      }
    }
  };
  const userInputSearch = () => {
    setEnterActive(false);
    const actionParams = {
      actionType: 'user-input',
      questionId,
      questionType,
    };
    onTextTopic({ timestamp: Date.now() }, actionParams);
  };
  const onAdd = () => {
    if (showMultimode) {
      topicRef?.current?.focus();
      setShowMultimode(false);
    } else {
      sendNSPLog({ action: 'expose', modulename: 'picture' });
      sendNSPLog({ action: 'expose', modulename: 'camera' });
      sendNSPLog({ action: 'expose', modulename: 'music' });
      setShowMultimode(true);
      setPauseTopic(false);
      // 隐藏搜索底部框
      if (searchKeyword) {
        setTimeout(() => {
          setShowSearchBox(false);
        }, 0);
      }
    }
    sendNSPLog({ action: 'click', modulename: 'plus' });
  };
  const onSession = (e: MouseEvent<HTMLDivElement> | KeyboardEvent, topicType: string) => {
    // 为空或者生成会话中不可以再次发出
    if (!topic || isCreateTopicing === true) {
      e?.preventDefault();
      return false;
    }
    onReset();
    topicRef?.current?.blur();
    // 键盘失焦时显示搜索底部框
    if (searchKeyword && !showMultimode) {
      setTimeout(() => {
        setShowSearchBox(true);
      }, 0);
    }
    onTextTopic({
      timestamp: Date.now(),
      query: topic,
      type: 'text',
    });
    const from = window.location.pathname === '/' ? 'homepage_frame' : 'dialogpage_frame';
    if (window.location.pathname === '/' && topic) {
      navigate(`/session?from=${from}`);
    }
    sendNSPLog({
      action: 'click',
      modulename: 'frame',
      prompt: topic,
      prompt_type: uploadType.current || 'data',
      from,
      topicType,
    });
  };
  // 处理图片，生成urlData
  const createUrlData = (file: File, fileName: string) => {
    if (!file || isCreateTopicing) {
      return;
    }
    const limitSize = 10 * 1024 * 1024;
    if (limitSize < file.size) {
      onToast(globalVar.uploadTips.limit);
      return;
    }
    // 限制图片格式
    const lastIndex = fileName.lastIndexOf('.');
    const suffix = fileName.substring(lastIndex + 1).toLocaleLowerCase();
    const imgFormat = ['jpg', 'jpeg', 'png', 'bmp'];
    if (!imgFormat.includes(suffix)) {
      onToast(globalVar.uploadTips.format);
      return;
    }
    // 10分钟内有交互或者无次数时不再提醒
    try {
      if (window.location.pathname === '/') {
        navigate('/session');
      }
      // setUploadResUrl('');
      const timestamp = Date.now();
      const windowURL = window.URL || window.webkitURL; // 兼容操作
      const dataURI = windowURL.createObjectURL(file);
      // currentPageUrl = dataURI || '';
      // setCurrntKeyword('');
      // setUploadUrl(dataURI);
      setShowMultimode(false);
      setShowPicsBtn(false);
      // createTopic = true;
      // stopResponse.current = false;
      // setCreateTopic(true);
      // dispatch(updateTopicCreating(true));
      onCreateImgTopic({
        timestamp,
        dataURI,
        file,
        fileName,
      });
    } catch (error) {
      onCatchError?.(error);
    }
  };
  // 清除
  const onClear = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (topic) {
      isClearC.current = true;
      onReset();
      setIsFocus(true);
      topicRef?.current?.focus();
    }
  };
  // 照片上传
  const onPhoto = (e: any) => {
    // console.log('选择图片', e.target.files[0], e.target.value);
    createUrlData(e.target.files[0], e.target.value);
    e.target.value = '';
    setRSFrom('dialogpage_pic');
    sendNSPLog({ action: 'click', modulename: 'picture', prompt_type: 'picture' });
    stopAuidoPlay();
  };
  // 拍照上传
  const onCamera = (e: any) => {
    // console.log('拍照上传', e.target.files[0], e.target.value);
    createUrlData(e.target.files[0], e.target.value);
    e.target.value = '';
    setRSFrom('dialogpage_pic');
    sendNSPLog({ action: 'click', modulename: 'camera', prompt_type: 'camera' });
    stopAuidoPlay();
  };
  // 处理音乐录音
  const onRecord = (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    const errorInfo: ErrorInfo = limitError();
    if (errorInfo.code !== 0) {
      return;
    }
    setShowMultimode(false);
    onCreateMusicTopic();
    stopAuidoPlay();
    sendNSPLog({ action: 'click', modulename: 'musicCompound', prompt_type: 'music' });
  };
  const onRecordSuccess = (audioInfo: AudioInfo) => {
    const timestamp = Date.now();
    // 请求nsp回复服务
    onTextTopic({
      url: '',
      timestamp,
      type: 'audioCompoundList',
      audioInfo,
    });
    sendNSPLog(
      {
        action: 'click',
        modulename: 'musicRecordSuccess',
        prompt_type: 'music',
        musicParams: JSON.stringify({ audioUrl: encodeURIComponent(audioInfo.audioUrl), duration: audioInfo.duration }),
      },
      currentReqParams.current,
    );
  };

  window.onkeydown = (event) => {
    if (13 === event.keyCode) {
      if (isStudyProject) {
        userInputSearch();
        return;
      }
      onSession(event, 'keydown');
      return false;
    }
  };
  const resetStatus = () => {
    setTopicBtns([]);
    setShowTopicBtns(false);
    setShowPicsBtn(false);
    // scrollToBottom(isStudyProject);
    stopAuidoPlay();
  };

  // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // 暴露给父组件修改mask弹窗的方法
    onReset,
    setTopicBtns,
    setShowSearchBox,
    setShowMultimode,
    setShowTopicBtns,
    setShowPicsBtn,
    setTopicCurrent,
    setPauseTopic,
    onPopState,
    resetStatus,
    setShowTopicBox,
    setPicUrl,
    onFocus: topicRef?.current?.onfocus,
    onBlur: topicRef.current?.onblur,
  }));

  return talkingShow ? (
    <>
      <div
        className={classNames('nsp-tips-box')}
        onClick={(e) => e.stopPropagation()}
        style={{
          opacity: showTopicBox ? '1' : '0',
          width: showTopicBox ? '100%' : '1px',
        }}
      >
        {pauseTopic && topicCurrent?.query ? (
          <div className="stop-container nsp-ie-row">
            <span className="flex-full sa-text-clamp1">{topicCurrent?.query}</span>
            <span className="split-line"></span>
            <span
              className="edit sa-spacing-left-6"
              onClick={() => {
                onEditTopic(topicCurrent?.query || '');
                setPauseTopic(false);
                sendNSPLog(
                  {
                    action: 'click',
                    modulename: 're_edit',
                  },
                  topicCurrent,
                );
              }}
            >
              重新编辑
            </span>
          </div>
        ) : null}
        {showTopicBtns && topicBtns && topicBtns.length > 0 ? (
          <RSTips
            topicBtns={topicBtns}
            doneData={topicCurrent?.done}
            onClick={(e: EventInfo) => {
              const { item } = e;
              // 不在创建中时可以进行操作
              if (!isCreateTopicing) {
                // setTopic(item);
                // console.log(topic);
                onTextTopic({
                  timestamp: Date.now(),
                  query: item,
                });
                setTopicBtns([]);
                setShowTopicBtns(false);
                sendNSPLog({
                  action: 'click',
                  modulename: 'frame',
                  prompt: topic,
                  prompt_type: topicCurrent?.type || 'data',
                  topicType: 'rs',
                });
              }
            }}
          />
        ) : null}
        {showPicsBtn ? (
          <PiCBtns
            doneData={topicCurrent?.done}
            onClick={(e: PicEventInfo) => {
              const { topic, type } = e;
              if (!isCreateTopicing) {
                setTopic(e.topic);
                onTextTopic({
                  timestamp: Date.now(),
                  query: topic,
                  type,
                  pic: picUrl.current,
                });
                setShowPicsBtn(false);
              }
            }}
          />
        ) : null}
        <div className="nsp-row container sa-spacing-bottom-8">
          {showTopic && !isFocus && !topic && !showMultimode && !isStudyProject ? (
            <button className={classNames('topic-box')}>
              <img
                src={
                  !isCreateTopicing && window.location.pathname === '/session'
                    ? assets.nspNewTopicBlue
                    : assets.nspNewTopic
                }
                onClick={onNewTopic}
                alt="topic"
              />
            </button>
          ) : null}
          {type?.includes('audio') && !stopMusic ? (
            <button
              className={classNames(
                'nsp-audio-record-btn',
                // type !== 'audioCompoundStart' ? 'nsp-audio-record-btn-gray' : '',
              )}
              onClick={() => {
                let modulename = '';
                if (type === 'audioCompoundStart') {
                  maskAudioRef.current?.setMask(true);
                  modulename = 'musicStartRecord';
                  stopAuidoPlay();
                } else {
                  modulename = 'musicStartStop';
                  onStopMuiscTopic();
                }
                sendNSPLog(
                  {
                    action: 'click',
                    modulename,
                  },
                  currentReqParams.current,
                );
              }}
            >
              {type !== 'audioCompoundStart' ? '结束音乐合成' : '开始录制'}
            </button>
          ) : (
            <>
              <div
                className={classNames('topic-input flex-full', isFocus || topic ? 'topic-input-selected' : 'nsp-row')}
              >
                <div className="flex-full">
                  <textarea
                    id="nspSessionInput"
                    ref={topicRef}
                    value={topic}
                    placeholder={placeholder}
                    className={classNames('flex-full')}
                    maxLength={globalVar.maxLength}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onInput={onInput}
                    onChange={onChange}
                  />
                  {isCreateTopicing ? <div className="createing"></div> : null}
                </div>
                {isStudyProject ? (
                  <img
                    src={assets.rightArrow}
                    className={classNames(
                      'enter-icon sa-spacing-left-8 align-flex-center',
                      enterActive ? 'enter-icon-active' : '',
                    )}
                    alt=""
                    onClick={() => {
                      userInputSearch();
                    }}
                  />
                ) : (
                  <InputMate
                    multiLine={multiLine}
                    isCreateTopicing={isCreateTopicing}
                    isLoadingSend={isLoadingSend}
                    isFocus={isFocus}
                    topic={topic}
                    onAdd={onAdd}
                    onSession={onSession}
                    onClear={onClear}
                  />
                )}
              </div>
            </>
          )}
          {isStudyProject ? (
            <div className="gpt-session-next sa-spacing-left-8">
              <img
                src={isLastQuestion ? studyAssets.nextGray : studyAssets.nextBlue}
                onClick={onStudyTopic}
                alt="nsp-search"
              />
            </div>
          ) : null}
        </div>
        {!globalVar.isPublicNet ? (
          <MultiMode showMultimode={showMultimode} onPhoto={onPhoto} onCamera={onCamera} onRecordClick={onRecord} />
        ) : null}
        {isNAFrom && showSearchBox ? <SearchBox keyword={searchKeyword} /> : null}
      </div>
      <MaskAudio ref={maskAudioRef} onRecordSuccess={onRecordSuccess}></MaskAudio>
    </>
  ) : null;
});
export default TalkingBox;
