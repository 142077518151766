let aniScroll = null;
let scrollHeight = 0;
let scrollClearTime = null;
let cancelAutoScroll = false;
let scrollType = '';

// 用户滚动监听
window.onscroll = () => {
  if (scrollType !== '') {
    scrollType = '';
  } else {
    cancelScrollClearTime();
  }
};

function scrollToBottom(isStudyProject = false) {
  // scrollType = 'scrollToBottom';
  const nspSession = document.querySelector('.nsp-session-bottom');
  scrollClearTime = setTimeout(() => {
    if (isStudyProject) {
      window.scrollTo(null, document.body.scrollHeight);
    } else {
      // document.querySelector('.nsp-session').scrollTop = 1000;
      // window.scrollTo(null, nspSession?.scrollHeight);
      // nspSession.scrollIntoView(false);
      nspSession?.scrollIntoView?.({ behavior: 'smooth', block: 'end' });
    }
  }, 20);
}

function scrollToBottomAnimate(addHeight) {
  const { body } = document;
  if (body.scrollHeight > scrollHeight) {
    if (scrollHeight >= 0) {
      aniScroll = requestAnimationFrame(() => {
        scrollToBottomAnimate(addHeight);
      });
      scrollHeight += addHeight;
      window.scrollTo(0, scrollHeight);
    }
  } else {
    scrollHeight = body.scrollHeight;
    cancelScroll();
  }
}

function cancelScroll() {
  // 如果上次滚动的动画存在，则先取消
  if (aniScroll) {
    cancelAnimationFrame(aniScroll);
  }
}

function cancelScrollClearTime() {
  // 如果上次滚动的动画存在，则先取消
  if (scrollClearTime) {
    cancelAutoScroll = true;
    cancelAnimationFrame(scrollClearTime);
  }
}

const pageScrollStop = (e) => {
  e.preventDefault();
};

// 禁止页面滑动
function stopPageScroll() {
  document.body.style.overflow = 'hidden'; // 出现滚动条
  // passive 参数不能省略，用来兼容ios和android
  document.body.addEventListener('touchmove', pageScrollStop, {
    passive: false,
  });
}

// 取消滚动限制
function movePageScroll() {
  document.body.style.overflow = ''; // 出现滚动条
  document.body.removeEventListener('touchmove', pageScrollStop, {
    passive: false, // 关闭被动监听
  });
}

export { scrollToBottomAnimate, scrollToBottom, cancelScroll, cancelScrollClearTime, stopPageScroll, movePageScroll };
