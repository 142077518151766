/* eslint-disable default-case */
import axios from 'axios';
import JSEncrypt from 'jsencrypt';

import { globalVar } from './global';

axios.defaults.timeout = 100000;

export const replaceReferTag = (response, docRefInfos = [], references) => {
  if (!docRefInfos || docRefInfos.length === 0) {
    return response;
  }
  let str = '';
  let start = 0;
  // console.log('replaceReferTag', response, docRefInfos, references);
  docRefInfos.forEach((docs, index) => {
    const docids = docs.doc_id;
    const { pos } = docs;
    const innerStr = response.slice(start, pos);
    let tagStr = '';
    start = pos;
    // 标记
    docids.forEach((doc) => {
      const currentDoc = references.filter((item) => item.doc_id === doc);
      tagStr += `<span class=search-result-tag data-href=${encodeURIComponent(
        currentDoc[0].url,
      )}><em class=search-result-tag-num>${doc}</em></span>`;
    });
    str += innerStr + tagStr;
    // 如果是最后一条，可能还会有剩下的字符串需要拼接
    if (index === docRefInfos.length - 1 && pos < response.length) {
      str += response.slice(pos, response.length);
    }
  });
  return str;
};

export const replaceHTMLTag = (response) => response?.replace(/\n/gi, '<br/>')?.replace(/\t/gi, '&nbsp;');

export const requestQuery = async (params = {}, extParams = {}) => {
  const urlParams = urlParamsParse();
  const debugParams = globalVar.isPublicNet
    ? {}
    : {
        needDebug: globalVar.isDebug,
      };
  const { expdbg = '', model = '', scene = '', expType = '', fromType = '' } = urlParams || {};

  const hostMap = {
    'nse.woa.com': 'NSE',
    'nsp.woa.com': 'NSP',
    'sge.qq.com': 'SGE',
    'sge.testsite.woa.com': 'SGE_TEST',
    'nspdev.woa.com': 'DEV',
  };
  // 新版SC开发参数
  const inferenceReq = {
    audioCompound: params.audioCompound || null,
    confirm: params.confirm || {
      confirmID: '',
      data: '',
    },
    header: {
      expdbg: decodeURIComponent(expdbg),
      dialogueID: params.dialogueID || '',
      lastDialogueID: '',
      model: encodeURIComponent(model),
      scene: encodeURIComponent(scene),
      nspKey: extParams.nspKey || '',
      fromType: encodeURIComponent(fromType),
      requestID: '',
      sessionID: params.sessionID || '',
      sessionIndex: params.sessionIndex || 0,
      timestamp: params.timestamp || 0,
      sign: '',
      platform: {
        hostType: hostMap[location.host] || '',
        expType: encodeURIComponent(expType),
      },
    },
    pic: params.pic || null,
    query: params.query || '',
    type: params.type || '',
    search: params.search || {
      passageThreshold: 0,
    },
    user: {
      userID: params.userID || '',
      nickName: window.nickName || params.userID || '',
    },
  };
  return await axios({
    method: 'POST',
    url: globalVar.api.reqQuery,
    data: JSON.stringify({
      inferenceReq,
      expdbg: decodeURIComponent(expdbg),
      ...params,
      ...debugParams,
      nspKey: extParams.nspKey || '',
      userID: globalVar.isPublicNet ? window.netQBID : '',
    }),
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  }).catch((error) => {
    const data = {
      code: -1,
      msg: error,
    };
    return { data };
  });
};

export const request = async ({ query, sessionID, dialogueID }) => {
  const reqUrl = '/open-admin/searchApi/query';
  const debugParams = {};
  return await axios({
    method: 'POST',
    url: reqUrl,
    data: JSON.stringify({
      query,
      sessionID,
      dialogueID,
      ...debugParams,
      userID: globalVar.isPublicNet ? window.netQBID : '',
    }),
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  });
};

export const requestVote = async ({ query, sessionID, dialogueID, vote }) =>
  await axios({
    method: 'POST',
    url: globalVar.api.reqVote,
    data: JSON.stringify({
      query,
      sessionID,
      dialogueID,
      vote,
      userID: globalVar.isPublicNet ? window.netQBID : '',
    }),
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  });

export const requestUserInfo = async () =>
  await axios({
    method: 'POST',
    url: globalVar.api.reqUserInfo,
    data: JSON.stringify({
      userID: globalVar.isPublicNet ? window.netQBID : '',
    }),
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  });

export const requestUserAuth = async (userID) =>
  await axios({
    method: 'POST',
    url: globalVar.api.getUserAuth,
    data: JSON.stringify({
      userID,
    }),
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  });

// eslint-disable-next-line sonarjs/no-identical-functions
export const requestPauseTopic = async ({ sessionID, dialogueID, timestamp, frameID }) =>
  await axios({
    method: 'POST',
    url: globalVar.api.reqPauseTopic,
    data: JSON.stringify({
      sessionID,
      dialogueID,
      timestamp,
      frameID,
      userID: globalVar.isPublicNet ? window.netQBID : '',
    }),
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  });

export const requestGetCosAuth = async () =>
  await axios({
    method: 'POST',
    url: globalVar.api.getCosAuth,
    data: null,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  });

export const requestUpload = async (file, fileName, contentType) => {
  const formdata = new FormData();
  formdata.append('data', file);
  formdata.append('filename', fileName);
  formdata.append('contentType', contentType || '');
  return await axios.post(globalVar.api.uploadImg, formdata, {
    timeout: 100000,
  });
};

// 请求点词翻译灵犀卡数据
export const requestTransWord = async ({ query, from, to }) =>
  await axios({
    method: 'POST',
    url: globalVar.api.getTransWord,
    data: {
      query: encodeURIComponent(query),
      from: encodeURIComponent(from),
      to: encodeURIComponent(to),
      userID: globalVar.isPublicNet ? window.netQBID : '',
    },
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  });

export const copyText = (query) => {
  const storage = document.createElement('textarea');
  // 避免闪动
  storage.setAttribute('readonly', 'readonly');
  storage.value = query || '';
  storage.style.width = '1px';
  storage.style.height = '1px';
  const element = document.querySelector('body');
  element.appendChild(storage);

  // Copy the text in the fake `textarea` and remove the `textarea`
  storage.select();
  storage.setSelectionRange(0, 99999);
  document.execCommand('copy');
  element.removeChild(storage);
};

export const urlParamsParse = () => {
  const obj = {};
  const arr = window.location.search.replace('?', '').split('&');
  arr.forEach((item) => {
    const kvs = item.split('=');
    obj[kvs[0]] = kvs[1];
  });
  return obj;
};

export const sendLogPB = (logParams) => {
  const logUrl = 'https://pb.sogou.com/pv.gif?pbtype=pv&uigs_productid=wapapp&type=nsp&stype=error';
  let logStr = '';
  if (typeof logParams === 'object') {
    for (const i in logParams) {
      logStr += `&${i}=${logParams[i] || ''}`;
    }
  }
  if (logStr === '') {
    return;
  }
  return reporter(logUrl + logStr);
};

/**
 * 上报接口
 * @param  {String}   src      上报完整url
 * @param  {Function} callback 上报回调
 * @return {Self}
 */
function reporter(src, callback) {
  const d = `memory_log_${Date.now()}${getRand()}`;
  let img = new Image();
  const callBack = function () {
    img.onload = null;
    img.onerror = null;
    img.onabort = null;
    img = null;
    window.d = null;
    typeof callback === 'function' && callback(src);
  };
  window[d] = img;

  img.onload = callBack;
  img.onerror = callBack;
  img.onabort = callBack;
  img.src = src;
  return this;
}

/**
 * 获取随机数，精确到ms级
 * @return {String}
 */
function getRand() {
  return Math.floor(Math.random() * 1000);
}

export function disableTouchs() {
  // 阻止双击放大
  let lastTouchEnd = 0;
  document.addEventListener(
    'touchstart',
    (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    },
    {
      passive: false, // 关闭被动监听
    },
  );
  document.addEventListener(
    'touchmove',
    (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    },
    {
      passive: false, // 关闭被动监听
    },
  );
  document.addEventListener(
    'touchend',
    (event) => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300 || event.touches.length > 1) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false,
  );

  // 阻止双指放大
  document.addEventListener('gesturestart', (event) => {
    event.preventDefault();
  });
}

export function dclRSA(uri, userId) {
  const query = {
    pub_key:
      'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAydQ7ToJVbPExt+DqeCEaepK8O1fx1KBcSQkJyMygpUEUd6IHfwZ57Emo5bfWfPw5G9FN7QfW0TNTXPPP5yZf1QOzi/RQZcNC7m3WwgN0+cbyYvh3neCbRQB6xkbBY1XyawuUocipYJBvTz7JRDOGNbt3UW2hrxRpdLWor9/c8os8yHrwm6J0oaVxUDY4Z1YOPOV8aUseDWELLWAf0EzYdY8yBLuPl0kk90/QRe9wqNA88LY4oDYypGYTVzWaiBhUrY2ECyoCcbwrMju1QGg3AE/G9jScX9Z7Qoe9hhIsWtpkoFYY5RBnIgjE0AR+6vriMluV8YQ1KRvPDht92sIojQIDAQAB',
    appId: 'x7agpxec83',
  };
  const t = Date.now();
  const encrypt = new JSEncrypt(); // 新建JSEncrypt对象

  encrypt.setPublicKey(query.pub_key); // 设置公钥 / publicKey为公钥

  const encrypted = encrypt.encrypt(`userid=${userId}&t=${t}&version=${t}`); // 对密码进行加密
  const data = encrypted;

  return `${uri}?appId=${query.appId}&data=${data}`;
}

export function getRandom(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}
/**
 * 获取随机长度字符串
 * @param  {Number}   length      长度
 * @return {String}
 */
export function getCode(length) {
  let code = '';
  for (let i = 0; i < length; i++) {
    const type = getRandom(1, 3);
    switch (type) {
      case 1:
        code += String.fromCharCode(getRandom(48, 57)); //  数字
        break;
      case 2:
        code += String.fromCharCode(getRandom(65, 90)); //  大写字母
        break;
      case 3:
        code += String.fromCharCode(getRandom(97, 122)); //  小写字母
        break;
    }
  }
  return code;
}
export const requestDclLevel = async (url) =>
  await axios
    .get(url, {
      headers: {
        Source: 'api',
        'X-Tone-RequestId': `${getCode(22)}_${parseInt(Date.now() / 1000) - 10}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      const data = {
        errcode: -1,
        msg: error,
      };
      return { data };
    });
export const requestDclCustom = async (url) =>
  await axios
    .get(url, {
      headers: {
        Source: 'api',
        'X-Tone-RequestId': `${getCode(22)}_${parseInt(Date.now() / 1000) - 10}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      const data = {
        errcode: -1,
        msg: error,
      };
      return { data };
    });
export const requestDclFeedBack = async (url, params = {}) =>
  await axios
    .post(url, params, {
      headers: {
        Source: 'api',
        'X-Tone-RequestId': `${getCode(22)}_${parseInt(Date.now() / 1000) - 10}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      const data = {
        errcode: -1,
        msg: error,
      };
      return { data };
    });

export function loadJs(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = resolve;
    script.onerror = reject;
    script.src = src;
    document.getElementsByTagName('head')[0].appendChild(script);
  });
}

export const stopAuidoPlay = () => {
  // 停止之前所有的auido播放
  const allAudio = document.querySelectorAll('.nsp-session audio');
  allAudio.forEach((audio) => {
    audio.pause();
  });
};

export const getUserInfo = async () =>
  await axios({
    method: 'POST',
    url: globalVar.api.userInfo,
    data: JSON.stringify({
      userID: globalVar.isPublicNet ? window.netQBID : '',
    }),
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  });

export const getHeaderInfo = async () =>
  await axios({
    method: 'POST',
    url: globalVar.api.getHeaderInfo,
    data: {
      userID: globalVar.isPublicNet ? window.netQBID : '',
    },
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
    timeout: 100000,
    withCredentials: true,
  });

export const waterMark = (username) => {
  // const { username } = props;
  // console.log(document.getElementById('myWaterMark'));
  let imgBase64 = '';
  if (document.getElementById('myWaterMark') !== null) {
    return;
  }
  const svg = document.createElement('div');

  svg.innerHTML = `
          <svg id="mySvg" width="250" height="240" xmlns="http://www.w3.org/2000/svg">          
              <text x="50%" y="50%" font-size="14" font-weight="normal" fill="#b7b8c6" fill-opacity="0.3" font-family="system-ui, sans-serif"  
                  text-anchor="middle" dominant-baseline="middle" transform="rotate(-35, 100 100)">
                  ${username}
              </text>
              <text x="50%" y="50%" font-size="14" font-weight="normal" fill="#b7b8c6" fill-opacity="0.3" font-family="system-ui, sans-serif"  
                  text-anchor="end" dominant-baseline="middle" transform="rotate(-35, 40 10)">
                  AI生成内容仅供参考
              </text>
          </svg>`;

  if (svg === null) {
    return;
  }
  const s = new XMLSerializer().serializeToString(svg.childNodes[1]);
  const src = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(s)))}`;
  // svg 转png
  const img = new Image(); // 创建图片容器承载过渡
  img.src = src;
  img.onerror = (err) => {
    // eslint-disable-next-line no-console
    console.error('err', err);
  };
  img.onload = () => {
    const canvas = document.createElement('canvas');
    // console.log('img.width', img.width);
    canvas.width = img.width;
    canvas.height = img.height;
    const context = canvas.getContext('2d');
    context?.drawImage(img, 0, 0);
    imgBase64 = canvas.toDataURL('image/png');
    svg.style.background = `url(${imgBase64}) left top repeat`;
  };

  svg.style.pointerEvents = 'none';
  svg.style.top = '0px';
  svg.style.left = '0px';
  svg.style.position = 'fixed';
  svg.style.zIndex = '99999';
  svg.style.width = '100%';
  svg.style.height = '100%';
  svg.id = 'myWaterMark';
  document.body.appendChild(svg);
  const orignSvg = document.getElementById('mySvg');
  orignSvg?.remove();
};

export const isContain = (dom) => {
  const totalHeight = window.innerHeight || document.documentElement.clientHeight;
  const totalWidth = window.innerWidth || document.documentElement.clientWidth;
  // 当滚动条滚动时，top, left, bottom, right时刻会发生改变。
  const { top, right, bottom, left, width } = dom.getBoundingClientRect();
  return top >= 0 && left >= 0 && right <= totalWidth + width && bottom <= totalHeight;
};

export const toggleFixBox = (isShow) => {
  const nspFixBox = document.querySelector('.nsp-fixed-top-box');
  const nspFixgradientBox = document.querySelector('.nsp-fixed-top-gradient-box');
  if (nspFixgradientBox && nspFixBox) {
    nspFixBox.style.display = isShow ? '' : 'none';
    nspFixgradientBox.style.display = isShow ? '' : 'none';
  }
};

export const openNaUrl = (url) => {
  if (!globalVar.isQB) {
    return;
  }
  const options = {
    url,
    newWindow: false,
  };
  if (globalVar.isiOS) {
    window?.browser?.app?.openUrl(null, null, options);
  } else if (globalVar.isAndroid) {
    window.browser.qb.openUrlInBrowser(options);
  }
};
