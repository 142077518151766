import { createSlice } from '@reduxjs/toolkit';

import { urlParamsParse } from '../utils';

const getCurrentUrlParams = urlParamsParse();
const { questionIndex = '0', sceneType } = getCurrentUrlParams;
const pathname = window.location?.pathname;
export const studySlice = createSlice({
  name: 'study',
  initialState: {
    questionList: [],
    talkingShow: !(sceneType === 'study' && pathname === '/'),
    questionIndex: parseInt(questionIndex, 10),
    translateAllData: {
      content: [],
      status: -1, // -1: 未开始 0: 加载中 1: 生成中 2: 生成成功 3: 生成失败
      cursor: '',
    },
    topicCreating: false,
    wordStorageMap: {},
  },
  reducers: {
    updateQuestionList: (state, action) => {
      state.questionList = action.payload;
    },
    updataTalkingShow: (state, action) => {
      state.talkingShow = action.payload;
    },
    updateQuestionIndex: (state, action) => {
      state.questionIndex = action.payload;
    },
    updateTranslateAllData: (state, action) => {
      state.translateAllData = action.payload;
    },
    updateTopicCreating: (state, action) => {
      state.topicCreating = action.payload;
    },
    updateWordStorageMap: (state, action) => {
      state.wordStorageMap = action.payload;
    },
  },
});
// 每个case reducer
export const {
  updateQuestionList,
  updataTalkingShow,
  updateQuestionIndex,
  updateTranslateAllData,
  updateTopicCreating,
  updateWordStorageMap,
} = studySlice.actions;

export default studySlice.reducer;
