import React, { useEffect } from 'react';

import Loading from '../../../components/loading';
import { studyAssets } from '../js/assets';
import Audio from '../js/audio';
import { loadStatusMap } from '../js/model';

interface PagePros {
  pointWordInfo?: PointWordInfoParams;
  popPosition: PopPositionParams;
  goSearch?: any;
  popRef?: any;
  clearWordPointPop?: any;
}
interface PopPositionParams {
  pointPopX?: number;
  pointPopY?: number;
  pointPopDirection?: string;
}
interface PointWordInfoParams {
  word?: string;
  basicWordInfo?: BasicWordInfo;
  gptWordInfo?: GptWordInfo;
  loadStatus?: number;
}
interface BasicWordInfo {
  machineData?: any;
  phonetics?: any[];
  details?: any[];
  status: number;
}
interface GptWordInfo {
  meaning?: string;
  status: number;
}
const PointWordPop: React.FC<PagePros> = (props: PagePros) => {
  const { goSearch, pointWordInfo, popPosition, popRef, clearWordPointPop } = props;
  const { loadStatus, basicWordInfo, gptWordInfo, word } = pointWordInfo || {};
  const { phonetics = [], details = [], machineData, status: basicStatus } = basicWordInfo || {};
  const { meaning: gptWordMeaning, status: gptStatus } = gptWordInfo || {};
  const { pointPopX, pointPopY, pointPopDirection } = popPosition || {};

  useEffect(() => {
    window.addEventListener('offline', () => {
      const audio = document.querySelector('audio');
      if (audio) {
        audio.classList.remove('active');
        Audio.destroy();
      }
    });
  }, []);
  let isOn = false;
  const play = (event: any, item: any) => {
    const e = event || window.event;
    const { target } = e;
    if (item) {
      const { className } = target;
      if (className.indexOf('play') !== -1) {
        isOn = false;
      } else {
        isOn = true;
      }
      Audio.init({
        src: item.src || item.filename,
        loop: false,
        isLoop: false,
        isOn,
        target,
      });
      const clickType = ['v', item.type];
      item.lang && clickType.push(item.lang);
    } else {
      Audio.destroy();
    }
  };
  return (
    <div className="nsp-study-word-wrap">
      <div
        className="nsp-study-word-box"
        style={{ top: pointPopY }}
        ref={popRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="nsp-study-word-title">{word}</div>
        {loadStatus === loadStatusMap.loading ? <Loading loadingText=""></Loading> : null}
        {loadStatus === loadStatusMap.done ? (
          <>
            <div className="nsp-study-word-pronounce">
              {phonetics?.length > 0 &&
                phonetics?.map((item: any, index: number) => (
                  <div className="item" key={index}>
                    <span className="phonetic">
                      {item.type} {item.text ? `[${item.text}]` : ''}
                    </span>
                    <img
                      className="sa-spacing-left-8 gpt-pronounce-icon"
                      src={studyAssets.pronounce}
                      onClick={(e) => {
                        play(e, item);
                      }}
                      alt=""
                    ></img>
                  </div>
                ))}
            </div>
            <div className="nsp-study-word-paraphrase sa-text-clamp3">
              <span className="nsp-study-paraphrase-txt">文中释义</span>
              {gptStatus === loadStatusMap.success ? (
                <span>{gptWordMeaning}</span>
              ) : (
                <span className="nsp-error-tips">暂无数据，请查询其它词</span>
              )}
            </div>
            {basicStatus === loadStatusMap.success ? (
              <div className="nsp-study-word-paraphrase sa-text-clamp2">
                <span className="nsp-study-paraphrase-txt">基础释义</span>
                {details?.length > 0 ? (
                  details.map((detail: any, detailIndex: number) => (
                    <span key={detailIndex}>{`${detail.pos} ${detail.value}；`}</span>
                  ))
                ) : (
                  <span>{machineData.transText}</span>
                )}
              </div>
            ) : null}
            <div
              className="nsp-study-result-btn"
              onClick={(e) => {
                goSearch(e, `${word} 翻译`);
              }}
            >
              查看搜索结果
            </div>
          </>
        ) : null}
        <div
          className={`nsp-study-triangle ${pointPopDirection === 'top' ? 'top' : 'bottom'}`}
          style={{ left: pointPopX }}
        ></div>
      </div>
      <div
        className="mask-translate-bg"
        onClick={() => {
          clearWordPointPop();
        }}
      ></div>
    </div>
  );
};

export default PointWordPop;
