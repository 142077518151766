export const loadStatusMap = {
  unStart: -1,
  loading: 0,
  generating: 1,
  success: 2,
  fail: 3,
  done: 4,
};
export const MAX_VOICE_LEN = 400;
// tts语音播放
export const AUDIO_TTS_PATH = '/openapi/external/getWebTTS';

export const actionMap = {
  translateWord: 'translate-word',
  translateAll: 'translate-all',
  answerExercise: 'answer-exercise',
};
