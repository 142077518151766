export const ICONS = {
  day: {
    晴: 'sunny-day.png',
    大部晴朗: 'sunny-day.png',
    多云: 'cloudy.png',
    少云: 'cloudy.png',
    阴: 'overcast.png',
    小雨: 'light-rain.png',
    中雨: 'moderate-rain.png',
    大雨: 'heavy-rain.png',
    暴雨: 'rainstorm.png',
    大暴雨: 'big-rainstorm.png',
    特大暴雨: 'extra-large-rainstorm.png',
    雷阵雨: 'thunderstorm.png',
    雷阵雨伴有冰雹: 'thunderstorm-hail.png',
    冻雨: 'freezing-rain.png',

    冰针: 'hail.png',
    冰雹: 'hail.png',
    冰粒: 'hail.png',
    雷爆: 'thunder-blast.png',
    雷电: 'thunder.png',

    阵雨: 'shower.png',
    局部阵雨: 'shower.png',
    小阵雨: 'shower.png',
    强阵雨: 'shower.png',
    雨: 'moderate-rain.png',
    阵雪: 'day-snow-shower.png',
    小阵雪: 'day-snow-shower.png',
    小雪: 'light-snow.png',
    雨夹雪: 'sleet.png',
    中雪: 'moderate-snow.png',
    大雪: 'heavy-snow.png',
    暴雪: 'blizzard.png',
    雪: 'moderate-snow.png',
    雾: 'fog.png',
    冻雾: 'fog.png',
    大雾: 'heavy-fog.png',
    浓雾: 'dense-fog.png',
    强浓雾: 'strong-dense-fog.png',
    特强浓雾: 'extra-strong-dense-fog.png',
    雾霾: 'haze.png',
    霾: 'haze.png',
    中度霾: 'moderate-haze.png',
    重度霾: 'severe-haze.png',
    严重霾: 'big-severe-haze.png',
    浮尘: 'floating-dust.png',
    尘卷风: 'floating-dust.png',
    扬沙: 'sand-blowing.png',
    沙尘暴: 'sand-storm.png',
    强沙尘暴: 'heavy-sand-storm.png',
  },
  night: {
    阵雨: 'night-shower.png',
    雨: 'night-shower.png',
    多云: 'night-cloudy.png',
    阵雪: 'night-snow-shower.png',
    雪: 'night-snow-shower.png',
    晴: 'night-sunny-day.png',
  },
};
type Kinds = keyof typeof ICONS;

export function weatherIcon<T extends Kinds>(params: { kind: T; key: keyof typeof ICONS[T] }): string {
  let { key, kind } = params;
  const baseUrl = '//dlweb.sogoucdn.com/vrfly/opentest/wap/dist/ued/images/weather_big/';
  let file = (ICONS[kind] as any)[key] || '';
  if (!file) file = (ICONS.day as any)[key] || '';
  if (!file) {
    key = formatDescription(key as string) as any;
    for (const k in ICONS[kind]) {
      if ((key as string).indexOf(k) != -1) {
        key = k;
        break;
      }
    }
    file = (ICONS[kind] as any)[key] || '';
    if (!file) file = (ICONS.day as any)[key] || '';
  }

  return `${baseUrl}${file}`;
}

interface PmInfo {
  level: string;
  color: string;
  value?: number;
}
export function pmLevel(val: string): PmInfo {
  let rs = null as unknown as PmInfo;
  if (!val) {
    return rs;
  }
  const value = parseInt(val);

  if (value <= 50)
    rs = {
      level: '优',
      color: 'type01',
    };
  else if (value > 50 && value <= 100)
    rs = {
      level: '良',
      color: 'type02',
    };
  else if (value > 100 && value <= 150)
    rs = {
      level: '轻',
      color: 'type03',
    };
  else if (value > 150 && value <= 200)
    rs = {
      level: '中',
      color: 'type04',
    };
  else if (value > 200 && value <= 300)
    rs = {
      level: '重',
      color: 'type05',
    };
  else if (value > 301 && value <= 500)
    rs = {
      level: '严重',
      color: 'type06',
    };
  else {
    rs = {
      level: '爆表',
      color: 'type07',
    };
  }

  rs.value = value;
  return rs;
}

export function evalData(str: string): any {
  let ret = {};
  try {
    if (typeof str === 'string') {
      str = str.replace(/\n|\r|\f/gi, '');
      // eslint-disable-next-line no-eval
      ret = eval(decodeURIComponent(str));
    }
  } catch (e) {
    console.error(e);
  }
  return ret;
}

interface TodayWeather {
  temperature: number;
  qixiang: string;
  fengli: string;
  fengxiang: string;
  wet: string;
}
export function formatDcDataWeather(data: any): TodayWeather {
  const ret = {} as TodayWeather;
  if (data && data && data.l1 && data.l7 && data.l3 && data.l4) {
    ret.temperature = parseInt(data.l1);
    ret.qixiang = data.l5;

    ret.fengli = data.l3 == 0 ? 1 : data.l3;
    ret.fengxiang = data.l4;
    ret.wet = data.l2;
  }
  return ret;
}

function toDBC(dataStr = '') {
  let tmp = '';
  for (let i = 0; i < dataStr.length; i++) {
    if (dataStr.charCodeAt(i) > 65248 && dataStr.charCodeAt(i) < 65375) {
      tmp += String.fromCharCode(dataStr.charCodeAt(i) - 65248);
    } else if (dataStr.charCodeAt(i) === 12288) {
      tmp += ' ';
    } else {
      tmp += String.fromCharCode(dataStr.charCodeAt(i));
    }
  }
  return tmp;
}
export function getQoObj(qoQuery: any) {
  if (!qoQuery) return {};
  const res = {} as any;
  const arr = qoQuery.indexOf('||') === -1 ? [qoQuery] : qoQuery.split('||');
  let temp;
  arr.forEach((item: any) => {
    temp = item.split('::');
    res[temp[0]] = toDBC(temp[1]);
  });
  return res;
}

function getNowInfo() {
  const dateLocal1 = new Date();
  const nowHour = `${dateLocal1.getHours()}`;
  const formatDate = function (dateObj: any) {
    return [
      dateObj.getFullYear(),
      '-',
      `${dateObj.getMonth() + 1}`.padStart(2, '0'),
      '-',
      `${dateObj.getDate()}`.padStart(2, '0'),
    ].join('');
  };
  return {
    todayStr: formatDate(dateLocal1),
    nowHour,
    nowDate: dateLocal1.getDate(),
    nowTime: `${nowHour.padStart(2, '0')}:${dateLocal1.getMinutes()}${''.padStart(2, '0')}`,
    lastDay1: formatDate(new Date(dateLocal1.getTime() - 24 * 3600 * 1000)),
    nextDay1: formatDate(new Date(dateLocal1.getTime() + 24 * 3600 * 1000)),
    nextDay2: formatDate(new Date(dateLocal1.getTime() + 2 * 24 * 3600 * 1000)),
    nextDay3: formatDate(new Date(dateLocal1.getTime() + 3 * 24 * 3600 * 1000)),
    nextDay4: formatDate(new Date(dateLocal1.getTime() + 4 * 24 * 3600 * 1000)),
  };
}

function formatDescription(description: string) {
  let ret = description;
  if (ret.indexOf('到') != -1) {
    ret = ret.split('到')[1];
  }
  if (ret.indexOf('转') != -1) {
    ret = ret.split('转')[0];
  }
  if (ret == '雨') {
    ret = '中雨';
  }
  if (ret == '雪') {
    ret = '中雪';
  }
  if (ret.indexOf('晴') != -1) {
    ret = '晴';
  }
  if (ret.indexOf('阵雨') != -1) {
    ret = '阵雨';
  }
  if (ret.indexOf('阵雪') != -1) {
    ret = '阵雪';
  }
  if (ret.indexOf('雾') != -1) {
    ret = '雾';
  }
  if (ret == '尘卷风') {
    ret = '浮尘';
  }
  if (ret.indexOf('云') != -1) {
    ret = '多云';
  }
  if (ret.indexOf('冰') != -1) {
    ret = '冰雹';
  }

  return ret;
}
export function formatDaysList(list: any[]) {
  const fieldMap = {
    // 字段名映射
    number_low: 'low',
    number_high: 'high',
    value_day_pm25: 'day_pm2.5_value',
    time_month: 'month',
    date_day: 'days',
    forbidden: 'forbidden',
    description_day: 'daydescription',
    date: 'date',
    week: 'week',
    other_temperature: 'real_feel',
    uvi: 'uvi',
    precipitation: 'precip_1h',
    typhoon: 'typhoon',
    description_night: 'nightdescription',
    summary: 'description',
    day_wind: 'daywind',
    day_windother: 'daywind1',
    night_wind: 'nightwind',
    night_windother: 'nightwind1',
    type_wind: 'wind',
    type_windlevel: 'wind1',
    humidity: 'shidu',
    time_sunrise: 'sunrise',
    time_sunset: 'sunset',
    gray_day_pm25: 'day_pm2.5_grade',
  } as any;
  let ret = [] as any;
  let todayPos = -1;
  const nowDateObj = getNowInfo();
  list.forEach((item: any, index: number) => {
    const obj = {} as any;
    for (const [fieldName, val = ''] of Object.entries(item)) {
      const key = fieldMap[fieldName];
      if (key == 'low') {
        obj.low = parseInt(val as string);
      } else if (key == 'hight') {
        obj.hight = parseInt(val as string);
      }
      if (key == 'day_pm2.5_value') {
        obj.pm = pmLevel(val as string);
      } else if (key == 'month' || key == 'days') {
        obj[key] = (val as string).padStart(2, '0');
      } else if (key == 'forbidden') {
        obj[key] = (val as string).replace('限行:', '');
      } else if (key == 'wind') {
        if (val == '无持续风向') {
          obj.wind = '';
        } else {
          obj.wind = val;
        }
      } else if (key == 'daydescription') {
        obj[key] = formatDescription(val as string);
      } else {
        obj[key] = val;
      }
    }

    obj.qixiangimg = weatherIcon({
      kind: 'day',
      key: item.description_day,
    });
    if (obj.date == nowDateObj.lastDay1) {
      obj.dateText = '昨天';
    } else if (obj.date == nowDateObj.todayStr) {
      obj.dateText = '今天';
      todayPos = index;
    } else if (obj.date == nowDateObj.nextDay1) {
      obj.dateText = '明天';
    } else {
      obj.dateText = obj.week;
    }
    ret.push(obj);
  });
  // 如果包含今天，把今天置到第2列
  if (todayPos > 0) {
    ret = ret.slice(todayPos - 1);
  }
  return ret;
}
export function initQueryIndex(daysList: any[], qoObj: any) {
  /**
   * mingzhongArr = ['12月1号;12月2号;12月3号;12月1日;12月2日;12月3日;周三;周四;周五;昨天;今天;明天']
   *
   **/
  const len = daysList.length;
  const mingzhongArr = [];
  const mzKey = 'date1';
  let todayIndex = -2;
  let queryIndex = -1;
  let isQueryToday = false;
  let queryInfo = '';
  // 判断queryIndex
  const nowDateObj = getNowInfo();
  for (let j = 0; j < len; j++) {
    const one = daysList[j];
    let text = '';
    if (one.date == nowDateObj.lastDay1) {
      text = '昨天';
    } else if (one.date == nowDateObj.todayStr) {
      todayIndex = j;
      text = '今天';
    } else if (one.date == nowDateObj.nextDay1) {
      text = '明天';
    } else if (one.date == nowDateObj.nextDay2) {
      text = '后天';
    } else if (one.date == nowDateObj.nextDay3) {
      text = '大后天';
    } else if (one.date == nowDateObj.nextDay4) {
      text = '大大后天';
    }
    mingzhongArr[j] = `${parseInt(one.month)}月${parseInt(one.days)}日`;
    mingzhongArr[j + len] = `${parseInt(one.month)}月${parseInt(one.days)}号`;
    mingzhongArr[j + 2 * len] = one.week;
    mingzhongArr[j + 3 * len] = text;
  }

  if (mzKey in qoObj) {
    const qoVal = toDBC(qoObj[mzKey]);

    for (let ii = 0; ii < mingzhongArr.length; ii++) {
      const item = mingzhongArr[ii];
      if (qoVal == item) {
        queryIndex = ii % len;
        break;
      }
    }
  }

  if (queryIndex < 0) {
    if (todayIndex >= 0) {
      queryIndex = todayIndex;
      isQueryToday = true;
    } else {
      queryIndex = len - 1;
      isQueryToday = false;
    }
  } else {
    if (queryIndex == todayIndex) {
      isQueryToday = true;
    }
  }
  queryInfo = daysList[queryIndex];
  return { queryInfo, queryIndex, isQueryToday, todayIndex };
}

export function checkNight(day: any) {
  const nowDateObj = getNowInfo();
  const { nowTime } = nowDateObj;
  return !!(nowTime > day.sunset || nowTime < day.sunrise);
}

// 获取日出日落时间AQI
function sunStatusAQI(time: string) {
  let hour;
  hour = time.split(':')[0];
  hour = parseInt(hour);
  return hour;
}
function getSunImg(state = 'set') {
  if (state == 'set') {
    return '//dlweb.sogoucdn.com/vrfly/opentest/wap/dist/ued/images/weather/sunset.png';
  }
  return '//dlweb.sogoucdn.com/vrfly/opentest/wap/dist/ued/images/weather/sunrise.png';
}
export function getHourList(xmlHourList: any[], queryInfo: any, todayInfo: string) {
  const ret = [] as any;
  if (!xmlHourList) {
    return ret;
  }

  const nowDateObj = getNowInfo();
  const len = xmlHourList.length;
  let initQueryIndex = -1;
  // 兼容hour_day 不存在情况
  let hour_day = xmlHourList[0].day_hour;
  if (hour_day) {
    xmlHourList.some((item: any, index) => {
      hour_day = item.day_hour;
      if (
        parseInt(item.time as string) == (nowDateObj.nowHour as unknown as number) &&
        parseInt(hour_day) == nowDateObj.nowDate
      ) {
        initQueryIndex = index;
        return true;
      }
    });
  } else {
    xmlHourList.some((item: any, index) => {
      if (parseInt(item.time as string) == (nowDateObj.nowHour as unknown as number)) {
        initQueryIndex = index;
        return true;
      }
    });
  }
  if (initQueryIndex < 0 || initQueryIndex >= len) {
    // 没有‘现在’ ，则24小时不展示
    return ret;
  }
  const t1 = queryInfo.sunrise;
  const t2 = queryInfo.sunset;
  // 获取日出日落AQI取值时间
  const sunStatusInfo = {
    riseTime: '',
    riseAqi: null,
    setTime: '',
    setAqi: null,
  } as any;
  sunStatusInfo.riseTime = sunStatusAQI(t1);
  sunStatusInfo.setTime = sunStatusAQI(t2);
  for (let i = initQueryIndex; i < len; i++) {
    const obj = {} as any;
    const onehour = xmlHourList[i];
    obj.time = onehour.time;
    obj.wind1 = onehour.wind1;
    const tmptime = obj.time.replace('时', '');
    if (t1 && t2 && (tmptime <= parseInt(t1) || tmptime > parseInt(t2))) {
      obj.img = weatherIcon({
        key: onehour.state,
        kind: 'night',
      });
    } else {
      obj.img = weatherIcon({
        key: onehour.state,
        kind: 'day',
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    parseInt(tmptime as string, 10) == (nowDateObj.nowHour as unknown as number) ? (obj.time = '现在') : '';
    obj.temperature = `${parseInt(onehour.temperature, 10)}°`;
    const tmpPm = onehour.value_hour_pm25 || '';
    if (tmpPm) {
      obj.pm = pmLevel(tmpPm);
    }
    // 用实时温度替换 xml 数据
    if (obj.time === '现在' && (todayInfo as any).temperature) {
      obj.temperature = `${parseInt((todayInfo as any).temperature, 10)}°`;
      const isNight = checkNight(queryInfo);
      obj.img = weatherIcon({
        kind: isNight ? 'night' : 'day',
        key: (todayInfo as any).qixiang,
      });
    }

    ret.push(obj);
    if (sunStatusInfo.riseTime == parseInt(obj.time)) {
      sunStatusInfo.riseAqi = obj.pm;
    }
    if (sunStatusInfo.setTime == parseInt(obj.time)) {
      sunStatusInfo.setAqi = obj.pm;
    }
    // 插入日出日落
    if (parseInt(t1) == parseInt(obj.time)) {
      ret.push({
        time: t1,
        img: getSunImg('rise'),
        temperature: '日出',
        pm: {},
      });
    }
    if (parseInt(t2) == parseInt(obj.time)) {
      ret.push({
        time: t2,
        img: getSunImg('set'),
        temperature: '日落',
        pm: {},
      });
    }
  }
  ret.some((item: any) => {
    if (item.temperature == '日出') {
      item.pm = sunStatusInfo.riseAqi;
    }
    if (item.temperature == '日落') {
      item.pm = sunStatusInfo.setAqi;
    }
  });

  return ret;
}

function getFormatData(str = ''): Date {
  str = str.replace(/-/g, '/');
  return new Date(str);
}

export function getWarningObj(data: any[]) {
  if (!data || data.length < 1) {
    return '';
  }

  const warningColor = {
    蓝色: 'type01',
    黄色: 'type02',
    橙色: 'type03',
    红色: 'type04',
  } as any;
  const warningImgMap = {
    暴雪: 'snowstorm.png',
    暴雨: 'storm.png',
    冰雹: 'hail.png',
    大风: 'wind.png',
    大雾: 'fog.png',
    道路结冰_道路冰雪: 'road_freeze.png',
    干旱: 'dry.png',
    干热风: 'drywind.png',
    高温: 'hightemperature.png',
    寒冷_寒潮_降温_低温: 'code_air.png',
    灰霾_霾: 'smog.png',
    雷电: 'thunder.png',
    雷雨大风: 'thunder_wind.png',
    森林风险: 'forestfire.png',
    沙尘暴: 'sandstorm.png',
    霜冻_冰冻: 'freeze.png',
    台风: 'typhoon.png',
  } as any;
  const ret = [] as any;
  data.sort((a: any, b: any) => {
    if (getFormatData(a.w8).getTime() - getFormatData(b.w8).getTime() > 0) {
      return -1;
    }
    if (getFormatData(a.w8).getTime() - getFormatData(b.w8).getTime() < 0) {
      return 1;
    }
    return 0;
  });
  data.forEach((item: any) => {
    const type = item.w5;
    let warningtext = `${type + item.w7}预警`;
    const tmpdate = getFormatData(item.w8);
    const date = `${tmpdate.getMonth() + 1}月${tmpdate.getDate()}日`;
    const distract = warningtext;
    const description = item.w9.replace(/\n|\r|\f/gi, '');
    // type重复的，只取最新的
    if (ret.some((item: any) => item.type == type)) {
      return false;
    }
    if (item.w8) {
      warningtext += ` ${item.w8.replace(
        /\d{4}-\d{1,2}-(\d{1,2})\s(\d{1,2}:\d{1,2}):\d{1,2}/,
        (_: any, $1: string, $2: string) => `${$1}日${$2}发布`,
      )}`;
    }

    let defaultimg = warningImgMap['大风'];
    for (const key in warningImgMap) {
      if (key.indexOf(type) != -1) {
        defaultimg = warningImgMap[key];
        break;
      }
    }
    if (item.w7 !== '白色') {
      ret.push({
        type,
        className: warningColor[item.w7],
        color: item.w7.replace('色', ''),
        tip: warningtext,
        // pop
        img: `//dlweb.sogoucdn.com/wapvr/images/warning/${defaultimg}`,
        date,
        distract,
        description: description.replace('（预警信息来源：国家预警信息发布中心）', ''),
        bottom: '信息来源：国家预警信息发布中心',
      });
    }
  });
  return ret;
}
const duplicateCityList = [
  { label: '朝阳 北京', label2: '\t北京\t朝阳', index: 'chaoyang', code: '101010300' },
  { label: '通州 北京', label2: '\t北京\t通州', index: 'tongzhou', code: '101010600' },
  { label: '宝山 上海', label2: '\t上海\t宝山', index: 'baoshan', code: '101020300' },
  { label: '甘南 齐齐哈尔', label2: '黑龙江\t齐齐哈尔\t甘南', index: 'gannan', code: '101050204' },
  { label: '梨树 四平', label2: '吉林\t四平\t梨树', index: 'lishu', code: '101060403' },
  { label: '大安 白城', label2: '吉林\t白城\t大安', index: 'daan', code: '101060603' },
  { label: '海城 鞍山', label2: '辽宁\t鞍山\t海城', index: 'haicheng', code: '101070304' },
  { label: '东港 丹东', label2: '辽宁\t丹东\t东港', index: 'donggang', code: '101070604' },
  { label: '平山 石家庄', label2: '河北\t石家庄\t平山', index: 'pingshan', code: '101090111' },
  { label: '宽城 承德', label2: '河北\t承德\t宽城', index: 'kuancheng', code: '101090409' },
  { label: '清河 邢台', label2: '河北\t邢台\t清河', index: 'qinghe', code: '101090914' },
  { label: '长安 西安', label2: '陕西\t西安\t长安', index: 'changan', code: '101110102' },
  { label: '山阳 商洛', label2: '陕西\t商洛\t山阳', index: 'shanyang', code: '101110608' },
  { label: '栖霞 烟台', label2: '山东\t烟台\t栖霞', index: 'qixia', code: '101120507' },
  { label: '昌邑 潍坊', label2: '山东\t潍坊\t昌邑', index: 'changyi', code: '101120606' },
  { label: '河口 东营', label2: '山东\t东营\t河口', index: 'hekou', code: '101121202' },
  { label: '沙湾 塔城', label2: '新疆\t塔城\t沙湾', index: 'shawan', code: '101131107' },
  { label: '大通 西宁', label2: '青海\t西宁\t大通', index: 'datong', code: '101150102' },
  { label: '东乡 临夏', label2: '甘肃\t临夏\t东乡', index: 'dongxiang', code: '101161106' },
  { label: '襄城 许昌', label2: '河南\t许昌\t襄城', index: 'xiangcheng', code: '101180403' },
  { label: '通州 南通', label2: '江苏\t南通\t通州', index: 'tongzhou', code: '101190509' },
  { label: '兴山 宜昌', label2: '湖北\t宜昌\t兴山', index: 'xingshan', code: '101200904' },
  { label: '象山 宁波', label2: '浙江\t宁波\t象山', index: 'xiangshan', code: '101210406' },
  { label: '普陀 舟山', label2: '浙江\t舟山\t普陀', index: 'putuo', code: '101211105' },
  { label: '太和 阜阳', label2: '安徽\t阜阳\t太和', index: 'taihe', code: '101220806' },
  { label: '东山 漳州', label2: '福建\t漳州\t东山', index: 'dongshan', code: '101230608' },
  { label: '永定 龙岩', label2: '福建\t龙岩\t永定', index: 'yongding', code: '101230706' },
  { label: '东乡 抚州', label2: '江西\t抚州\t东乡', index: 'dongxiang', code: '101240411' },
  { label: '华容 岳阳', label2: '湖南\t岳阳\t华容', index: 'huarong', code: '101251002' },
  { label: '东安 永州', label2: '湖南\t永州\t东安', index: 'dongan', code: '101251403' },
  { label: '龙山 湘西', label2: '湖南\t湘西\t龙山', index: 'longshan', code: '101251507' },
  { label: '白云 贵阳', label2: '贵州\t贵阳\t白云', index: 'baiyun', code: '101260102' },
  { label: '台江 黔东南', label2: '贵州\t黔东南\t台江', index: 'taijiang', code: '101260510' },
  { label: '长宁 宜宾', label2: '四川\t宜宾\t长宁', index: 'changning', code: '101271106' },
  { label: '东兴 内江', label2: '四川\t内江\t东兴', index: 'dongxing', code: '101271202' },
  { label: '九龙 甘孜', label2: '四川\t甘孜\t九龙', index: 'jiulong', code: '101271805' },
  { label: '金川 阿坝', label2: '四川\t阿坝\t金川', index: 'jinchuan', code: '101271907' },
  { label: '兴宁 梅州', label2: '广东\t梅州\t兴宁', index: 'xingning', code: '101280402' },
  { label: '五华 梅州', label2: '广东\t梅州\t五华', index: 'wuhua', code: '101280408' },
  { label: '开平 江门', label2: '广东\t江门\t开平', index: 'kaiping', code: '101281103' },
  { label: '鹤山 江门', label2: '广东\t江门\t鹤山', index: 'heshan', code: '101281108' },
  { label: '和平 河源', label2: '广东\t河源\t和平', index: 'heping', code: '101281204' },
  { label: '连山 清远', label2: '广东\t清远\t连山', index: 'lianshan', code: '101281304' },
  { label: '新兴 云浮', label2: '广东\t云浮\t新兴', index: 'xinxing', code: '101281403' },
  { label: '安宁 昆明', label2: '云南\t昆明\t安宁', index: 'anning', code: '101290112' },
  { label: '云龙 大理', label2: '云南\t大理\t云龙', index: 'yunlong', code: '101290202' },
  { label: '金平 红河', label2: '云南\t红河\t金平', index: 'jinping', code: '101290312' },
  { label: '河口 红河', label2: '云南\t红河\t河口', index: 'hekou', code: '101290313' },
  { label: '江城 普洱', label2: '云南\t普洱\t江城', index: 'jiangcheng', code: '101290907' },
  { label: '兴安 桂林', label2: '广西\t桂林\t兴安', index: 'xingan', code: '101300506' },
  { label: '钟山 贺州', label2: '广西\t贺州\t钟山', index: 'zhongshan', code: '101300704' },
  { label: '西林 百色', label2: '广西\t百色\t西林', index: 'xilin', code: '101301009' },
  { label: '东兴 防城港', label2: '广西\t防城港\t东兴', index: 'dongxing', code: '101301403' },
  { label: '南沙 三沙', label2: '海南\t三沙\t南沙群岛', index: 'nansha', code: '101310304' },
  { label: '九龙 香港', label2: '香港\t九龙半岛\t九龙半岛', index: 'jiulong', code: '101320102' },
  { label: '东安 牡丹江', label2: '黑龙江\t牡丹江\t东安', index: 'dongan', code: '101050308' },
  { label: '西安 牡丹江', label2: '黑龙江\t牡丹江\t西安', index: 'xian', code: '101050311' },
  { label: '向阳 佳木斯', label2: '黑龙江\t佳木斯\t向阳', index: 'xiangyang', code: '101050408' },
  { label: '郊区 佳木斯', label2: '黑龙江\t佳木斯\t郊区', index: 'jiaoqu', code: '101050411' },
  { label: '大同 大庆', label2: '黑龙江\t大庆\t大同', index: 'datong', code: '101050910' },
  { label: '新兴 七台河', label2: '黑龙江\t七台河\t新兴', index: 'xinxing', code: '101051001' },
  { label: '梨树 鸡西', label2: '黑龙江\t鸡西\t梨树', index: 'lishu', code: '101051108' },
  { label: '向阳 鹤岗', label2: '黑龙江\t鹤岗\t向阳', index: 'xiangyang', code: '101051204' },
  { label: '南山 鹤岗', label2: '黑龙江\t鹤岗\t南山', index: 'nanshan', code: '101051206' },
  { label: '兴安 鹤岗', label2: '黑龙江\t鹤岗\t兴安', index: 'xingan', code: '101051207' },
  { label: '东山 鹤岗', label2: '黑龙江\t鹤岗\t东山', index: 'dongshan', code: '101051208' },
  { label: '兴山 鹤岗', label2: '黑龙江\t鹤岗\t兴山', index: 'xingshan', code: '101051209' },
  { label: '宝山 双鸭山', label2: '黑龙江\t双鸭山\t宝山', index: 'baoshan', code: '101051309' },
  { label: '宽城 长春', label2: '吉林\t长春\t宽城', index: 'kuancheng', code: '101060109' },
  { label: '朝阳 长春', label2: '吉林\t长春\t朝阳', index: 'chaoyang', code: '101060110' },
  { label: '昌邑 吉林', label2: '吉林\t吉林\t昌邑', index: 'changyi', code: '101060207' },
  { label: '铁西 四平', label2: '吉林\t四平\t铁西', index: 'tiexi', code: '101060406' },
  { label: '铁东 四平', label2: '吉林\t四平\t铁东', index: 'tiedong', code: '101060407' },
  { label: '龙山 辽源', label2: '吉林\t辽源\t龙山', index: 'longshan', code: '101060704' },
  { label: '西安 辽源', label2: '吉林\t辽源\t西安', index: 'xian', code: '101060705' },
  { label: '和平 沈阳', label2: '辽宁\t沈阳\t和平', index: 'heping', code: '101070107' },
  { label: '铁西 沈阳', label2: '辽宁\t沈阳\t铁西', index: 'tiexi', code: '101070111' },
  { label: '中山 大连', label2: '辽宁\t大连\t中山', index: 'zhongshan', code: '101070208' },
  { label: '铁东 鞍山', label2: '辽宁\t鞍山\t铁东', index: 'tiedong', code: '101070305' },
  { label: '铁西 鞍山', label2: '辽宁\t鞍山\t铁西', index: 'tiexi', code: '101070306' },
  { label: '平山 本溪', label2: '辽宁\t本溪\t平山', index: 'pingshan', code: '101070503' },
  { label: '太和 锦州', label2: '辽宁\t锦州\t太和', index: 'taihe', code: '101070708' },
  { label: '海州 阜新', label2: '辽宁\t阜新\t海州', index: 'haizhou', code: '101070903' },
  { label: '清河 铁岭', label2: '辽宁\t铁岭\t清河', index: 'qinghe', code: '101071107' },
  { label: '连山 葫芦岛', label2: '辽宁\t葫芦岛\t连山', index: 'lianshan', code: '101071405' },
  { label: '新城 呼和浩特', label2: '内蒙古\t呼和浩特\t新城', index: 'xincheng', code: '101080108' },
  { label: '青山 包头', label2: '内蒙古\t包头\t青山', index: 'qingshan', code: '101080210' },
  { label: '海南 乌海', label2: '内蒙古\t乌海\t海南', index: 'hainan', code: '101080303' },
  { label: '长安 石家庄', label2: '河北\t石家庄\t长安', index: 'changan', code: '101090119' },
  { label: '桥西 石家庄', label2: '河北\t石家庄\t桥西', index: 'qiaoxi', code: '101090120' },
  { label: '新华 石家庄', label2: '河北\t石家庄\t新华', index: 'xinhua', code: '101090121' },
  { label: '桥东 张家口', label2: '河北\t张家口\t桥东', index: 'qiaodong', code: '101090315' },
  { label: '桥西 张家口', label2: '河北\t张家口\t桥西', index: 'qiaoxi', code: '101090316' },
  { label: '开平 唐山', label2: '河北\t唐山\t开平', index: 'kaiping', code: '101090516' },
  { label: '新华 沧州', label2: '河北\t沧州\t新华', index: 'xinhua', code: '101090715' },
  { label: '矿区 阳泉', label2: '山西\t阳泉\t矿区', index: 'kuangqu', code: '101100304' },
  { label: '郊区 阳泉', label2: '山西\t阳泉\t郊区', index: 'jiaoqu', code: '101100305' },
  { label: '新城 西安', label2: '陕西\t西安\t新城', index: 'xincheng', code: '101110108' },
  { label: '市中 济南', label2: '山东\t济南\t市中', index: 'shizhong', code: '101120108' },
  { label: '河东 临沂', label2: '山东\t临沂\t河东', index: 'hedong', code: '101120913' },
  { label: '市中 枣庄', label2: '山东\t枣庄\t市中', index: 'shizhong', code: '101121406' },
  { label: '东港 日照', label2: '山东\t日照\t东港', index: 'donggang', code: '101121504' },
  { label: '城关 拉萨', label2: '西藏\t拉萨\t城关', index: 'chengguan', code: '101140109' },
  { label: '城中 西宁', label2: '青海\t西宁\t城中', index: 'chengzhong', code: '101150106' },
  { label: '城关 兰州', label2: '甘肃\t兰州\t城关', index: 'chengguan', code: '101160105' },
  { label: '安宁 兰州', label2: '甘肃\t兰州\t安宁', index: 'anning', code: '101160108' },
  { label: '金川 金昌', label2: '甘肃\t金昌\t金川', index: 'jinchuan', code: '101160603' },
  { label: '新华 平顶山', label2: '河南\t平顶山\t新华', index: 'xinhua', code: '101180509' },
  { label: '鼓楼 开封', label2: '河南\t开封\t鼓楼', index: 'gulou', code: '101180808' },
  { label: '山阳 焦作', label2: '河南\t焦作\t山阳', index: 'shanyang', code: '101181111' },
  { label: '鹤山 鹤壁', label2: '河南\t鹤壁\t鹤山', index: 'heshan', code: '101181204' },
  { label: '鼓楼 南京', label2: '江苏\t南京\t鼓楼', index: 'gulou', code: '101190111' },
  { label: '栖霞 南京', label2: '江苏\t南京\t栖霞', index: 'qixia', code: '101190112' },
  { label: '鼓楼 徐州', label2: '江苏\t徐州\t鼓楼', index: 'gulou', code: '101190808' },
  { label: '云龙 徐州', label2: '江苏\t徐州\t云龙', index: 'yunlong', code: '101190809' },
  { label: '海州 连云港', label2: '江苏\t连云港\t海州', index: 'haizhou', code: '101191006' },
  { label: '青山 武汉', label2: '湖北\t武汉\t青山', index: 'qingshan', code: '101200112' },
  { label: '襄城 襄阳', label2: '湖北\t襄阳\t襄城', index: 'xiangcheng', code: '101200209' },
  { label: '华容 鄂州', label2: '湖北\t鄂州\t华容', index: 'huarong', code: '101200303' },
  { label: '西湖 杭州', label2: '浙江\t杭州\t西湖', index: 'xihu', code: '101210113' },
  { label: '江北 宁波', label2: '浙江\t宁波\t江北', index: 'jiangbei', code: '101210409' },
  { label: '大通 淮南', label2: '安徽\t淮南\t大通', index: 'datong', code: '101220404' },
  { label: '郊区 铜陵', label2: '安徽\t铜陵\t郊区', index: 'jiaoqu', code: '101221304' },
  { label: '鼓楼 福州', label2: '福建\t福州\t鼓楼', index: 'gulou', code: '101230106' },
  { label: '台江 福州', label2: '福建\t福州\t台江', index: 'taijiang', code: '101230109' },
  { label: '西湖 南昌', label2: '江西\t南昌\t西湖', index: 'xihu', code: '101240107' },
  { label: '昌江 景德镇', label2: '江西\t景德镇\t昌江', index: 'changjiang', code: '101240804' },
  { label: '资阳 益阳', label2: '湖南\t益阳\t资阳', index: 'ziyang', code: '101250706' },
  { label: '永定 张家界', label2: '湖南\t张家界\t永定', index: 'yongding', code: '101251105' },
  { label: '钟山 六盘水', label2: '贵州\t六盘水\t钟山', index: 'zhongshan', code: '101260805' },
  { label: '大安 自贡', label2: '四川\t自贡\t大安', index: 'daan', code: '101270306' },
  { label: '市中 内江', label2: '四川\t内江\t市中', index: 'shizhong', code: '101271206' },
  { label: '市中 乐山', label2: '四川\t乐山\t市中', index: 'shizhong', code: '101271410' },
  { label: '沙湾 乐山', label2: '四川\t乐山\t沙湾', index: 'shawan', code: '101271411' },
  { label: '白云 广州', label2: '广东\t广州\t白云', index: 'baiyun', code: '101280110' },
  { label: '南沙 广州', label2: '广东\t广州\t南沙', index: 'nansha', code: '101280112' },
  { label: '金平 汕头', label2: '广东\t汕头\t金平', index: 'jinping', code: '101280506' },
  { label: '南山 深圳', label2: '广东\t深圳\t南山', index: 'nanshan', code: '101280604' },
  { label: '江城 阳江', label2: '广东\t阳江\t江城', index: 'jiangcheng', code: '101281805' },
  { label: '五华 昆明', label2: '云南\t昆明\t五华', index: 'wuhua', code: '101290102' },
  { label: '兴宁 南宁', label2: '广西\t南宁\t兴宁', index: 'xingning', code: '101300102' },
  { label: '城中 柳州', label2: '广西\t柳州\t城中', index: 'chengzhong', code: '101300303' },
  { label: '象山 桂林', label2: '广西\t桂林\t象山', index: 'xiangshan', code: '101300516' },
  { label: '海城 北海', label2: '广西\t北海\t海城', index: 'haicheng', code: '101301304' },
  { label: '朝阳市', label2: '辽宁\t朝阳\t', index: 'chaoyang', code: '101071201' },
  { label: '大同 ', label2: '山西\t大同\t', index: 'datong', code: '101100201' },
  { label: '西安 ', label2: '陕西\t西安\t', index: 'xian', code: '101110101' },
  { label: '资阳 ', label2: '四川\t资阳\t', index: 'ziyang', code: '101271301' },
  { label: '中山 ', label2: '广东\t中山\t', index: 'zhongshan', code: '101281701' },
  { label: '昌江 ', label2: '海南\t昌江\t', index: 'changjiang', code: '101310206' },
  { label: '长宁 ', label2: '上海\t长宁\t', index: 'changning', code: '101021300' },
  { label: '普陀 ', label2: '上海\t普陀\t', index: 'putuo', code: '101021500' },
  { label: '和平 ', label2: '天津\t和平\t', index: 'heping', code: '101030800' },
  { label: '河东 ', label2: '天津\t河东\t', index: 'hedong', code: '101031200' },
  { label: '江北 ', label2: '重庆\t江北\t', index: 'jiangbei', code: '101043700' },
  { label: '海南 ', label2: '青海\t海南\t', index: 'hainan', code: '101150402' },
  { label: '甘南 ', label2: '甘肃\t甘南\t', index: 'gannan', code: '101161209' },
];

function formatCity2Str(obj = {} as any) {
  return `${obj.province ? obj.province : ''}\t${obj.city ? obj.city : ''}\t${obj.xianzhen ? obj.xianzhen : ''}`;
}
function getShowLayerText(item: any) {
  const label2 = formatCity2Str(item);
  let ret = item.xianzhen || item.city || item.province;
  duplicateCityList.some((one) => {
    if (one.label2 == label2) {
      ret = one.label;
      return true;
    }
  });
  return ret;
}
export function getDefaultCity(paramsObj: any) {
  if (paramsObj.jingdian) {
    const searchObj = {
      showTextLayer: paramsObj.jingdian || paramsObj.xianzhen || paramsObj.city || paramsObj.province,
    } as any;
    searchObj.showText = searchObj.showTextLayer;
    return searchObj;
  }
  const searchObj = {
    showTextLayer: getShowLayerText(paramsObj),
  } as any;
  searchObj.showText = searchObj.showTextLayer;
  return searchObj;
}
