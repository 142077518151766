import React, { MouseEvent } from 'react';

import classNames from 'classnames';

import { assets } from '../../common/js/assets';
import { globalVar } from '../../common/js/global';

interface PageProps {
  multiLine?: boolean;
  createTopic?: boolean;
  isLoadingSend?: boolean;
  isFocus?: boolean;
  topic?: string;
  onAdd?: () => void;
  onSession?: (e: MouseEvent<HTMLDivElement>, type: string) => void;
  onClear?: (e: MouseEvent<HTMLElement>) => void;
  isCreateTopicing?: boolean;
}

const InputMate: React.FC<PageProps> = (props: PageProps) => {
  const { multiLine, isCreateTopicing, onAdd, onSession, onClear, isLoadingSend, topic = '', isFocus } = props;
  const flexCenter = 'align-flex-center';
  const rowClass = 'nsp-row';
  let sendLogo = '';
  if (sendLogo === '') {
    sendLogo = !topic ? assets.nspNewSend : assets.nspNewSendBlue;
  }
  return (
    <div className={classNames(rowClass)}>
      <div className="nsp-row meta-area">
        <div className={classNames('nsp-ie-row flex-full')}>
          {isFocus && topic ? (
            <>
              <i className={classNames('sa-icon sa-icon-delete-solid sa-spacing-right-4 clear')} onClick={onClear}></i>
              <div className={classNames('word-num', topic.length >= globalVar.maxLength ? 'word-max' : '')}>
                <span>{topic.length}</span>
                <span>/</span>
                <span>{globalVar.maxLength}</span>
              </div>
            </>
          ) : null}
        </div>
        <div className={classNames(rowClass)}>
          {!isCreateTopicing && !globalVar.isPublicNet ? (
            <img
              src={assets.nspNewAddGray}
              className={classNames('add-icon align-flex-end', !multiLine ? flexCenter : 'align-flex-end')}
              onClick={() => {
                onAdd?.();
              }}
              alt=""
            />
          ) : null}
          {isLoadingSend ? (
            <div
              style={{
                background: `url(${assets.nspNewSendAnimateLogo})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
              className={classNames(
                'align-flex-end send-loading-icon',
                !multiLine ? flexCenter : 'align-flex-end sa-spacing-bottom-12',
              )}
            ></div>
          ) : (
            <div
              style={{
                backgroundImage: `url(${sendLogo})`,
                display: isCreateTopicing ? 'none' : 'block',
              }}
              className={classNames('align-flex-end send-icon', !multiLine ? flexCenter : 'align-flex-end')}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSession?.(e, 'send');
              }}
            ></div>
          )}
        </div>
        {/* <div className={classNames(rowClass)}>
          {!multiLine ? (
            <>
              <i className={classNames('sa-icon sa-icon-delete-solid sa-spacing-right-4 clear')} onClick={onClear}></i>
              <div className={classNames('word-num', topic.length >= globalVar.maxLength ? 'word-max' : '')}>
                <span>{topic.length}</span>
                <span>/</span>
                <span>{globalVar.maxLength}</span>
              </div>
            </>
          ) : null}
        </div> */}
      </div>
    </div>
  );
};

export default InputMate;
