import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import { sendNSPLog } from '../common/js/log';
import Swiper from '../common/js/swiper.min';
import { AudioListItem, AudioParams } from '../view/types/talking';

interface PageProps {
  onMiuscClick?: (item: AudioListItem) => void;
  audioParams: AudioParams;
  musicDisable?: boolean;
}
const MusicList: React.FC<PageProps> = (props: PageProps) => {
  const { audioParams, onMiuscClick, musicDisable = false } = props;
  const { message, list = [] } = audioParams;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrollList, setScrollList] = useState<AudioListItem[][]>([]);

  useEffect(() => {
    const splitScrollList = (list: AudioListItem[]) => {
      const pageNum = 8;
      let i = 0;
      const tmpList: AudioListItem[][] = [];
      // 一页放8条
      const max = Math.ceil(list.length / pageNum);
      while (i < max) {
        const currentList = list.slice(i * pageNum, (i + 1) * pageNum);
        tmpList.push(currentList);
        i += 1;
      }
      return tmpList;
    };
    setScrollList(splitScrollList(list));
    console.log('scrollList', scrollList, list);
    new Swiper('.swiper-music', {
      loop: false,
      delay: 3000,
      speed: 500,
      on: {
        slideChangeTransitionStart(swiper: any) {
          setCurrentIndex(swiper.activeIndex);
          // 发送prompt曝光日志
          sendNSPLog({
            action: 'expose',
            prompt: '',
            modulename: 'reply_music_list',
            pageindex: swiper.activeIndex + 1,
          });
        },
      },
    });
  }, []);

  return (
    <>
      <div className="nsp-music-msg">
        <span className="sa-text-regular-body1 msg">{message}</span>
      </div>
      <div className="nsp-music-container swiper swiper-music swiper-initialized swiper-horizontal swiper-pointer-events">
        <div className="swiper-wrapper">
          {scrollList.map((child, index) => (
            <div
              className={classNames(
                'nsp-swiper',
                'swiper-slide',
                { 'swiper-slide-active': index === 1 },
                { 'swiper-slide-duplicate': index === 0 || index === scrollList.length - 1 },
              )}
              data-swiper-slide-index={`${currentIndex}`}
              key={index}
            >
              <div className="nsp-row row">
                {child.map((item, idx) => (
                  <div className="wrap" key={idx} onClick={() => !musicDisable && onMiuscClick?.(item)}>
                    <div className="nsp-row item-center">
                      <div className="img-container" style={{ backgroundImage: `url(${item.picURL})` }}></div>
                      <div className={classNames('content', musicDisable ? 'text-gray' : '')}>
                        <div className="sa-text-bold-body2 title sa-text-clamp1">{item.name}</div>
                        <div className={classNames('text sa-text-regular-caption sa-text-clamp1')}>
                          <span>{item.singer}</span>
                          <span className="sa-spacing-left-6 sa-spacing-right-6">|</span>
                          <span>{item.album}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="nsp-scroll">
          {scrollList.map((item, index) => (
            <i
              key={index}
              className={classNames('nsp-scroll-icon', index === currentIndex ? 'nsp-scroll-icon-select' : '')}
            ></i>
          ))}
        </div>
      </div>
    </>
  );
};

export default MusicList;
