import CryptoJS from 'crypto-js';
const options = {
  encryptKeyVoice: CryptoJS.enc.Utf8.parse('76350b1840ff9832eb6244ac6d444366'),
  encryptOtherVoice: {
    iv: CryptoJS.enc.Utf8.parse(atob('AAAAAAAAAAAAAAAAAAAAAA==') || '76350b1840ff9832eb6244ac6d444366'),
    mode: CryptoJS.mode.CBC,
    pad: CryptoJS.pad.Pkcs7,
  },
};

export function encryptVoice(str) {
  return CryptoJS.AES.encrypt(str, options.encryptKeyVoice, options.encryptOtherVoice).toString();
}
