import React, { useImperativeHandle, useState, forwardRef, ReactElement, useRef, useContext } from 'react';

import classNames from 'classnames';

import { C } from '../App';
import { assets } from '../common/js/assets';
import { globalVar } from '../common/js/global';
import { sendNSPLog } from '../common/js/log';
import { initMediaDevices } from '../common/js/media';
import { requestUpload } from '../common/js/utils';
import { ContextProps } from '../view/types/App';
import { AudioInfo } from '../view/types/talking';

interface PageProps {
  onRecordSuccess?: (audioInfo: AudioInfo) => void;
}

interface MediaStreamData {
  data: Blob;
}

interface MediaTrace {
  stop: () => {};
}

interface UploadRes {
  url: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const MaskAudio = forwardRef((props: PageProps, ref: any): ReactElement => {
  const { onRecordSuccess } = props;
  const [isShowMask, setShowMask] = useState(false);
  // const [startRecord, setStartRecord] = useState(false);
  const [duration, setDuration] = useState(0);
  const btnDefaultText = '至少要录制10s哦';
  const [btnText, setBtnText] = useState(btnDefaultText);
  let blobAudio: Blob[] = [];
  const audioUrl = useRef('');
  const currentDuration = useRef(0);
  const recordTimeOut = useRef<any>();
  const recordStartTimeout = useRef<any>();
  // 录制声音源
  const recordBlob: any = useRef(null);
  // 音波频率
  const [muiscFrep, setMuiscFrep] = useState(assets.nspNewAudioLow1);
  // 是否关闭音乐合成
  const closeMusic = useRef<boolean>(false);

  const pageRecorder: any = useRef(null);
  const { onToast }: ContextProps = useContext(C);

  // 最长录制时间
  const maxDuration = 30;
  // 停止以后发送的文案
  const sendingText = '发送中...';

  const upLoadFile = (blob: Blob) => {
    const file = new File([blob], 'nsp_audio.mp3', {
      type: 'audio/mpeg',
    });
    // onToast(`upLoadFile:${file.size}`);
    return new Promise((resolve, reject) => {
      requestUpload(file, 'nsp_audio.mp3', 'audio').then(resolve).catch(reject);
    });
  };

  const init = () => {
    // 重置变量
    reset();
    recordStartTimeout.current = setTimeout(() => {
      if (currentDuration.current === 0) {
        onToast('获取麦克风录音权限超时，请开启麦克风权限并重启浏览器后再尝试~');
      }
    }, 5000);
    initMediaDevices()
      .then((res: any) => {
        let { recorder, mediaStream } = res;
        pageRecorder.current = recorder;
        recorder.ondataavailable = function (e: MediaStreamData) {
          if (e.data.size > 0) {
            clearTimeout(recordStartTimeout.current);
            // blobEvent对象的data属性才是存储的blob数据
            blobAudio.push(e.data);
            if (e.data?.size > 8000) {
              setMuiscFrep(assets.nspNewAudioHigh1);
            } else if (e.data?.size > 5000) {
              setMuiscFrep(assets.nspNewAudioHigh2);
            } else {
              setMuiscFrep(assets.nspNewAudioLow1);
            }
            // onRecordSuccess?.({
            //   audioUrl: audioUrl.current,
            //   duration: currentDuration,
            // });
          }
          // console.log('ondataavailable', audioUrl, e.data, duration, currentDuration);
          recorder.onstop = function () {
            const disRecord = () => {
              // 关闭媒体流的所有轨道
              mediaStream.getTracks().forEach((track: MediaTrace) => {
                track.stop();
              });
            };
            // 小于10S 不处理
            if (currentDuration.current < 10) {
              disRecord();
              blobAudio = [];
              return;
            }
            if (closeMusic.current === false) {
              recordBlob.current = new Blob(blobAudio, { type: 'audio/wav;codecs=opus' });
              // 此前只是把blob数据存储在数组中，必须把数组转化为blob对象才能生存url
              audioUrl.current = URL.createObjectURL(recordBlob.current);
              // 上传语音文件
              if (recordBlob.current) {
                setBtnText(sendingText);
                const duration = currentDuration.current;
                upLoadFile(recordBlob.current)
                  .then((res: any) => {
                    const { url }: UploadRes = res.data;
                    reset();
                    // 关闭媒体流的所有轨道
                    disRecord();
                    recorder = null;
                    mediaStream = null;
                    onRecordSuccess?.({
                      audioUrl: url,
                      duration,
                    });
                  })
                  .catch((e) => {
                    recorder = null;
                    mediaStream = null;
                    onToast('发送超时，请稍后再试~');
                    console.log('upLoadFile audio error', e);
                  });
                return;
              }
            }
            reset();
            // 关闭媒体流的所有轨道
            mediaStream.getTracks().forEach((track: MediaTrace) => {
              track.stop();
            });
            recorder = null;
            mediaStream = null;
            sendNSPLog({ action: 'click', modulename: 'musicMaskClose', prompt_type: 'music' });
          };
        };
        onStartRecord();
      })
      .catch((e) => {
        setTimeout(() => {
          reset();
        }, 2000);
        if (e?.code === 1) {
          onToast('您的浏览器不支持录音');
        } else if (e?.code === 2) {
          clearTimeout(recordStartTimeout.current);
          if (globalVar.isAndroid) {
            onToast('请开启麦克风权限，若已授权但仍不能使用请操作浏览器-设置-清除缓存Cookies，并重启浏览器后再尝试～');
          } else {
            onToast('请授权打开麦克风录音功能');
          }
        }
      });
  };

  const stopAudio = () => {
    console.log('stopAudio ....');
    clearInterval(recordTimeOut.current);
    pageRecorder.current?.stop();
  };

  const onStartRecord = () => {
    let seconds = 0;
    // setStartRecord(true);
    setDuration(0);
    blobAudio = [];
    currentDuration.current = 0;
    pageRecorder.current?.start(500);
    // 超过30秒就清除定时器
    recordTimeOut.current = setInterval(() => {
      seconds += 1;
      currentDuration.current = seconds;
      setDuration(seconds);
      // console.log('开始计时', duration);
      if (seconds >= 10) {
        setBtnText('说完了');
      }
      if (seconds >= 30) {
        clearInterval(recordTimeOut.current);
        pageRecorder.current?.stop();
        // console.log('audioUrl', audioUrl);
      }
      // currentDuration = seconds;
      // currentDuration.current = seconds;
    }, 1000);
  };

  // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // 暴露给父组件修改mask弹窗的方法
    setMask: (newShowMask: boolean) => {
      // console.log('setMask', newShowMask);
      if (newShowMask === true) {
        document.body.style.overflow = 'hidden';
        init();
      } else {
        stopAudio();
      }
      setShowMask(newShowMask);
    },
  }));

  const reset = () => {
    document.body.style.overflow = '';
    resetStatus();
  };
  const resetStatus = () => {
    blobAudio = [];
    setShowMask(false);
    setBtnText(btnDefaultText);
    setDuration(0);
    currentDuration.current = 0;
    recordBlob.current = null;
    pageRecorder.current = null;
    closeMusic.current = false;
    // setStartRecord(false);
    clearInterval(recordTimeOut.current);
  };
  // const testUrl = 'https://m.sogou.com/web/searchList.jsp?keyword=%E5%88%98%E5%BE%B7%E5%8D%8E';
  // let touchStartY = 0;
  return (
    <div
      onClick={() => {
        closeMusic.current = true;
        stopAudio();
        reset();
      }}
      className="nsp-mask-bg nsp-audio-mask"
      style={{
        display: isShowMask ? 'block' : 'none',
      }}
    >
      <div className="container container-audio" onClick={(e) => e.stopPropagation()}>
        <div className="nsp-row sa-spacing-left-12 sa-spacing-right-12">
          <div className="nsp-row header sa-spacing-top-6">
            <span className="audio-title">声音录制</span>
            <div className="sa-spacing-right-8 audio-close">
              <img
                src={assets.deleteBlack}
                className="audio-close-icon"
                alt=""
                onClick={() => {
                  closeMusic.current = true;
                  stopAudio();
                  reset();
                }}
              />
            </div>
          </div>
        </div>
        <>
          <div className="record-container" onClick={(e) => e.stopPropagation()}>
            <div className="record-text">
              已录制
              <span className="duration sa-spacing-left-4 sa-spacing-right-4">
                {duration >= 10 ? duration : `0${duration}`}
              </span>
              秒{duration >= 25 ? <span>，录制即将在{maxDuration - duration}秒后结束</span> : null}
            </div>
            <div className="record-sound">
              <img src={muiscFrep} alt="" />
            </div>
            <button
              className={classNames('record-btn', duration >= 10 && btnText !== sendingText ? 'record-active-btn' : '')}
              onClick={(e) => {
                e.stopPropagation();
                if (duration < 10) {
                  return;
                }
                // 用户点击end说完
                if (duration >= 10) {
                  currentDuration.current = duration;
                  stopAudio();
                }
              }}
            >
              {btnText}
            </button>
          </div>
        </>
      </div>
    </div>
  );
});

export default MaskAudio;
