import React, { useState, ReactElement, useRef, useMemo, useEffect, MouseEvent } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { assets } from '../common/js/assets';
import { sendNSPLog } from '../common/js/log';
import { stopAuidoPlay } from '../common/js/utils';
import { TopicState } from '../view/types/store';
import { AudioCompoundData, AudioInfo, AudioListItem, TopicCurrent } from '../view/types/talking';

interface PageProps {
  onRecordSuccess?: (audioInfo: AudioInfo) => void;
  status?: string;
  audioCompoundParams?: AudioCompoundData;
  onRecordClick?: (e: TopicCurrent) => void;
  onMiuscClick?: (item: AudioListItem) => void;
  cardData?: TopicCurrent;
  cardIndex?: number;
}

// 禁止点击样式
const disbaleClickStyle = 'nsp-music-reload-btn-disable';

// eslint-disable-next-line sonarjs/cognitive-complexity
const MusicCompoundSuccess = (props: PageProps): ReactElement => {
  const { audioCompoundParams, cardData } = props || {};
  const { message, audio } = audioCompoundParams || {};
  const { name, picURL, playURL, singer } = audio || {};
  const [playStatus, setPlayStatus] = useState(assets.nspNewMusicPlay);
  const audioRef = useRef<HTMLAudioElement>(null);
  useEffect(() => {
    sendNSPLog(
      {
        action: 'expose',
        modulename: 'musicCompoundSuccess',
      },
      cardData,
    );
  }, []);

  return (
    <>
      <div className="nsp-music-msg sa-spacing-bottom-16">
        <span className="sa-text-regular-body1 msg">{message}</span>
      </div>
      <div className="nsp-row item-center nsp-music-container sa-spacing-bottom-12">
        <div className="nsp-row flex-full">
          <div className="img-container img-success" style={{ backgroundImage: `url(${picURL})` }}></div>
          <div className="text-container sa-text-clamp1">
            <div className="sa-text-bold-body1 title">{name}</div>
            <div className="sa-text-clamp1 text sa-text-regular-caption">
              <span>{`${singer}`}</span>
            </div>
          </div>
        </div>
        <div className="nsp-row">
          <a
            onClick={() => {
              sendNSPLog(
                {
                  action: 'click',
                  modulename: 'musicCompoundSuccessDownload',
                },
                cardData,
              );
            }}
            href={playURL}
            download={name}
            className="img-success-bg img-success"
            style={{ backgroundImage: `url(${assets.nspNewMusicDownload})` }}
          ></a>
          <audio
            ref={audioRef}
            className="audio-components"
            controls
            src={playURL}
            preload="preload"
            onPause={() => {
              setPlayStatus(assets.nspNewMusicPlay);
            }}
            onEnded={() => {
              setPlayStatus(assets.nspNewMusicPlay);
            }}
          ></audio>
          <div
            className="img-success-bg img-success"
            style={{ backgroundImage: `url(${playStatus})` }}
            onClick={() => {
              if (playStatus === assets.nspNewMusicPause) {
                setPlayStatus(assets.nspNewMusicPlay);
                audioRef?.current?.pause();
                sendNSPLog(
                  {
                    action: 'click',
                    modulename: 'musicCompoundSuccessPause',
                  },
                  cardData,
                );
              } else {
                stopAuidoPlay();
                setPlayStatus(assets.nspNewMusicPause);
                audioRef?.current?.play();
                sendNSPLog(
                  {
                    action: 'click',
                    modulename: 'musicCompoundSuccessPlay',
                  },
                  cardData,
                );
              }
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

const MusicCompoundReload = (props: PageProps): ReactElement => {
  const { onRecordClick, cardData, audioCompoundParams } = props;
  const { errorCode } = audioCompoundParams || {};
  const { musicDisable } = cardData || {};
  const [disableStyle, setDisableStyle] = useState(musicDisable ? disbaleClickStyle : '');
  const tips =
    errorCode === 44003
      ? '录制时有效声音时长过短，请重新录制～'
      : '录入的声音有点小哦，可以尝试离手机麦克风近一点重新录制～';
  useEffect(() => {
    sendNSPLog(
      {
        action: 'expose',
        modulename: 'musicCompoundReload',
      },
      cardData,
    );
  }, []);
  return (
    <div className="nsp-music-container">
      <p className="none-block-gap">{tips}</p>
      <button
        className={classNames(disableStyle, 'nsp-music-reload-btn sa-spacing-top-12 sa-spacing-bottom-4')}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (disableStyle === '') {
            setDisableStyle(disbaleClickStyle);
            setTimeout(() => {
              onRecordClick?.(cardData || {});
            }, 0);
            sendNSPLog({ action: 'click', modulename: 'musicCompoundReload', prompt_type: 'music' }, cardData);
          }
        }}
      >
        重新录制
      </button>
    </div>
  );
};

const MusicCompoundLimit = (props: PageProps): ReactElement => {
  const { onMiuscClick, cardIndex = 0, cardData } = props;
  const { musicItem, musicDisable, audioCompound } = cardData || {};
  const [disableStyle, setDisableStyle] = useState(musicDisable ? disbaleClickStyle : '');
  const { topicMax = 0 } = useSelector((state: TopicState) => state.topic);
  useEffect(() => {
    if (cardIndex >= topicMax) {
      setDisableStyle(disbaleClickStyle);
    }
    sendNSPLog(
      {
        action: 'expose',
        modulename: 'musicCompoundLimit',
      },
      cardData,
    );
  }, []);
  return (
    <div className="nsp-music-container">
      <div className="none-block-gap">
        <p className="none-block-gap">抱歉～当前排队人数过多，服务器出错了~</p>
        <p className="none-block-gap">可以尝试继续生成歌曲</p>
        <p className="none-block-gap">若想要退出本次功能体验，请点击页面底部“结束音乐合成”按钮</p>
      </div>
      <button
        className={classNames(disableStyle, 'nsp-music-reload-btn sa-spacing-top-12 sa-spacing-bottom-4')}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (!musicDisable && audioCompound?.audioUrl) {
            // setDisableStyle(disbaleClickStyle);
            if (musicItem) {
              onMiuscClick?.({ ...musicItem, audioCompound });
            }
            sendNSPLog({ action: 'click', modulename: 'musicCompoundNext', prompt_type: 'music' }, cardData);
          }
        }}
      >
        继续生成
      </button>
    </div>
  );
};

const MusicCompound = (props: PageProps) => {
  const { audioCompoundParams, cardData = {} } = props;
  const { errorCode } = audioCompoundParams || {};
  const { musicDisable } = cardData;
  // errorCode = 44001;

  const ActiveComponent = (): ReactElement => {
    if (errorCode === 44001) {
      return useMemo(() => <MusicCompoundLimit {...props} />, [errorCode]);
    }
    if (errorCode === 44000 || errorCode === 44003) {
      return useMemo(() => <MusicCompoundReload {...props} />, [errorCode]);
    }
    return useMemo(() => <MusicCompoundSuccess {...props} />, [errorCode]);
  };

  return useMemo(() => <ActiveComponent />, [errorCode, musicDisable]);
};

export default MusicCompound;
