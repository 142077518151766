import React from 'react';

import classnames from 'classnames';

import { assets } from '../../common/js/assets';

interface PagePros {
  className?: string;
  disableShare?: boolean;
  onCancel: () => void;
  onRealShare?: () => void;
}

const ShareBottomBar: React.FC<PagePros> = (props: PagePros) => {
  const { disableShare = false, className, onCancel, onRealShare } = props;
  return (
    <div className={classnames('nsp-share-bar', className)}>
      <div className="nsp-row">
        <div
          className={classnames({ 'btn-share': true, disable: disableShare })}
          onClick={() => {
            if (disableShare) {
              return;
            }
            onRealShare?.();
          }}
        >
          <img src={assets.share} alt="" />
          分享
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <div onClick={onCancel} className="btn-cancel">
          取消
        </div>
      </div>
    </div>
  );
};

export default ShareBottomBar;
