import React, { MouseEvent } from 'react';

import classnames from 'classnames';

import { assets } from '../../common/js/assets';
import { globalVar } from '../../common/js/global';
import { sendNSPLog } from '../../common/js/log';
import { TopicCurrent } from '../../view/types/talking';

interface PageProps {
  className?: string;
  disableShare?: boolean;
  onCopy?: (event: MouseEvent<HTMLElement>) => void;
  onShare?: (event: MouseEvent<HTMLElement>) => void;
  onBatchShare?: (event: MouseEvent<HTMLElement>) => void;
  cardData?: TopicCurrent;
  rect?: {
    x: number;
    y: number;
  };
}

const ShareButtons: React.FC<PageProps> = (props: PageProps) => {
  const { className, disableShare, onCopy, onShare, onBatchShare, cardData, rect } = props;
  const showShareButtons = !!globalVar.switch.share;
  const style = showShareButtons ? { left: '0' } : {};
  return (
    <div
      className={classnames('copy copy-nsp copy-tips nsp-row nsp-card-mask', className)}
      style={{ ...style, top: rect?.y, left: rect?.x }}
    >
      <div
        className={classnames('nsp-row nsp-dir-column sa-spacing-left-12', { 'sa-spacing-right-20': showShareButtons })}
        onClick={(e) => {
          sendNSPLog(
            {
              action: 'click',
              modulename: 'reply_copy',
            },
            cardData,
          );
          onCopy?.(e);
        }}
      >
        <img src={assets.nspNewCopy} className="copy-icon" alt="copy" />
        <span className="sa-spacing-top-2">复制</span>
      </div>
      {showShareButtons && (
        <>
          {!disableShare && (
            <div
              className="nsp-row nsp-dir-column sa-spacing-right-20"
              onClick={(e) => {
                sendNSPLog(
                  {
                    action: 'click',
                    modulename: 'share',
                  },
                  cardData,
                );
                onShare?.(e);
              }}
            >
              <img src={assets.nspNewShare} className="copy-icon" alt="copy" />
              <span className="sa-spacing-top-2">分享</span>
            </div>
          )}
          <div
            className="nsp-row nsp-dir-column sa-spacing-right-12"
            onClick={(e) => {
              sendNSPLog(
                {
                  action: 'click',
                  modulename: 'multi_select',
                },
                cardData,
              );
              onBatchShare?.(e);
            }}
          >
            <img src={assets.nspNewSelect} className="copy-icon" alt="copy" />
            <span className="sa-spacing-top-2">多选</span>
          </div>
        </>
      )}

      <i className="copy-arrow"></i>
    </div>
  );
};

export default ShareButtons;
