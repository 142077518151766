import axios from 'axios';

import { globalVar } from './global';

// 判断queryList是否有选择的数据
export function hasSelectedDialogue(queryList) {
  const items = queryList.filter((session) => session.shareRequest || session.shareResponse);
  return items.length > 0;
}

export async function shareDialogue(queryList) {
  const items = queryList
    .filter((session) => session.shareRequest || session.shareResponse)
    .map((session) => {
      const {
        dialogueID: dialogueId,
        shareRequest: needRequest = false,
        shareResponse: needResponse = false,
        query,
        pic,
        timestamp,
      } = session;
      let requestData = '';
      if (!dialogueId) {
        const type = pic ? 'pic' : '';
        requestData = JSON.stringify({ query, pic, timestamp, type });
      }

      return {
        dialogueId,
        needRequest,
        needResponse,
        requestData,
      };
    });
  try {
    const res = await axios({
      method: 'post',
      url: globalVar.api.shareDialogue,
      data: {
        items,
        userID: globalVar.isPublicNet ? window.netQBID : '',
      },
    });
    // console.log('shareDialogue res.data', res.data);
    return res.data;
  } catch (e) {
    console.error('shareDialogue', e);
    return { code: -1 };
  }
}

export async function shareSingleDialogue(queryList, timestamp, type = '') {
  const items = queryList
    .filter((session) => session.timestamp === timestamp)
    .map((session) => {
      const { dialogueID: dialogueId } = session;
      return {
        dialogueId,
        needRequest: type === 'user',
        needResponse: type !== 'user',
      };
    });
  try {
    const res = await axios({
      method: 'post',
      url: globalVar.api.shareDialogue,
      data: {
        items,
        userID: globalVar.isPublicNet ? window.netQBID : '',
      },
    });
    // console.log('shareSingleDialogue res.data', res.data);
    return res.data;
  } catch (e) {
    console.error('shareSingleDialogue', e);
    return { code: -1 };
  }
}

export function getShareUrl(shareId) {
  return `${location.origin}/share/history/${shareId}`;
}

export function getQbShareParams(shareId, nickName = '') {
  return {
    url: getShareUrl(shareId),
    title: `${nickName}与智能搜索助手的聊天记录`,
    description: '基于腾讯大模型的对话式搜索引擎。知你所思，无界探索！',
  };
}

export async function browserAppShare(shareOptions) {
  return new Promise((resolve, reject) => {
    if (!window.browser?.app?.share) {
      reject(new Error('js api load error!'));
      return;
    }
    window.browser.app.share(shareOptions, resolve);
  });
}

export async function qbApiShare(shareOptions) {
  try {
    const ret = await browserAppShare(shareOptions);
    // {Number} code android：-1表示取消，0表示成功，-2表示失败；Android才有该字段
    // {Number} result IOS：分享成功是1， 失败和取消是-1；ios才有该字段
    return ret?.result === 0 || ret?.code === 1;
  } catch (e) {
    console.error(e);
  }
  return false;
}

export function isShareHistoryPage() {
  return location.pathname.startsWith('/share/history');
}
