import React, { useEffect, useRef, MouseEvent } from 'react';

import classNames from 'classnames';

import { assets } from '../../common/js/assets';
import { globalVar } from '../../common/js/global';
interface PageProps {
  onPhoto?: (e: MouseEvent<HTMLInputElement>) => void;
  onCamera?: (e: MouseEvent<HTMLInputElement>) => void;
  onRecordClick?: (e: MouseEvent<HTMLInputElement>) => void;
  showMultimode?: boolean;
}

const MultiMode: React.FC<PageProps> = (props: PageProps) => {
  const { showMultimode, onPhoto, onCamera, onRecordClick } = props;
  const cameraRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.setAttribute('capture', 'camera');
    }
  }, []);
  return (
    <>
      <div className={classNames('nsp-row multimode-hide', showMultimode ? 'multimode' : '')}>
        <div className="box">
          <div>
            <img src={assets.nspNewPhoto} alt="" />
            <div className="sa-text-regular-body2 sa-spacing-top-4 text">图片</div>
            <input type="file" accept="image/*" className="input" onInput={onPhoto} />
          </div>
        </div>
        <div className="box">
          <img src={assets.nspNewCamera} alt="" />
          <div className="sa-text-regular-body2 sa-spacing-top-4 text">拍照</div>
          <input
            type="file"
            ref={cameraRef}
            accept="image/*"
            id="capture"
            capture={true}
            className="input"
            onInput={onCamera}
          />
        </div>
        {!globalVar.isPC ? (
          <div className="box" onClick={onRecordClick}>
            <img src={assets.nspNewAudio} alt="" />
            <div className="sa-text-regular-body2 sa-spacing-top-4 text">音乐合成</div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MultiMode;
