export const assets = {
  nspLogoAni: 'https://search.imtt.qq.com/search_static/nsp/icon/%E8%BF%9B%E5%9C%BA%E5%8A%A8%E7%94%BB.png',
  nspLogo: 'https://search.imtt.qq.com/search_static/nsp/nsp.png',
  nspRobot: 'https://search.imtt.qq.com/search_static/nsp/nsp-logo.png',
  task: 'https://search.imtt.qq.com/search_static/nsp/icon/%E4%BB%BB%E5%8A%A1.png',
  creation: 'https://search.imtt.qq.com/search_static/nsp/icon/%E5%88%9B%E4%BD%9C.png',
  skill: 'https://search.imtt.qq.com/search_static/nsp/icon/%E6%8A%80%E8%83%BD.png',
  problem: 'https://search.imtt.qq.com/search_static/nsp/icon/%E9%97%AE%E9%A2%98.png',
  knowledge: 'https://search.imtt.qq.com/search_static/nsp/icon/%E7%9F%A5%E8%AF%86.png',
  vote: 'https://search.imtt.qq.com/search_static/nsp/icon/%E8%B5%9E.png',
  tread: 'https://search.imtt.qq.com/search_static/nsp/icon/%E8%B8%A9.png',
  goFeedBack: 'https://search.imtt.qq.com/search_static/nsp/icon/%E5%8E%BB%E5%8F%8D%E9%A6%88.png',
  feedBack: 'https://search.imtt.qq.com/search_static/nsp/icon/feedBack.png',
  yetVote: 'https://search.imtt.qq.com/search_static/nsp/icon/%E5%B7%B2%E8%B5%9E.png',
  yetTread: 'https://search.imtt.qq.com/search_static/nsp/icon/%E5%B7%B2%E8%B8%A9.png',
  rightArrow: 'https://search.imtt.qq.com/search_static/nsp/icon/right-arrow.png',
  rightArrowBlue: 'https://search.imtt.qq.com/search_static/nsp/icon/right-arrow-blue.png',
  logoLoading: 'https://search.imtt.qq.com/search_static/nsp/icon/%E7%AD%89%E5%BE%85.png',
  logoEnd: 'https://search.imtt.qq.com/search_static/nsp/icon/%E7%BB%93%E6%9D%9F.png',
  newTopic: 'https://search.imtt.qq.com/search_static/nsp/icon/new-topic.png',
  newTopicBlue: 'https://search.imtt.qq.com/search_static/nsp/icon/new-topic-blue.png',
  search: 'https://search.imtt.qq.com/search_static/nsp/icon/%E6%90%9C%E7%B4%A2.png',
  copy: 'https://search.imtt.qq.com/search_static/nsp/icon/%E5%A4%8D%E5%88%B6.png',
  target: 'https://search.imtt.qq.com/search_static/nsp/icon/target.png',
  delete: 'https://search.imtt.qq.com/search_static/nsp/icon/delete.png',
  add: 'https://search.imtt.qq.com/search_static/nsp/icon/多模入口icon@3x.png',
  newSend: 'https://search.imtt.qq.com/search_static/nsp/icon/%E5%8F%91%E9%80%81icon@3x.png',
  camera: 'https://search.imtt.qq.com/search_static/nsp/icon/拍照@3x.png',
  photo: 'https://search.imtt.qq.com/search_static/nsp/icon/图片@3x.png',
  audio: 'https://search.imtt.qq.com/search_static/nsp/icon/audio.png',
  addGray:
    'https://search.imtt.qq.com/search_static/nsp/icon/%E5%A4%9A%E6%A8%A1%E5%85%A5%E5%8F%A3icon-%E7%BD%AE%E7%81%B0%403x.png',
  microphone: 'https://search.imtt.qq.com/search_static/nsp/icon/microphone.png',
  bigMicrophone: 'https://search.imtt.qq.com/search_static/nsp/icon/bigmicrophone.png',
  deleteBlack: 'https://search.imtt.qq.com/search_static/nsp/icon/delete-black.png',
  sound: 'https://search.imtt.qq.com/search_static/nsp/icon/sound.png',
  loadingClock: 'https://search.imtt.qq.com/search_static/nsp/icon/loading_clock.png',
  loadingRight: 'https://search.imtt.qq.com/search_static/nsp/icon/loading_right.png',
  loadingRightGray: 'https://search.imtt.qq.com/search_static/nsp/icon/loading_right_gray.png',
  loadingRightBlack: 'https://search.imtt.qq.com/search_static/nsp/icon/loading_right_black.png',
  loadingSearch: 'https://search.imtt.qq.com/search_static/nsp/icon/loading_search.png',
  loadingArrowUp: 'https://search.imtt.qq.com/search_static/nsp/icon/loading_arrow_up.png',
  loadingArrowDown: 'https://search.imtt.qq.com/search_static/nsp/icon/loading_arrow_down.png',
  loadingStatus: 'https://search.imtt.qq.com/search_static/nsp/icon/loading_status.png',
  check0: 'https://search.imtt.qq.com/search_static/nsp/icon/new/check0.png',
  check1: 'https://search.imtt.qq.com/search_static/nsp/icon/new/check1.png',
  share: 'https://search.imtt.qq.com/search_static/nsp/icon/new/share.png',
  shareIconWechat: 'https://search.imtt.qq.com/search_static/nsp/icon/new/wechat.png',
  shareIconEnterpriseWechat: 'https://search.imtt.qq.com/search_static/nsp/icon/new/qiye_wechat.png',
  shareIconLink: 'https://search.imtt.qq.com/search_static/nsp/icon/new/link.png',
  shareIconBatch: 'https://search.imtt.qq.com/search_static/nsp/icon/new/batch.png',
  shareIconShareWhite: 'https://search.imtt.qq.com/search_static/nsp/icon/new/share-white.png',
  info: 'https://search.imtt.qq.com/search_static/nsp/icon/new/info.png',
  imageExpiredError: 'https://search.imtt.qq.com/search_static/nsp/icon/new/image-expired-error.png',
  nspNewLogo: 'https://search.imtt.qq.com/search_static/nsp/icon/new/logo.png',
  nspNewTopic: 'https://search.imtt.qq.com/search_static/nsp/icon/new/topic.png',
  nspNewTopicBlue: 'https://search.imtt.qq.com/search_static/nsp/icon/new/topicBlue.png',
  nspNewSend: 'https://search.imtt.qq.com/search_static/nsp/icon/new/send2.png',
  nspNewSendBlue: 'https://search.imtt.qq.com/search_static/nsp/icon/new/sendBlue2.png',
  nspNewSending: 'https://search.imtt.qq.com/search_static/nsp/icon/new/resing2.png',
  nspNewAi: 'https://search.imtt.qq.com/search_static/nsp/icon/new/ai.png',
  nspNewCamera: 'https://search.imtt.qq.com/search_static/nsp/icon/new/grayCamera.png',
  nspNewPhoto: 'https://search.imtt.qq.com/search_static/nsp/icon/new/grayPhoto.png',
  nspNewAudio: 'https://search.imtt.qq.com/search_static/nsp/icon/new/grayAudio.png',
  nspNewSearch: 'https://search.imtt.qq.com/search_static/nsp/icon/new/search.png',
  nspNewArrowRight: 'https://search.imtt.qq.com/search_static/nsp/icon/new/arrowRight.png',
  nspNewPause: 'https://search.imtt.qq.com/search_static/nsp/icon/new/pause.png',
  nspNewPauseGray: 'https://search.imtt.qq.com/search_static/nsp/icon/new/pauseGray.png',
  nspNewYetVote: 'https://search.imtt.qq.com/search_static/nsp/icon/new/yetVote.png',
  nspNewYetTread: 'https://search.imtt.qq.com/search_static/nsp/icon/new/yetTread.png',
  nspNewYetPause: 'https://search.imtt.qq.com/search_static/nsp/icon/new/yetPause.png',
  nspNewYetStop: 'https://search.imtt.qq.com/search_static/nsp/icon/new/yetStop.png',
  nspNewAddGray: 'https://search.imtt.qq.com/search_static/nsp/icon/new/addGray2.png',
  nspNewLoadingStatus: 'https://search.imtt.qq.com/search_static/nsp/icon/new/loadingStatus.png',
  nspNewLoadingClock: 'https://search.imtt.qq.com/search_static/nsp/icon/new/loadingClock.png',
  nspNewLoadingSearch: 'https://search.imtt.qq.com/search_static/nsp/icon/new/loadingSearch.png',
  nspNewSendAnimateLogo: 'http://search.sogoucdn.com/test/reactfly/static/images/arrow_animation.7ae639.png',
  nspNewLogoStart: 'https://search.imtt.qq.com/search_static/nsp/icon/new/logoEnter.png',
  nspNewLogoing: 'https://search.imtt.qq.com/search_static/nsp/icon/new/logoFor.png',
  nspNewLogoDone: 'https://search.imtt.qq.com/search_static/nsp/icon/new/logoEnd.png',
  nspNewAudioLow1: 'https://search.imtt.qq.com/search_static/nsp/audio/low-1.png',
  nspNewAudioLow2: 'https://search.imtt.qq.com/search_static/nsp/audio/low-2.png',
  nspNewAudioHigh1: 'https://search.imtt.qq.com/search_static/nsp/audio/high-1.png',
  nspNewAudioHigh2: 'https://search.imtt.qq.com/search_static/nsp/audio/high-2.png',
  nspNewMusicDownload: 'https://search.imtt.qq.com/search_static/nsp/icon/music_download.png',
  nspNewMusicPlay: 'https://search.imtt.qq.com/search_static/nsp/icon/music_play.png',
  nspNewMusicPause: 'https://search.imtt.qq.com/search_static/nsp/icon/music_pause.png',
  nspNewAudioIcon: 'https://search.imtt.qq.com/search_static/nsp/icon/new/play.png',
  nspNewCopy: 'https://search.imtt.qq.com/search_static/nsp/icon/new/newCopy.png',
  nspNewShare: 'https://search.imtt.qq.com/search_static/nsp/icon/new/newShare.png',
  nspNewSelect: 'https://search.imtt.qq.com/search_static/nsp/icon/new/newSelect.png',
  nspNewCopySearch: 'https://search.imtt.qq.com/search_static/nsp/icon/new/newSearch.png',
  nspFeedbackQR: 'https://search.imtt.qq.com/search_static/nsp/icon/new/nspFeedbackQR.png',
  nspNewLike: 'https://search.imtt.qq.com/search_static/nsp/icon/new/newLike.png',
  newLikeSelecte: 'https://search.imtt.qq.com/search_static/nsp/icon/new/newLikeSelecte.png',
  newDislike: 'https://search.imtt.qq.com/search_static/nsp/icon/new/newDislike.png',
  newDisLikeSelect: 'https://search.imtt.qq.com/search_static/nsp/icon/new/newDisLikeSelect.png',
  newLogo: 'https://search.imtt.qq.com/search_static/nsp/icon/new/newLogo.png',
};
