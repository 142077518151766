import React, { useRef } from 'react';

import { useSelector } from 'react-redux';

import { globalVar } from '../common/js/global';
import { sendLogPB } from '../common/js/utils';
import { TopicState } from '../view/types/store';
import { CardData } from '../view/types/talking';

interface ErrorParams {
  code?: number;
  tips: string;
  showTopic?: boolean;
  remainingTopics?: number | string;
}

interface CodeMap {
  [key: string | number]: ErrorParams;
}

interface ErrorTipsProps {
  code?: number | string;
  remainingTopics?: number | string;
  cardData?: CardData;
  index: number;
}

const CreateErrorTips: React.FC<ErrorParams> = (props: ErrorParams) => {
  const { tips, showTopic, remainingTopics = 0, code } = props;
  const topicInfo = useSelector((state: TopicState) => state.topic);
  const { maxNum = 0 } = topicInfo || {};

  return (
    <>
      <div className="tips">
        {showTopic ? <span className="line"></span> : null}
        <span className="sa-spacing-left-12 sa-spacing-right-12">{tips}</span>
        {showTopic ? <span className="line"></span> : null}
        {showTopic ? <p key={code}>{`今日剩余话题${+remainingTopics}/${maxNum}`}</p> : null}
      </div>
    </>
  );
};

const ErrorTips: React.FC<ErrorTipsProps> = (props: ErrorTipsProps) => {
  const { cardData, index } = props;
  const { code, remainingTopics = 0, query, timestamp, dialogueID, sessionID } = cardData || {};
  const { userInfo } = useSelector((state: any) => state.userInfo);
  const topicInfo = useSelector((state: TopicState) => state.topic);
  const { ring } = topicInfo || {};
  const errorKeys = useRef<number[]>([]);
  if (!errorKeys.current.includes(index)) {
    // 上报错误日志
    sendLogPB({
      code,
      query,
      timestamp,
      userID: userInfo.userID,
      dialogueID,
      sessionID,
      username: encodeURIComponent(userInfo.username),
    });
    errorKeys.current.push(index);
  }
  const defaultTips = '请求用户过多，请稍候再试哦～';
  const codeMap: CodeMap = {
    23: {
      tips: defaultTips,
      showTopic: false,
    },
    10001: {
      tips: defaultTips,
      showTopic: false,
    },
    40001: {
      tips: defaultTips,
      showTopic: false,
    },
    40002: {
      tips: `目前仅支持${ring}轮交互，继续对话将开启新话题`,
      showTopic: true,
    },
    40003: {
      tips: '您已达今日话题数上限哦，请明天再试~',
      showTopic: false,
    },
    40004: {
      tips: '抱歉，您有会话正在进行中，无法开启新会话',
      showTopic: false,
    },
    40005: {
      tips: '因内容量限制回复未完成，继续对话将开启新话题',
      showTopic: true,
    },
    40006: {
      tips: '因内容量限制回复未完成，继续对话将开启新话题',
      showTopic: true,
    },
    40007: {
      tips: '因长时间无消息，继续对话将开启新话题。',
      showTopic: true,
    },
    42001: {
      tips: '图片没有找到',
      showTopic: true,
    },
    42002: {
      tips: '没有相似的图片',
      showTopic: true,
    },
    10009: {
      tips: '抱歉因字数限制，将在新话题中重新提问',
      showTopic: true,
    },
    80006: {
      tips: '',
    },
    80008: {
      tips: '继续对话将开启新话题。',
      showTopic: true,
    },
    80009: {
      tips: '对话将开启新话题',
      showTopic: true,
    },
    80010: {
      tips: '已成功合成音乐，继续聊天将开启新话题',
      showTopic: true,
    },
    80011: {
      tips: '本轮音乐合成已结束，将开启新一轮合成',
      showTopic: true,
    },
    80012: {
      tips: '音乐合成已结束，继续聊天将开启新话题',
      showTopic: true,
    },
    20001: {
      tips: '题目已全部体验完，请试试对话吧',
      showTopic: false,
    },
  };
  const errorMsg = codeMap[code || ''] || {
    tips: defaultTips,
    showTopic: false,
  };
  let showErrorInfo = '';
  if (globalVar.isDebug) {
    showErrorInfo += code;
  }
  return (
    <>
      <div className="nsp-ie-row nsp-error-tips">
        <CreateErrorTips {...errorMsg} remainingTopics={remainingTopics} />
        {showErrorInfo ? <div>{showErrorInfo}</div> : null}
      </div>
    </>
  );
};

export default ErrorTips;
