import hljs from 'highlight.js';
import { marked } from 'marked';
import { markedHighlight } from 'marked-highlight';
import markedKatex from 'marked-katex-extension';

export const markedInit = () => {
  const options = {
    throwOnError: false,
  };
  marked.use(markedKatex(options));
  marked.use(
    markedHighlight({
      langPrefix: 'hljs language-',
      highlight(code, lang) {
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code || '', { language }).value;
      },
    }),
  );
  marked.use({
    sanitize: true,
    breaks: true,
    renderer: new marked.Renderer(),
    headerIds: true,
    mangle: false,
    gfm: true,
  });
};
