import React from 'react';

interface PageProps {
  loadingText?: string;
}
const Loading: React.FC<PageProps> = (props: PageProps) => {
  const { loadingText } = props;
  return (
    <>
      {loadingText ? (
        <div>
          {loadingText}
          <span className="nsp-dot">...</span>
        </div>
      ) : (
        <div className="nsp-result-loading-box">
          <div className="nsp-result-loading-bg nsp-result-loading-text sa-spacing-top-8"></div>
          <div className="nsp-result-loading-bg nsp-result-loading-text sa-spacing-top-8"></div>
          <div className="nsp-result-loading-bg nsp-result-loading-text nsp-result-loading-text-last sa-spacing-top-8"></div>
        </div>
      )}
    </>
  );
};

export default Loading;
