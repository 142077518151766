import { urlParamsParse } from './utils';

let uuid = '';
let rsFrom = '';
let headerInfo = {
  ip: '',
  qimei36: '',
  useragent: navigator.userAgent,
  qua: '',
  hippyversion: '',
  ref_url: '',
};

function paramsFormat(obj) {
  const params = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    params.push(`${key}=${typeof obj[key] === 'object' ? encodeURIComponent(JSON.stringify(obj[key])) : obj[key]}`);
  }
  return params.join('&');
}

export const setUUid = () => {
  const randomNum = `${Math.random()}`.slice(0, 8);
  uuid = window.btoa(`nsp_${randomNum}_${Date.now()}`);
};
setUUid();

export const setHeaderInfo = (params = {}) => {
  const urlParams = urlParamsParse();
  // 用户属性
  headerInfo = {
    ...headerInfo,
    ref_url: params.referer || urlParams?.ref_url || '',
    ...params,
  };
};

export const setRSFrom = (from) => {
  rsFrom = from;
};

const getChannelParams = () => {
  const urlParams = urlParamsParse();
  return {
    ref_uuid: urlParams.ref_uuid || '',
    ref_actiontime: urlParams.ref_actiontime || '',
    from: rsFrom || urlParams.from || '',
  };
};

export const sendNSPLog = (newModuleParams = {}, cardData = {}) => {
  const reqUrl = 'https://h.trace.qq.com/kv';
  // 渠道参数
  const channelParams = getChannelParams();
  const pagename = window.location.pathname.includes('session') ? 'nsp_dialogpage' : 'nsp_homepage';
  // 页面信息
  const pageParams = {
    pagename,
    pageindex: '',
    ct_url: encodeURIComponent(location.href),
  };
  // 默认信息
  const defaultParams = {
    attaid: '0fc00076186',
    token: '1489981767',
    type: 'batch',
    version: 'v1.0.0',
    uuid,
  };
  const { sessionID, dialogueID, remainingTopics = 0, cardIndex = 0, timestamp, query = '' } = cardData;
  // 模块信息
  const moduleParams = {
    modulename: '',
    prompt: query || '',
    prompt_type: '',
    session_id: sessionID || '',
    dialog_id: dialogueID || '',
    dialog_id_index: cardIndex + 1,
    dialog_id_time: timestamp || '',
    action: '',
    action_time: Date.now(),
    vrid: '',
    docid: '',
    res_title: '',
    index: '',
    linkid: '',
    target_url: '',
    target_type: '',
    ext: {},
    ...newModuleParams,
  };
  const postData = { ...headerInfo, ...channelParams, ...pageParams, ...moduleParams, ...defaultParams };
  // $.post(reqUrl, paramsFormat(postData));
  httpRequest({
    url: reqUrl,
    method: 'POST',
    data: postData,
  });
};

export function httpRequest(obj, successfun, errFun) {
  return new Promise((resolve, reject) => {
    let xmlHttp = null;
    // 创建 XMLHttpRequest 对象
    if (window.XMLHttpRequest) {
      // code for all new browsers()
      xmlHttp = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
      // code for IE5 and IE6
      xmlHttp = new ActiveXObject('Microsoft.XMLHTTP');
    }
    // 判断是否支持请求
    if (xmlHttp === null) {
      console.log('浏览器不支持xmlHttp');
      return;
    }
    // 请求方式， 转换为大写
    const httpMethod = (obj.method || 'Get').toUpperCase();
    // 数据类型
    // const httpDataType = obj.dataType || 'json';
    // url
    const httpUrl = obj.url;
    // 异步请求
    const async = true;
    let requestData = '';
    // post请求时参数处理
    if (httpMethod === 'POST') {
      // 请求体中的参数 post请求参数格式为：param1=test&param2=test2
      requestData = paramsFormat(obj.data || {});
    }
    // 请求接口
    if (httpMethod === 'GET') {
      xmlHttp.open('GET', httpUrl, async);
      xmlHttp.send(null);
    } else if (httpMethod === 'POST') {
      xmlHttp.open('POST', httpUrl, async);
      xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xmlHttp.send(requestData);
    }

    // onreadystatechange 是一个事件句柄
    xmlHttp.onreadystatechange = function () {
      // complete
      if (xmlHttp.readyState === 4) {
        // 此处用了promise替代了请求成功后的回调
        if (xmlHttp.status === 200) {
          // let res = null;
          // if (xmlHttp.responseText && typeof xmlHttp.responseText === 'string') {
          //   try {
          //     res = JSON.parse(xmlHttp.responseText);
          //   } catch (error) {}
          // }
          // if (res && res.errno === 0) {
          //   resolve(res);
          // } else {
          //   reject(res);
          // }
          // 请求成功执行的回调函数
          // successfun(JSON.parse(xmlHttp.responseText));
        } else {
          // 请求失败的回调函数
          console.log('request error');
        }
      }
    };
  });
}
