import { useEffect } from 'react';

import { globalVar } from '../../common/js/global';
import { sendNSPLog } from '../../common/js/log';
import { FrameDone } from '../../view/types/talking';

export interface PicEventInfo {
  type: string;
  topic: string;
}

interface PageProps {
  onClick: (eventInfo: PicEventInfo) => void;
  doneData?: FrameDone;
}

const PiCBtns: React.FC<PageProps> = (props: PageProps) => {
  const { onClick, doneData } = props;
  useEffect(() => {
    sendNSPLog(
      {
        action: 'expose',
        modulename: 'pic_reply',
      },
      doneData,
    );
  }, []);
  return (
    <div className="pic-btns">
      <button
        className="btn sa-spacing-right-8"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick?.({
            type: 'pic2anime',
            topic: globalVar.tips.picCartoon,
          });
          sendNSPLog(
            {
              action: 'click',
              prompt: globalVar.tips.picCartoon,
              modulename: 'pic_reply_manga',
            },
            doneData,
          );
        }}
      >
        {globalVar.tips.picCartoon}
      </button>
      <button
        className="btn"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick?.({
            type: 'pic2star',
            topic: globalVar.tips.picStar,
          });
          sendNSPLog(
            {
              action: 'click',
              prompt: globalVar.tips.picStar,
              modulename: 'pic_reply_star',
            },
            doneData,
          );
        }}
      >
        {globalVar.tips.picStar}
      </button>
    </div>
  );
};

export default PiCBtns;
