import React, { useEffect, useState } from 'react';

import { assets } from '../../common/js/assets';
import { globalVar } from '../../common/js/global';
import { sendNSPLog } from '../../common/js/log';
import { reportLog } from '../../common/js/trace/reportLog';
import { requestVote } from '../../common/js/utils';
import { TopicCurrent } from '../../view/types/talking';

interface PageProps {
  cardData: TopicCurrent;
  onStopResponse?: () => void;
  onFeedBackClick?: (type: number) => void;
  isEnd?: boolean;
  response?: string;
  stopResponse?: boolean;
  isAudioType?: boolean;
  talkRef?: any;
}

interface StopProps {
  stopResponse?: boolean;
  onStopResponse?: () => void;
  onEditTopic?: (query: string) => void;
  query: string;
}

const StopResponseComponents: React.FC<StopProps> = (props: StopProps) => {
  const { stopResponse, onStopResponse } = props;
  return !stopResponse ? (
    <div className="nsp-ie-row sa-text-regular-body2 sa-spacing-right-2" onClick={onStopResponse}>
      <img src={assets.nspNewPause} alt="" className="pause-icon sa-spacing-right-2" />
      <span className="pause-text">停止回复</span>
    </div>
  ) : null;
};

const NspTopicBottom: React.FC<PageProps> = (props: PageProps) => {
  const { cardData, onStopResponse, onFeedBackClick, talkRef } = props;
  const [vote, setVote] = useState(0);
  // const voteStatus = useRef<number>(0);
  // const [stopResponse, setStopResponse] = useState(cardData?.stopResponse);
  const {
    cardIndex,
    query = '',
    dialogueID = '',
    isEnd,
    sessionID = '',
    response = '',
    stopResponse,
    timestamp,
    voteStatus,
    type = '',
  } = cardData;
  const { max } = globalVar;
  const isAudioType = type.includes('audio');

  useEffect(() => {
    // voteStatus.current = 0;
    setVote(voteStatus !== undefined ? voteStatus : 0);
  }, [timestamp]);
  // 是否为最后一轮对话
  const last = cardIndex ? (cardIndex + 1) % max === 0 : false;
  // 点赞
  const voteClick = (vote: number) => {
    requestVote({
      query,
      dialogueID,
      sessionID,
      vote,
    });
    // voteStatus.current = vote;
    setVote(vote);
    talkRef.current.setCardInfo({ ...cardData, voteStatus: vote });
  };
  // 停止响应
  // const onRequestPause = () => {
  //   requestPauseTopic({
  //     dialogueID,
  //     sessionID,
  //     timestamp,
  //   });
  // };

  return (
    <div className="vote-container">
      <div
        className="nsp-session-nums sa-text-regular-body2"
        style={{ opacity: (response && !stopResponse) || isAudioType ? 1 : 0 }}
      >
        {cardIndex || cardIndex === 0 ? (
          <>
            <span>第</span>
            <span>
              {last ? max : (cardIndex + 1) % max}/{max}
            </span>
            <span>轮回复</span>
          </>
        ) : null}
      </div>
      {!stopResponse && isEnd ? (
        <>
          <img
            className="like-icon sa-spacing-right-16"
            src={vote === 1 ? assets.newLikeSelecte : assets.nspNewLike}
            alt="vote"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let iVote = vote;
              if (iVote === 1) {
                iVote = 0;
              } else {
                iVote = 1;
                onFeedBackClick?.(iVote);
              }
              setVote(iVote);
              voteClick(iVote);
              sendNSPLog(
                {
                  action: 'click',
                  modulename: 'reply_like',
                },
                cardData,
              );
              reportLog('reply_like', {
                dialogueID: cardData.dialogueID,
                sessionID: cardData.sessionID,
              });
            }}
          />
          <img
            className="like-icon"
            src={vote === -1 ? assets.newDisLikeSelect : assets.newDislike}
            alt="tread"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let iVote = vote;
              if (iVote === -1) {
                iVote = 0;
              } else {
                iVote = -1;
                onFeedBackClick?.(iVote);
              }
              setVote(iVote);
              voteClick(iVote);
              sendNSPLog(
                {
                  action: 'click',
                  modulename: 'reply_dislike',
                },
                cardData,
              );
              reportLog('reply_dislike', {
                dialogueID: cardData.dialogueID,
                sessionID: cardData.sessionID,
              });
            }}
          />
        </>
      ) : (
        <></>
      )}
      {(!isEnd || stopResponse) && !isAudioType ? (
        <StopResponseComponents
          query={query}
          stopResponse={stopResponse}
          onStopResponse={() => {
            // setStopResponse(true);
            onStopResponse?.();
          }}
        />
      ) : null}
    </div>
  );
};

export default NspTopicBottom;
