import React, { useEffect, useRef, useState, createContext, lazy, forwardRef, useImperativeHandle } from 'react';
import './App.scss';
import './components/swiper/index.module.scss';
import 'normalize.css/normalize.css';
import 'animate.css';
import '@tencent/search-global-css/dist/globalS.min.css';

import Aegis from 'aegis-web-sdk';
import 'formdata-polyfill';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';

import { globalVar } from './common/js/global';
import { sendNSPLog, setHeaderInfo } from './common/js/log';
import { resetMaskStaus } from './common/js/store/querySlice';
import { updateQuestionList } from './common/js/store/studySlice';
import { setRemainingTopics } from './common/js/store/topicNumsSlice';
import { updateUserInfo } from './common/js/store/userInfoSlice';
// TAM曝光日志
import { reportLog } from './common/js/trace/reportLog';
import {
  requestUserInfo,
  urlParamsParse,
  disableTouchs,
  requestPauseTopic,
  getUserInfo,
  waterMark,
  getHeaderInfo,
  requestUserAuth,
} from './common/js/utils';
import BetaWrap from './components/betaWrap';
import Header from './components/header';
import NoPermission from './components/noPermission';
import Toast from './components/toast';
import Index from './view';
// import ShareHistory from './view/share-history';
import Donwload from './view/download';
import Session from './view/session';
import { requestQuestionList } from './view/study/js/util';
import Talking from './view/talking';
import { URLParams } from './view/types/App';
import { AudioInfo } from './view/types/talking';

// 调用前保证先 setup sdk
// setup();

const ShareHistory = lazy(() => import(/* webpackChunkName: "share-history" */ './view/share-history'));
// const Session = lazy(() => import(/* webpackChunkName: "Session" */ './view/session'));

// 层级很深的组件进行数据或者方法通信
export const C: any = createContext(null);

interface LayoutProps {
  getTalkRef?: (ref?: HTMLDivElement) => void;
  userInfo?: object;
  forwardedRef?: HTMLDivElement;
  isNAFrom?: boolean;
}

interface GlobalToast {
  setShow: (status: boolean) => {};
}

interface QBLoginRes {
  qbid: string;
  nickname: string;
  appid?: string;
}

interface MainProps {
  userInfo?: object;
  initRemainingTopics: number;
}

interface StudyParams {
  study: {
    talkingShow?: boolean;
  };
}

interface UserRes {
  data?: {
    user_type?: number;
    remainingTopics?: number;
  };
}

const getCurrentUrlParams: URLParams = urlParamsParse();
const { sceneType, keyword, nspKey, rsword, from } = getCurrentUrlParams;

window.aegis = new Aegis({
  id: 'gQyweignnQqLPj9zPd', // 上报 id
  uin: 'SGE', // 用户唯一 ID（可选）
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速
  spa: true, // spa 应用页面跳转的时候开启 pv 计算
  hostUrl: 'https://rumt-zh.com',
});

const Layout = forwardRef((props: LayoutProps, forwardedRef?: any) => {
  const talkRef = useRef<HTMLDivElement>();
  // const { userInfo = {} } = props;
  // console.log('userInfo layout', userInfo);
  const { isNAFrom } = props;
  useImperativeHandle(forwardedRef, () => ({
    getTalkRef: () => talkRef,
  }));
  // 是否展示talking组件
  const { talkingShow } = useSelector((state: StudyParams) => state.study);
  // 是否显示内测弹窗
  // const isShowBateWrap = !nspKey && from !== 'topBarIcon';
  const isShowBateWrap = !globalVar.isPublicNet;
  return (
    <>
      {isShowBateWrap ? <BetaWrap sceneType={sceneType} /> : null}
      {globalVar.isPC ? <Header sceneType={sceneType} /> : null}
      <Outlet />
      {
        <Talking
          ref={talkRef}
          sceneType={sceneType || ''}
          talkingShow={talkingShow}
          nspKey={nspKey}
          rsword={rsword}
          from={from}
          isNAFrom={isNAFrom}
        />
      }
    </>
  );
});

// 页面UI
const Main = (props: MainProps) => {
  const { userInfo, initRemainingTopics } = props;
  const layoutRef = useRef<LayoutProps>({
    getTalkRef: () => {},
  });
  const [talkRef, setTalkRef]: [any, any] = useState();
  const dispatch = useDispatch();
  // 显示toast
  const toastRef = useRef<GlobalToast>();
  const [toastText, setToastText] = useState('');
  const isNAFrom = !!(nspKey || from === 'topBarIcon' || from === 'sgeInvite');
  const keywordLock = useRef<Boolean>(false);
  const [talkReady, setTalkReady] = useState(false);

  window.onNSPInputFocus = () => {};
  // @ts-ignore
  // window.onNSPInputFocus();
  window.onpopstate = () => {
    talkRef?.current?.onPopState();
  };
  const topicInput: HTMLTextAreaElement | null = document.querySelector('#nspSessionInput');
  // 点击其他任意区域收起多模
  document.body.onclick = () => {
    talkRef?.current?.setShowMultimode(false);
    topicInput?.blur();
    dispatch(resetMaskStaus());
  };
  window.onscroll = () => {
    dispatch(resetMaskStaus());
  };
  document.body.addEventListener(
    'touchmove',
    () => {
      if (topicInput) {
        topicInput.blur();
      }
    },
    false,
  );
  useEffect(() => {
    // 设置当前会话次数
    if (talkReady && +initRemainingTopics > 0) {
      talkRef?.current?.setRemainingTopics(+initRemainingTopics);
    }
    if (talkReady && +initRemainingTopics === 0) {
      talkRef?.current?.limitError(Date.now());
    }
    // 如果存在keyword关键字
    if (keyword && talkReady && keywordLock.current === false && initRemainingTopics && initRemainingTopics > 0) {
      const resultParams = nspKey ? { nspKey, rsword, from } : null;
      talkRef?.current?.setQuickTopic(decodeURIComponent(keyword), null, resultParams);
      keywordLock.current = true;
    }
  }, [talkReady, initRemainingTopics]);

  const onQuickTopic = () => {
    setTalkRef(layoutRef?.current?.getTalkRef?.());
    setTalkReady(true);
  };
  const onreload = (query: string) => {
    // 重新发起话题
    talkRef?.current?.setQuickTopic(decodeURIComponent(query));
  };
  // 重新编辑对话
  const onRequestPause = talkRef?.current?.onRequestPause;
  // 点击音乐列表Item
  const onMiuscClick = talkRef?.current?.onMiuscClick;
  // 点击重新录制
  const onRecordClick = talkRef?.current?.onRecordClick;
  // 全局toast
  const onToast = (toastText: string) => {
    setToastText(toastText);
    toastRef?.current?.setShow(true);
  };
  return (
    <C.Provider
      value={{ onreload, onRequestPause, onMiuscClick, onRecordClick, onToast, isNAFrom, talkRef, onQuickTopic }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="noPermission" Component={NoPermission}></Route>
          <Route path="/" element={<Layout userInfo={userInfo} ref={layoutRef} isNAFrom={isNAFrom} />}>
            <Route index element={<Index talkRef={talkRef} sceneType={sceneType}></Index>}></Route>
            <Route
              path="session"
              element={
                <Session
                  from={from}
                  nspKey={nspKey}
                  talkRef={talkRef}
                  onRecordSuccess={(audioInfo: AudioInfo) => {
                    talkRef?.current?.setAudioTopic(audioInfo);
                  }}
                />
              }
            />
          </Route>
          <Route path="/share/history/:ID" Component={ShareHistory}></Route>
          <Route path="download" Component={Donwload}></Route>
        </Routes>
      </BrowserRouter>
      <Toast ref={toastRef} text={toastText} duration={2000}></Toast>
    </C.Provider>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
function App() {
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({});
  const [initRemainingTopics, setInitRemainingTopics] = useState(-1);
  const [qbid, setQBid] = useState('');
  const [userType, setUserType] = useState(0);
  const [pageReady, setPageReady] = useState(false);
  const isCheckAuth = globalVar.isPublicNet && !location.pathname.includes('/share/history/');
  // alert(`window?.browser?.login:${window?.browser?.login}`);
  const checkAuth = (params?: QBLoginRes) => {
    const { qbid = 'local', nickname = '' } = params || {};
    if (qbid) {
      setQBid(qbid);
      requestUserAuth(qbid).then((res: UserRes) => {
        setPageReady(true);
        if (res?.data?.user_type === 1) {
          setUserType(res?.data?.user_type);
          window.netQBID = qbid;
          window.nickName = nickname;
          setTimeout(() => {
            init();
          }, 0);
        }
      });
    } else {
      setQBid('local');
      setPageReady(true);
    }
  };
  useEffect(() => {
    // 分享页不走鉴权逻辑
    if (isCheckAuth && globalVar.isQB) {
      window?.browser?.login?.getAccountInfo(checkAuth);
    } else {
      setPageReady(true);
      setTimeout(() => {
        init();
      }, 0);
    }
  }, []);
  const onPauseHistroyTopic = () => {
    // 如果存在之前的会话，需要停止 需要和快速会话结合在一起处理
    const nspSession = localStorage.getItem('NSPSession');
    if (nspSession) {
      const params = JSON.parse(nspSession);
      const { dialogueID, sessionID, timestamp, frameID = 0 } = params;
      // 10分钟以内有请求停止掉
      if (params && Date.now() - params.timestamp < 10 * 60 * 1000) {
        requestPauseTopic({
          dialogueID,
          sessionID,
          timestamp,
          frameID,
        });
        localStorage.removeItem('NSPSession');
      }
    }
  };

  const init = () => {
    disableTouchs();
    if (sceneType === 'study') {
      requestQuestionList()
        .then((res) => {
          const questionList = res;
          dispatch(updateQuestionList(questionList));
        })
        .catch((e: Error) => {
          console.log('error', e);
        });
    }
    window.aegis.time('getHeaderInfo');
    // 获取QB heaer里的qimei36等信息
    getHeaderInfo().then((res) => {
      window.aegis.timeEnd('getHeaderInfo');
      setHeaderInfo(res.data || {});
      const pagename = window.location.pathname === 'session' ? 'nsp_dialogpage' : 'nsp_homepage';
      // 发送首页曝光日志
      sendNSPLog({ action: 'expose', modulename: pagename });
    });
    window.aegis.time('getUserInfo');
    // 获取用户信息
    getUserInfo().then((res) => {
      const userInfo = res?.data?.data || {};
      window.aegis.timeEnd('getHeaderInfo');
      reportLog('getUserInfo', userInfo);
      dispatch(updateUserInfo({ ...userInfo }));
      setUserInfo(userInfo);
      window.nickName = window.nickName || userInfo.username || 'local';
      // setUUid(userInfo.username);
      if (!window.location.pathname.startsWith('/share/history')) {
        const userName = globalVar.isPublicNet
          ? window.netQBID || globalVar.AIName
          : userInfo.username || globalVar.AIName;
        waterMark(userName);
      }
    });
    window.aegis.time('requestUserInfo');
    // 获取剩余的会话次数
    requestUserInfo()
      .then((res: UserRes) => {
        window.aegis.timeEnd('requestUserInfo');
        reportLog('requestUserInfo', res.data);
        const remainingTopics = res?.data?.remainingTopics;
        // const remainingTopics = 0;
        if (remainingTopics || remainingTopics === 0) {
          setInitRemainingTopics(+remainingTopics);
          dispatch(setRemainingTopics(+remainingTopics));
        }
      })
      .catch((e: Error) => {
        window.aegis.error({
          msg: 'requestUserInfo error',
          ext1: JSON.stringify(e),
          ext2: JSON.stringify({ userID: window.nickName }),
        });
        console.log('error', e);
      });
    // 停止历史回复中的会话
    onPauseHistroyTopic();
  };

  return pageReady ? (
    <div className="App">
      {isCheckAuth ? (
        qbid === '' ? (
          <div className="nsp-no-permission">请在QQ浏览器中体验</div>
        ) : userType === 1 ? (
          <Main userInfo={userInfo} initRemainingTopics={initRemainingTopics} />
        ) : (
          <NoPermission />
        )
      ) : (
        <Main userInfo={userInfo} initRemainingTopics={initRemainingTopics} />
      )}
    </div>
  ) : (
    <></>
  );
}

export default App;
