import React, { useImperativeHandle, useState, forwardRef, ReactElement } from 'react';

import { assets } from '../common/js/assets';
interface PagePros {
  ref?: any;
  jumpUrl?: string;
}

const CreateFrame = (jumpUrl: string) => {
  if (!jumpUrl) {
    return;
  }
  try {
    // setTimeout(() => {
    //     // const iframeError = document.querySelector('#sub-frame-error');
    //     // window.frames[0].onerror = (message, source, lineno, colno, error) => {
    //     //     console.log('捕获到 iframe 异常：',{message, source, lineno, colno, error});
    //     //     return true;
    //     // };
    // }, 2000);
    // Axios.get(jumpUrl)
    //   .then(() => {
    //     // console.log('mask axios', res);
    //   })
    //   .catch(() => {
    //     // console.log('error load axios', err);
    //   });
    return (
      <iframe
        title="nsp"
        src={jumpUrl}
        scrolling="auto"
        onError={() => {
          // console.log('iframe error');
        }}
        onLoad={() => {
          // isLoad = true;
          // console.log('iframe loaded');
        }}
      />
    );
  } catch (error) {
    // console.log('error load', error);
    return <></>;
  }
};

const Mask = forwardRef((props: PagePros, ref: any): ReactElement => {
  const [isShowMask, setShowMask] = useState(false);
  // let pageTimeOut = false;
  const { jumpUrl } = props;
  const handlerUrl = () => {
    if (!jumpUrl) return jumpUrl;
    const url = jumpUrl;
    return url?.indexOf('http:') > -1 ? url?.replace('http:', 'https:') : url;
  };
  const replaceJumpUrl = handlerUrl();
  // iframe加载超时
  // setTimeout(() => {
  //     pageTimeOut = true;
  // }, 10000);
  // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // 暴露给父组件修改mask弹窗的方法
    setMask: (newShowMask: boolean) => {
      if (newShowMask === true) {
        document.body.style.overflow = 'hidden';
      }
      setShowMask(newShowMask);
    },
  }));

  const reset = () => {
    document.body.style.overflow = '';
    setShowMask(false);
  };
  // const testUrl = 'https://m.sogou.com/web/searchList.jsp?keyword=%E5%88%98%E5%BE%B7%E5%8D%8E';
  let touchStartY = 0;
  return (
    <div
      onClick={reset}
      className="nsp-mask-bg"
      style={{
        display: isShowMask ? 'block' : 'none',
      }}
      onTouchStart={(e) => {
        touchStartY = e?.changedTouches[0]?.pageY;
      }}
      onTouchEnd={(e) => {
        // 下滑关闭iframe
        const touchEndY = e.changedTouches[0].pageY;
        if (touchEndY - touchStartY > 50) {
          reset();
        }
      }}
    >
      <div className="container" onClick={(e) => e.stopPropagation()}>
        <div className="nsp-row sa-spacing-left-12 sa-spacing-right-12">
          <div className="nsp-row header">
            <i className="sa-icon sa-icon-sub-back back flex-full" onClick={reset}></i>
            <div>
              <a href={replaceJumpUrl} target={'_blank'} className="sa-spacing-right-8 target-page" rel="noreferrer">
                <img src={assets.target} className="close-icon" alt="target" />
              </a>
              <img src={assets.delete} className="close-icon" alt="delete" onClick={reset} />
            </div>
          </div>
        </div>
        <div className="sa-line-bottom-gray20"></div>
        {isShowMask ? (
          <>
            {/* <div className="error-tips">页面加载超时或者加载错误，请点击右上角的打开标签打开页面</div> */}
            {CreateFrame(replaceJumpUrl || '')}
          </>
        ) : null}
      </div>
    </div>
  );
});

export default Mask;
